import http from "api/https";

export enum EmailType {
  Invite = "invite",
  Reminder = "reminder",
  Report = "report",
}

export const getEmailLanguageMessage = (surveyId: number, emailType: EmailType): Promise<any> => {
  return http.getGO(`/sm/sm_survey/GetEmailLanguageMessages?id=${surveyId}&email_type=${emailType}`);
};
