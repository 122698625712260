import { TOthersDimension } from "types/dashboard";
import { makeAutoObservable, runInAction } from "mobx";

import { getDashboardResponcesOtherOptionsAsync } from "api/dasboard/useGetDashboardResponcesOtherOptions";
import { getDashboardResponcesOtherCommentsAsync } from "api/dasboard/useGetDashboardResponcesOtherComments";
import { getDashboardResponsesOtherDimensionsAsync } from "api/dasboard/useGetDashboardResponcesOtherDimensions";

import topStore from "../../store";
import MainStore from "MainStore";
import { USE_DASHBOARD_API } from "constants/config";
import { useGetDashboardResponses } from "api/dasboard/useGetDashboardResponses";

class Store {
    othersList: TOthersDimension[] = [];
    activeOthersDimension: TOthersDimension = null;

    constructor() {
        makeAutoObservable(this);
    }

    setData = (value: any, field: string) => {
        this[field] = value;
    };

    getDashboardResponsesOtherDimensions = async (
        workspace_id: number,
        entity_id: number,
        project_id: number,
        survey_id: number,
        filters: { id: number; name: string; segments: string[] }[]
    ) => {
        MainStore.changeLoader(true);
        let response;

        if (USE_DASHBOARD_API) {
            response = await useGetDashboardResponses({
                dashboardParameters: {
                    workspace_id,
                    entity_id,
                    project_id,
                    survey_id,
                    viewType: topStore.role,
                },
                attributes: filters,
                benchmarkType: topStore.overviewBenchmark,
            });
            response = response?.data?.others;
        } else {
            response = await getDashboardResponsesOtherDimensionsAsync({
                survey_id,
                attributes: filters,
                benchmarkType: topStore.overviewBenchmark,
            });
        }

        runInAction(() => {
            const formatedData = response.map((el) => {
                return {
                    dimension: el.name,
                    questionsList: el.questions.map((question) => ({
                        ...question,
                        responseList: question.responses,
                    })),
                };
            });

            const flattenedData = formatedData.flat();
            
            this.othersList = flattenedData;
            this.activeOthersDimension = flattenedData[0] || null;
        });

        MainStore.changeLoader(false);
    };

    getDashboardResponsesOtherComments = async (
        survey_id: number,
        filters: { id: number; name: string; segments: string[] }[],
        questionId: number,
        answer_id: string,
        pagination: { offset: number; limit: number }
    ) => {
        MainStore.changeLoader(true);

        const response = await getDashboardResponcesOtherCommentsAsync({
            survey_id,
            attributes: filters,
            questionId,
            answer_id,
            pagination,
            benchmarkType: topStore.overviewBenchmark,
        });

        MainStore.changeLoader(false);

        return response;
    };

    getDashboardResponsesOtherOptions = async (
        survey_id: number,
        filters: { id: number; name: string; segments: string[] }[],
        questionId: number,
        pagination: { offset: number; limit: number }
    ) => {
        MainStore.changeLoader(true);

        const response = await getDashboardResponcesOtherOptionsAsync({
            survey_id,
            attributes: filters,
            questionId,
            pagination,
            benchmarkType: topStore.overviewBenchmark,
        });

        MainStore.changeLoader(false);

        return response;
    };
}

const store = new Store();

export default store;
