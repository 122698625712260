import { FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { Divider } from "@mui/material";
import { Link } from "react-router-dom";
import { IconXlsx } from "./Icons/IconXlsx";

import CustomButton from "components/button/Button";
import storeSurveyList from "../SurveyList/store";
import { createUrl } from "helpers/url";
import store from "./store";

type SuccessProps = {};

const Success: FC<SuccessProps> = observer((props) => {
  const navUrl = createUrl({
    path: "/dashboard",
    query: {
      project_id: String(store.projectId),
      survey_id: String(store.surveyId),
    },
  });

  return (
    <ReviewChangesWrapper id={`ImportHistoricalSurvey_SuccessBlock`}>
      <Content>
        <Header id={`ImportHistoricalSurvey_SuccessHeader`}>Successfully imported!</Header>
        <FileWrapper>
          <IconXlsx />
          <p id={`ImportHistoricalSurvey_SuccessFileName`}>
            {store.fileName || "File not found"}
          </p>
        </FileWrapper>
        <Divider />
        <ButtonWrapper>
          <Link id={`ImportHistoricalSurvey_DashboardLink`} to={navUrl}>
            <Button id={`ImportHistoricalSurvey_DashboardButton`} variant="outlined">
              View dashboard
            </Button>
          </Link>
        </ButtonWrapper>
      </Content>
    </ReviewChangesWrapper>
  );
});

export default Success;

const ReviewChangesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Content = styled.div`
  width: 100%;
  max-width: 660px;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
`;

const Header = styled.h1`
  font-family: Roboto;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin: 0px 0px 20px 0px;
`;

const FileWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 28px;

  p {
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    color: var(--colorNeutralForeground1);
  }

  svg {
    margin-right: 10px;
  }
`;

const ButtonWrapper = styled.div``;

const Button = styled(CustomButton)`
  min-width: 200px !important;
  margin-top: 20px !important;
  background-color: var(--colorNeutralBackground1) !important;
`;
