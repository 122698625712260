import { observer } from "mobx-react";
import { ROLE_LIST } from "constants/role-list";
import { FC, useEffect, useState, useCallback } from "react";
import { Button, Tooltip, TooltipProps, tooltipClasses } from "@mui/material";

import dayjs from "dayjs";
import store from "./store";
import styled from "styled-components";
import topStore from "../store";
import GraphView from "./GraphView";
import ErrorIcon from "@mui/icons-material/Error";
import DimensionsList from "./DimensionsList";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { IS_OLD_DASHBOARD_OR_ENABLED_FEATURE } from "constants/config";

type PrioritizeScreenProps = {
  localFilters: { id: number; name: string; segments: string[] }[];
};

const PrioritizeScreen: FC<PrioritizeScreenProps> = observer((props) => {
  const [isSticky, setIsSticky] = useState(false);

  const handleScroll = useCallback((el: HTMLElement) => {
    const scrollTop = el.scrollTop;
    const threshold = 244;
    
    requestAnimationFrame(() => {
      setIsSticky(scrollTop > threshold);
    });
  }, []);

  useEffect(() => {
    const el = document.getElementById("main-scroll-content");
    if (!el) return;

    let ticking = false;
    const scrollHandler = () => {
      if (!ticking) {
        requestAnimationFrame(() => {
          handleScroll(el);
          ticking = false;
        });
        ticking = true;
      }
    };
    
    el.addEventListener("scroll", scrollHandler, { passive: true });

    return () => {
      el.removeEventListener("scroll", scrollHandler);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (
      topStore.selectedSurvey &&
      topStore.activeTab === "prioritize" &&
      topStore.overviewBenchmark !== undefined
    ) {
      topStore.getSurveyResponseCount(props.localFilters);
    }
  }, [
    topStore.selectedSurvey,
    props.localFilters,
    topStore.activeTab,
    topStore.overviewBenchmark,
    topStore.role,
    topStore.secondaryRole,
  ]);

  useEffect(() => {
    if (
      topStore.selectedSurvey &&
      topStore.activeTab === "prioritize" &&
      topStore.isSurveyHaveResponseCount
    ) {
      store.getQuestionList({
        survey_id: topStore.selectedSurvey.value,
        attributes: props.localFilters,
        viewType:
          topStore.role === ROLE_LIST.MANAGER.value ? topStore.secondaryRole : topStore.role,
      });
    }
  }, [
    topStore.selectedSurvey,
    topStore.activeTab,
    props.localFilters,
    topStore.role,
    topStore.secondaryRole,
    topStore.isSurveyHaveResponseCount,
  ]);

  useEffect(() => {
    if (
      topStore.activeTab === "prioritize" &&
      store.selectedQuestion &&
      topStore.isSurveyHaveResponseCount
    ) {
      store.getDimensionList({
        survey_id: topStore.selectedSurvey.value,
        attributes: props.localFilters,
        questionId: store.selectedQuestion,
        viewType:
          topStore.role === ROLE_LIST.MANAGER.value ? topStore.secondaryRole : topStore.role,
      });
    }
  }, [
    topStore.activeTab,
    props.localFilters,
    store.selectedQuestion,
    topStore.role,
    topStore.secondaryRole,
    topStore.isSurveyHaveResponseCount,
  ]);

  useEffect(() => {
    if (
      topStore.activeTab === "prioritize" &&
      store.selectedQuestion &&
      store.selectedPrioritize &&
      store.selectedPrioritize.length > 2 &&
      topStore.isSurveyHaveResponseCount
    ) {
      store.getChartData({
        survey_id: topStore.selectedSurvey.value,
        attributes: props.localFilters,
        questionId: store.selectedQuestion,
        dimensions: store.prioritizeList
          .map((el) => {
            if (store.selectedPrioritize.includes(el.id)) {
              return el.name;
            }
          })
          .filter((item) => item),
        viewType:
          topStore.role === ROLE_LIST.MANAGER.value ? topStore.secondaryRole : topStore.role,
      });
    }
  }, [
    topStore.activeTab,
    props.localFilters,
    store.selectedQuestion,
    store.selectedPrioritize,
    topStore.role,
    topStore.secondaryRole,
    topStore.isSurveyHaveResponseCount,
  ]);

  const sheduledCallData = () => {
    topStore.getSurveyResponseCount(props.localFilters);
    store.getQuestionList({
      survey_id: topStore.selectedSurvey.value,
      attributes: props.localFilters,
      questionId: store.selectedQuestion,
      viewType: topStore.role === ROLE_LIST.MANAGER.value ? topStore.secondaryRole : topStore.role,
    });
    store.getDimensionList({
      survey_id: topStore.selectedSurvey.value,
      attributes: props.localFilters,
      questionId: store.selectedQuestion,
      viewType: topStore.role === ROLE_LIST.MANAGER.value ? topStore.secondaryRole : topStore.role,
    });
    store.getChartData({
      survey_id: topStore.selectedSurvey.value,
      attributes: props.localFilters,
      questionId: store.selectedQuestion,
      dimensions: store.prioritizeList
        .map((el) => {
          if (store.selectedPrioritize.includes(el.id)) {
            return el.name;
          }
        })
        .filter((item) => item),
      viewType: topStore.role === ROLE_LIST.MANAGER.value ? topStore.secondaryRole : topStore.role,
    });

    store.setData(
      `${dayjs().format("h")}:${Number(dayjs().format("m")) >= 30 ? "30" : "00"} ${dayjs().format(
        "A"
      )}`,
      "lastUpdated"
    );
  };

  useEffect(() => {
    if (topStore.activeTab === "overview" && topStore.isSurveyHaveResponseCount) {
      var currentTime = new Date();

      // Calculating the time until the next function call
      const min = currentTime.getMinutes();
      const diffMin = min > 30 ? 60 - min : 30 - min;
      const timeToNextCall = diffMin === 0 ? 60 * 30 * 1000 : diffMin * 60 * 1000;

      setTimeout(function () {
        // Running a function
        sheduledCallData();

        // Setting the interval for the next execution
        setInterval(sheduledCallData, 60 * 30 * 1000);
      }, timeToNextCall);
    }
  }, [topStore.activeTab, topStore.isSurveyHaveResponseCount]);

  return (
    <Container>
      <TopWrapper $isSticky={isSticky}>
        {IS_OLD_DASHBOARD_OR_ENABLED_FEATURE && (
          <TopContainer $isSticky={isSticky}>
            <TopContainerText>
              Viewing results for {topStore.calculateRespondentsCount} respondents
            </TopContainerText>
            <Divider>|</Divider>
            <CustomWidthTooltip
              title={`Local timezone: UTC ${dayjs().format("Z")}`}
              placement="top"
              arrow
            >
              <TopContainerText>
                Based on results last updated: Today, {topStore.lastUpdated} <FiberManualRecordIcon />
              </TopContainerText>
            </CustomWidthTooltip>
          </TopContainer>
        )}
        {/* <Group $isSticky={isSticky}>
          {/* {storeLayout.isAdmin && (
            <DownloadButton
              variant="outlined"
              $isSticky={isSticky}
              disabled={
                store.prioritizeList.length < 3 ||
                !store.selectedQuestion ||
                store.selectedPrioritize?.length < 3
              }
            >
              <DownloadIcon />
              Download results
            </DownloadButton>
          )} 
        </Group> */}
      </TopWrapper>
      <Content>
        {!topStore.isSurveyHaveResponseCount ? (
          <EmptyState>
            <ErrorIcon />
            <EmptyStateText>
              Results will be shown when there are {topStore.selectedSurvey?.threshold} or more
              responses
            </EmptyStateText>
          </EmptyState>
        ) : store.prioritizeList.length < 3 || !store.selectedQuestion ? (
          <CenteredEmptyState>
            <EmptyText>
              Prioritize is not available as less than 3 dimensions are available in This review.
            </EmptyText>
          </CenteredEmptyState>
        ) : (
          <>
            <GraphView />
            <DimensionsList />
          </>
        )}
      </Content>
    </Container>
  );
});

export default PrioritizeScreen;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
`;

const TopWrapper = styled.div<{ $isSticky: boolean }>`
  min-height: 88px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0px;
  position: relative;
`;

const TopContainer = styled.div<{ $isSticky: boolean }>`
  width: 100%;
  align-items: center;
  display: flex;
  margin: 0;
  padding: 0;

  @media (max-width: 1439px) {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const TopContainerText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground2);

  svg {
    color: var(--colorPaletteGreenBackground2);
    font-size: 12px;
  }
`;

const Content = styled.div`
  width: 100%;
  display: flex;
  background-color: var(--colorNeutralBackground1);
  border-radius: 4px;
  min-height: 312px;
`;

const Divider = styled.p`
  margin: 0px 8px;
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralBackground3);
`;

const EmptyState = styled.div`
  height: 40px;
  width: 100%;
  margin-top: 24px;
  background-color: var(--colorNeutralForeground4);
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: var(--colorPaletteYellowForeground1);
    margin-right: 12px;
  }
`;

const CenteredEmptyState = styled.div`
  height: 100%;
  min-height: 312px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    color: var(--colorPaletteYellowForeground1);
    margin-right: 12px;
  }
`;

const EmptyStateText = styled.p`
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  color: var(--colorNeutralForeground1);
`;

const EmptyText = styled.p`
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 16px;
  color: var(--colorNeutralForeground1);
`;

const Group = styled.div<{ $isSticky: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const DownloadButton = styled(Button) <{ $isSticky: boolean }>`
  height: 34px !important;
  text-transform: none !important;
  white-space: nowrap !important;
  margin-left: 10px !important;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 18px !important;
    margin-right: 8px;
  }
`;

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    fontWeight: 500,
    fontSize: "12px",
    textAlign: "center",
    background: "var(--colorShadowInverted2)",
  },
});
