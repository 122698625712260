import { Grid } from "@mui/material";
import { useLocation, useNavigate } from "react-router";
import { FC, useEffect, useRef } from "react";
import styled from "styled-components";
import Button from "components/button/Button";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { observer } from "mobx-react";
import store from "./store";
import ProgressLoader from "./ProgressLoader";
import Errors from "./Errors";
import Success from "./Success";
import { IconchngPhoto } from "./Icons/IconchngPhoto";
import DownloadIcon from "@mui/icons-material/Download";
import ReviewChanges from "./ReviewChanges";
import { Step } from "constants/layout-types";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { createUrl } from "helpers/url";

type ImportHistoricalSurveyProps = {};

const ImportHistoricalSurvey: FC<ImportHistoricalSurveyProps> = observer(() => {
  const query = useQuery();
  const navigate = useNavigate();
  const projectId = query.get("project_id");

  useEffect(() => {
    const id: number = parseInt(projectId);
    if (!isNaN(id)) {
      store.doLoad(id);
    } else {
      navigate("/something-went-wrong");
    }
  }, [projectId]);

  useEffect(() => {
    return () => {
      store.clearStore();
    };
  }, []);

  useEffect(() => {

    store.getLatestJobs();
    // TODO: Improve once we have banner
    const intervalId = setInterval(store.getLatestJobs, 5000);

    // Cleanup function to clear the interval
    return () => clearInterval(intervalId);
  }, []);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleFileChange = (files) => {
    if (files && files.length > 0) {
      const file = files[0];
      if (file) {
        store.setFile(file);
        store.parseExcel();
      }
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
      StoreLayout.changeShowImportDone(false);
    }
  };

  const handleDrop = (event: { preventDefault: () => void; dataTransfer: { files: any } }) => {
    event.preventDefault();
    const droppedFiles = event.dataTransfer.files;
    if (droppedFiles.length > 0) {
      const newFiles = Array.from(droppedFiles);
      handleFileChange(newFiles);
    }
  };

  const handleUploadClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  const handleBacktoProjects = () => {
    navigate(
      createUrl({
        path: "/projects",
        query: { project_id: store.projectId.toString() },
      })
    );
    localStorage.setItem("surveyAction", "create");
  };

  return (
    <Container>
      <TopContainer>
        <LeftSide>
          <BackButton
            id={`ImportHistoricalSurvey_ImportHeader_BackButton`}
            onClick={handleBacktoProjects}
          >{`< Back to survey projects`}</BackButton>
          <Header id={`ImportHistoricalSurvey_ImportHeader`}>Import historical survey</Header>
          <SurveyId></SurveyId>
        </LeftSide>
      </TopContainer>
      <StyledMainGrid>
        <Grid item xs={12} md={6}>
          <ContentLeft>
            <GetStartedBlock>
              <GetStartedText id={`ImportHistoricalSurvey_GetStartedTemplateText`}>
                Get started by using the latest template.
              </GetStartedText>
              <DownloadButton
                id={`ImportHistoricalSurvey_downloadButton`}
                onClick={() => {
                  store.saveExcelStructure();
                }}
              >
                <DownloadIcon />
                Download template
              </DownloadButton>
            </GetStartedBlock>

            <ImportImageWrapper>
              <ImportImage src={"/assets/img-import.png"} />
            </ImportImageWrapper>

            <UploadText id={`ImportHistoricalSurvey_UploadDescription`}>
              <span id={`ImportHistoricalSurvey_UploadDescription_1`}>
                Upload your historical survey via an excel file. Please read the instructions in the
                template carefully before filling up the required data.
              </span>
              <br />
              <br />
              <span id={`ImportHistoricalSurvey_UploadDescription_2`}>
                Ensure that compulsory columns are filled with the correct headers format.
              </span>
            </UploadText>

            <FileWrapper disabled={store.file !== null}>
              <StyledContainerEmptyFile
                onDrop={handleDrop}
                onDragOver={(event) => event.preventDefault()}
              >
                <FileUploadIcon />
                <EmptyFileWrapperText id={`ImportHistoricalSurvey_DragAndDropText`}>
                  Drag and drop xlsx file to upload
                </EmptyFileWrapperText>
                <EmptyFileWrapperText id={`ImportHistoricalSurvey_FileTypes`}>
                  (.xlsx)
                </EmptyFileWrapperText>
              </StyledContainerEmptyFile>
            </FileWrapper>

            <div>
              <input
                type="file"
                ref={fileInputRef}
                id={`ImportHistoricalSurvey_ImportInputFile`}
                accept=".xlsx"
                style={{ display: "none" }}
                onClick={handleUploadClick}
                onChange={(e) => handleFileChange(e.target.files)}
              />
              <label htmlFor={"uploadFile"}>
                <DownloadButton
                  id={`ImportHistoricalSurvey_BrowseFilesButton`}
                  onClick={handleButtonClick}
                  disabled={store.file !== null}
                >
                  <IconchngPhoto />
                  Browse files
                </DownloadButton>
              </label>
            </div>
          </ContentLeft>
        </Grid>
        <Grid item xs={12} md={6}>
          <ContentRight>
            {store.importCurrentStep === Step.ProgressLoader && <ProgressLoader />}
            {store.importCurrentStep === Step.Error && <Errors file={store.file} />}
            {store.importCurrentStep === Step.ReviewChanges && <ReviewChanges />}
            {store.importCurrentStep === Step.Success && <Success />}
          </ContentRight>
        </Grid>
      </StyledMainGrid>
    </Container>
  );
});

export default ImportHistoricalSurvey;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const BackButton = styled(Button)`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground2) !important;
  margin-bottom: 10px;
`;


const Container = styled.div`
  width: 100%;
  min-height: calc(100vh - 80px);
  background-color: var(--colorNeutralForegroundInverted1);
  padding: 40px 150px 48px 150px;
`;

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const SurveyId = styled.div`
  color: var(--colorNeutralForeground2);
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.15px;
  margin-left: 15px;
  margin-top: 10px;
`;

const LeftSide = styled.div`
  justify-content: space-between;
  align-items: center;
`;

const ContentLeft = styled.div`
  width: 100%;
  max-width: 550px;
  margin-top: 20px;
`;


const ContentRight = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const Header = styled.div`
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
`;

const ImportImageWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
`;

const ImportImage = styled.img`
  width: 240px;
  height: 168px;
`;

const DownloadButton = styled(Button)<{ disabled?: boolean }>`
  width: fit-content;
  padding: 5px !important;
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  
  ${props => props.disabled && `
    color: #1477F8 !important;
    opacity: 0.3;
    
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.3;
      pointer-events: none;
      border-radius: 4px;
    }
    pointer-events: none;
    cursor: not-allowed;
  `}

  svg {
    margin-right: 5px;
  }
`;

const FileWrapper = styled.div<{ disabled?: boolean }>`
  width: 100%;
  height: 150px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--colorNeutralBackground1);
  border: 1px dashed var(--colorPaletteBlueBackground1);
  margin: 40px 0 10px;
  border-radius: 4px;
  position: relative;

  ${props => props.disabled && `
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #CDD3EC;
      opacity: 0.3;
      pointer-events: none;
      border-radius: 4px;
    }
  `}

  svg {
    color: var(--colorNeutralForeground5);
  }
`;  

const GetStartedBlock = styled.div`
  width: 100%;
  padding: 30px;
  display: flex;
  font-weight: 500;
  flex-direction: column;
  background-color: var(--colorNeutralBackground1);
  margin: 20px 0 10px;
  border-radius: 4px;
`;

const UploadText = styled.div`
  font-weight: 400;
  margin: 20px 0 10px;
`;

const GetStartedText = styled.div``;

const EmptyFileWrapperText = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--colorNeutralForeground5);
`;

const StyledContainerEmptyFile = styled.div`
  text-align: center;
`;

const StyledMainGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 50px;
  margin-top: 20px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-gap: 0px;
  }
`;
