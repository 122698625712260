import { FC } from "react";
import { observer } from "mobx-react";
import { OPEN_ENDED_LOCAL_MENU } from "constants/dashboard-responses";
import { USE_DASHBOARD_API } from "constants/config";

import store from "./store";
import styled from "styled-components";

type LocalMenuProps = {};

const LocalMenu: FC<LocalMenuProps> = observer(() => {
    return (
        <Container>
            <Tab
                $isActive={store.activeOpenEndedLocalTab === OPEN_ENDED_LOCAL_MENU.ALL_RESPONSES}
                onClick={() => {
                    store.setData(OPEN_ENDED_LOCAL_MENU.ALL_RESPONSES, "activeOpenEndedLocalTab");
                    store.setData(false, "isOpenTopicResponseDetail");
                }}
            >
                All responses
            </Tab>
            {/* Hide if use dashboard api */}
            {!USE_DASHBOARD_API && (
                <Tab
                    $isLast
                    $isActive={store.activeOpenEndedLocalTab === OPEN_ENDED_LOCAL_MENU.RESPONSES_BY_TOPICS}
                    onClick={() => {
                        store.setData(OPEN_ENDED_LOCAL_MENU.RESPONSES_BY_TOPICS, "activeOpenEndedLocalTab");
                        store.setData(false, "isOpenTopicResponseDetail");
                    }}
                >
                    Responses by topics
                </Tab>
            )}
        </Container>
    );
});

export default LocalMenu;

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 56px;
  border-bottom: 1px solid var(--colorPaletteBlueBackground1);
`;

const Tab = styled.span<{ $isActive: boolean; $isLast?: boolean }>`
  cursor: pointer;
  padding: 16px 24px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  border: 1px solid var(--colorPaletteBlueBackground1);
  border-radius: 4px 4px 0px 0px;
  background-color: ${(props) =>
        props.$isActive ? "var(--colorNeutralBackground1)" : "var(--colorPaletteGrayBackground1)"};
  color: ${(props) =>
        props.$isActive ? "var(--colorBrandForeground1)" : "var(--colorNeutralForeground1)"};
  ${(props) => !props.$isLast && "margin-right: 16px"};
  border-bottom: ${(props) =>
        props.$isActive ? "1px solid var(--colorBrandForeground1)" : "none"};
`;
