import { FC, useEffect } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { useLocation } from "react-router";
import { Container, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import SurveyAccessContent from "./SecondAccordion/SurveyAccessAccordionContent";
import SurveyDatesContent from "./FirstAccordion/SurveyDatesAccordionContent";
import ScheduleCommunication from "./ThirdAccordion/ScheduleCommunication";
import SelectTemplate from "features/Survey/SelectTemplate/cardGrid";
import SendReport from "./FourthAccordion/SendReport";
import { PreviewTemplate } from "./PreviewTemplate";
import CheckCommLanguage from "./checkCommLanguage";
import CustomModal from "components/modal/Modal";
import CheckPopup from "./checkPopup";
import store from "./store";
import StorePermission from "components/workspaces-sidebar/StorePermission";
import EditSurveyPopup from "../SurveyList/components/EditSurveyPopup";
import storeCreation from "../SurveyCreation/store";
import ChevronCircleIcon from "../Recipients/ChevronCircleIcon";

type CommunicationsProps = {};

const Communications: FC<CommunicationsProps> = observer(() => {
  let query = useQuery();
  let idSurvey = query.get("id") !== null ? query.get("id") : "0";

  useEffect(() => {
    let id_survey = +idSurvey;
    if (!isNaN(id_survey) && id_survey !== 0) {
      store.idSurvey = +idSurvey;
      store.loadAllData(+idSurvey);
    } else {
      window.location.href = "/error-404";
    }
    return () => {
      store.clearStore();
    };
  }, []);

  return (
    <StyledContainer sx={{ pb: 15 }}>
      {StorePermission.features.all_templates.hasFeature && (
        <CustomModal
          open={store.openCommTemplatesModal}
          id="SelectTemplate"
          maxWidth="xl"
          padding="0"
          hide={(x) => store.setOPenTemplateCommModal(x)}
          overflow="clip"
        >
          <SelectTemplate
            templateType={"communication"}
            MyOwnQuestionsClicked={() => {
              store.setOPenTemplateCommModal(false);
            }}
            onQuestionsAdded={(questions, commsTemplateId) => {
              store.setSelectedCommsTemplateForComunication(commsTemplateId);
            }}
            project_id={0}
          />
        </CustomModal>
      )}
      <CustomModal
        open={store.openCommTemplateCheckModal}
        maxWidth="sm"
        hide={(x) => store.setOpenTemplateCommCheckModal(x)}
      >
        <CheckPopup closePopup={() => store.setOpenTemplateCommCheckModal(false)} />
      </CustomModal>
      <CustomModal
        open={store.openCommLanguageInconsistency}
        maxWidth="sm"
        hide={(x) => store.changeOpenCommLanguageInconsistency(x)}
      >
        <CheckCommLanguage closePopup={() => store.changeOpenCommLanguageInconsistency(false)} />
      </CustomModal>

      <PreviewTemplate />
      <TitleWrapper>
        <TextCommunication id="Survey_EditCreate_Communication_Title">
          {store.AllInfo !== null && store.AllInfo.name !== null
            ? store.AllInfo.name
            : "Comms title template"}
        </TextCommunication>
      </TitleWrapper>

      <AccordionWrapped
        expanded={store.expandedTab === "panel0"}
        onChange={store.handleTabChange("panel0")}
      >
        <AccordionSummaryWrapped
          expandIcon={<ChevronCircleIcon position="bottom" />}
          aria-controls="panel4bh-content"
          id="Survey_EditCreate_Communication_SetSurveyDates"
        >
          <AccordionSummaryDiv>
            <AccordionText id="Survey_EditCreate_Communication_SetSurveyDates_Text">
              Set survey dates
            </AccordionText>
          </AccordionSummaryDiv>
        </AccordionSummaryWrapped>
        <AccordionDetails>
          <SurveyDatesContent />
        </AccordionDetails>
      </AccordionWrapped>

      <AccordionWrapped
        expanded={store.expandedTab === "panel1"}
        onChange={store.handleTabChange("panel1")}
      >
        <AccordionSummaryWrapped
          expandIcon={<ChevronCircleIcon position="bottom" />}
          aria-controls="panel4bh-content"
          id="Survey_EditCreate_Communication_SurveyAccess"
        >
          <AccordionSummaryDiv>
            <AccordionText id="Survey_EditCreate_Communication_SurveyAccess_Text">
              Survey access
            </AccordionText>
          </AccordionSummaryDiv>
        </AccordionSummaryWrapped>
        <AccordionDetails>
          <SurveyAccessContent />
        </AccordionDetails>
      </AccordionWrapped>

      <AccordionWrapped
        expanded={store.expandedTab === "panel2"}
        onChange={store.handleTabChange("panel2")}
      >
        <AccordionSummary
          expandIcon={<ChevronCircleIcon position="bottom" />}
          aria-controls="panel4bh-content"
          id="Survey_EditCreate_Communication_ScheduleCommunications"
        >
          <AccordionSummaryDiv>
            <AccordionText id="Survey_EditCreate_Communication_ScheduleCommunications_Text">
              Schedule communications
            </AccordionText>
          </AccordionSummaryDiv>
        </AccordionSummary>
        <AccordionDetails>
          <ScheduleCommunication />
        </AccordionDetails>
      </AccordionWrapped>

      <AccordionWrapped
        expanded={store.expandedTab === "panel3"}
        onChange={store.handleTabChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ChevronCircleIcon position="bottom" />}
          aria-controls="panel4bh-content"
          id="Survey_EditCreate_Communication_SendReport"
        >
          <AccordionSummaryDiv>
            <AccordionText id="Survey_EditCreate_Communication_SendReport_Text">
              Schedule communications to access dashboard
            </AccordionText>
          </AccordionSummaryDiv>
        </AccordionSummary>
        <AccordionDetails>
          <SendReport />
        </AccordionDetails>
      </AccordionWrapped>
      <CustomModal
        open={storeCreation.openRunningSurveyEditPopup}
        hide={(flag) => storeCreation.openClickRunSurveyEditPopup(flag)}
        children={<EditSurveyPopup hide={() => storeCreation.openClickRunSurveyEditPopup(false)} />}
      />
    </StyledContainer>
  );
});

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const StyledContainer = styled(Container)`
  .MuiAccordion-root {
    border: 1px solid var(--colorNeutralBackground1);
  }
  .MuiAccordion-root.Mui-expanded {
    border: 1px solid var(--colorPaletteBlueBackground1);
  }
`;

const TitleWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
    height: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--colorPaletteBlueBackground1);
    border-radius: 15px;
    border: 3px solid var(--colorPaletteBlueBackground1);
  }
`;

const TextCommunication = styled.span`
  font-size: 32px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const AccordionText = styled.span`
  color: var(--colorNeutralForeground1);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
`;

const AccordionWrapped = styled(Accordion)`
  margin: 0 0 20px 0;
  border-radius: 10px !important;
  box-shadow: none !important;
  &::before {
    content: none !important;
  }
`;

const AccordionSummaryWrapped = styled(AccordionSummary)``;

const AccordionSummaryDiv = styled.div`
  margin: 20px;
`;

export default Communications;
