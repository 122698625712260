import { FC, useEffect } from "react";
import styled from "styled-components";
import ImportRecipietns from "./ImportRecipients";
import ExportRecipietns from "./ExportRecipients";
import { useLocation } from "react-router";
import store from "./store";
import { observer } from "mobx-react";
import EditingLabel from "../EditingLabel";
import CustomModal from "components/modal/Modal";
import EditSurveyPopup from "../SurveyList/components/EditSurveyPopup";
import storeCreation from "../SurveyCreation/store";
import { SEND_OUT_SURVEY_TYPES } from "constants/send-out-survey";

type RecipientsScreenProps = {};

const RecipientsScreen: FC<RecipientsScreenProps> = observer(() => {
  let query = useQuery();
  let idSurvey = query.get("id") !== null ? query.get("id") : "0";

  useEffect(() => {
    let id_survey = +idSurvey;
    if (!isNaN(id_survey) && id_survey !== 0) {
      store.id_survey = id_survey;
      store.getAllEmployees();
      store.getAllRecipients();
      store.getSurveyInformation();
    } else {
      window.location.href = "/error-404";
    }
  }, []);

  return (
    <>
      {store.Survey?.project_type !== SEND_OUT_SURVEY_TYPES.Milestones && store.is_running_survey && (
        <EditingLabel
          text={"This is a running survey. Once recipients are added, the invite email will be sent to those with an email automatically."}
        />
      )}
      <Container>
        <ImportRecipietns />
        <ExportRecipietns />
      </Container>
      <CustomModal
        open={storeCreation.openRunningSurveyEditPopup}
        hide={(flag) => storeCreation.openClickRunSurveyEditPopup(flag)}
        children={<EditSurveyPopup hide={() => storeCreation.openClickRunSurveyEditPopup(false)} />}
      />
    </>
  );
});

export default RecipientsScreen;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Container = styled.div`
  width: 100%;
  display: flex;
  position: relative;

  @media (max-width: 1450px) {
    display: flex;
    flex-direction: column;
  }
`;
