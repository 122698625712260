import http from "../https";
import { TDashboardParameters } from "./useGetDashboardData";

type TRequestBody = {
  dashboardParameters: TDashboardParameters,
  attributes: {
    id: number;
    name: string;
    segments: string[];
  }[];
  benchmarkType?: number;
};

export const useGetDashboardResponses = (requestData: TRequestBody) =>
  http.dashboardAPIClient.post(`/dashboard/dashboard/responses`, requestData);
