import { Button } from "@mui/material";
import { observer } from "mobx-react";
import { FC, useState } from "react";
import { RESPONSE_TYPES } from "constants/dashboard-responses";
import { TOpenEndedResponse } from "types/dashboard";

import styled from "styled-components";
import TopicConstructor from "./TopicConstructor";

type ResponseCardProps = {
  response: TOpenEndedResponse;
  isShowSentimentsOpenEndedCard: boolean;
  localFilters: { id: number; name: string; segments: string[] }[];
};

const ResponseCard: FC<ResponseCardProps> = observer((props) => {
  const [isShowConstructor, setIsShowConstructor] = useState<boolean>(false);

  const changeIsShowConstructor = (state: boolean) => setIsShowConstructor(state);

  const returnImageUrl = (type: string) => {
    switch (type) {
      case RESPONSE_TYPES.MIXED:
        return "/assets/dashboard_overview_mixed_smile.png";
      case RESPONSE_TYPES.NEGATIVE:
        return "/assets/dashboard_overview_angry_smile.png";
      case RESPONSE_TYPES.NEUTRAL:
        return "/assets/dashboard_overview_neutral_smile.png";
      case RESPONSE_TYPES.POSITIVE:
        return "/assets/dashboard_overview_funny_smile.png";
      case RESPONSE_TYPES.UNAVAILABLE_SEGMENT:
        return "/assets/dashboard_overview_message_smile.png";
      default:
        return "/assets/dashboard_overview_message_smile.png";
    }
  };

  return (
    <Container $isShowSentimentsOpenEndedCard={props.isShowSentimentsOpenEndedCard}>
      {props.isShowSentimentsOpenEndedCard && (
        <StyledImg src={returnImageUrl(props.response.type)} />
      )}

      <InfoContainer>
        <Title>{props.response.title}</Title>
        {props.response.autoTranslation && (
          <AutoTranslarionWrapper>
            <AutoTranslationLabel>Auto-translation:</AutoTranslationLabel>
            <AutoTranslationText>{props.response.autoTranslation}</AutoTranslationText>
          </AutoTranslarionWrapper>
        )}

        {isShowConstructor && (
          <TopicConstructor
            topicList={props.response.Topics}
            changeIsShowConstructor={changeIsShowConstructor}
            localFilters={props.localFilters}
            response={props.response}
          />
        )}

        {!isShowConstructor && (
          <>
            {props.response?.Topics?.length > 0 ? (
              <TopicRow>
                <TopicLabel>Topic:</TopicLabel>
                {props.response.Topics.map((topic, index, array) => (
                  <Topic key={topic + index}>
                    {index !== 0 && ", "}
                    {topic}
                  </Topic>
                ))}
                {/* <TopicButton variant="text" $isEdit onClick={() => setIsShowConstructor(true)}>
                  Edit
                </TopicButton> */}
              </TopicRow>
            ) : (
              null
              // <TopicButton variant="text" onClick={() => setIsShowConstructor(true)}>
              //   Add topic
              // </TopicButton>
            )}
          </>
        )}
      </InfoContainer>
    </Container>
  );
});

export default ResponseCard;

const Container = styled.div<{ $isShowSentimentsOpenEndedCard: boolean }>`
  width: 100%;
  display: ${(props) => (props.$isShowSentimentsOpenEndedCard ? "grid" : "flex")};
  ${(props) => props.$isShowSentimentsOpenEndedCard && "grid-template-columns: 28px 1fr"};
  ${(props) => props.$isShowSentimentsOpenEndedCard && "column-gap: 16px"};
  background-color: var(--colorNeutralForeground8);
  margin-top: 12px;
  border-radius: 6px;
  padding: 16px;
`;

const InfoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Title = styled.p`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--colorNeutralForeground1);
`;

const AutoTranslarionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  background-color: var(--colorNeutralBackground1);
  margin-top: 16px;
`;

const AutoTranslationLabel = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--colorNeutralForeground2);
  margin-bottom: 3px;
`;

const AutoTranslationText = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: var(--colorNeutralForeground1);
`;

const StyledImg = styled.img`
  width: 24px;
  height: 24px;
`;

const TopicButton = styled(Button) <{ $isEdit?: boolean }>`
  padding: 0px !important;
  text-transform: none !important;
  font-size: 13px !important;
  min-width: 0px !important;
  ${(props) => props.$isEdit && "margin-left: 6px !important"};
`;

const TopicRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

const TopicLabel = styled.p`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--colorNeutralForeground2);
  margin-right: 4px;
`;

const Topic = styled.p`
  font-family: Roboto;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  color: var(--colorNeutralForeground1);
`;
