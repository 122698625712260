import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import store from "../../features/Survey/Communication/store";
import { observer } from "mobx-react";
import styled from "styled-components";
import LinkIcon from "../linkIcon";

const CommunicationRolesLookup = observer(() => {
  return (
    <WrapperAutocomplete>
      <LabelAutocomplete>
        WHICH ROLE GROUPS SHOULD WE SEND IT TO?
      </LabelAutocomplete>
      <Autocomplete
        multiple
        id="Survey_EditCreate_Communication_SendReport_LookupRoles_Input"
        size="small"
        value={store.Roles.filter((x) => store.SelectedRolesIds.includes(x.id))}
        disableCloseOnSelect={true}
        disableClearable
        ListboxProps={{ style: { maxHeight: "200px" } }}
        onChange={(e, value) => {
          store.changeReportRoles(value.map((x) => x.id));
        }}
        fullWidth
        noOptionsText={
          <NoOption>
            Permission role does not exist. Go to&nbsp;
            <PermissionLink target="_blank" href="/entityPermissions?nav=role">
              Permission roles <LinkIcon />
            </PermissionLink>
            to create role first.
          </NoOption>
        }
        options={store.Roles}
        getOptionLabel={(option) => option.name}
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              endAdornment: null,
            }}
          />
        )}
      />
      <HintsLabel>
        Add multiple role groups by typing and hiting "Enter" on your keyboard
      </HintsLabel>
    </WrapperAutocomplete>
  );
});

const WrapperAutocomplete = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const NoOption = styled.div`
  display: flex;
`;

const LabelAutocomplete = styled.label`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--colorNeutralForeground2);
`

const HintsLabel = styled.label`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
`

const PermissionLink = styled.a`
  display: flex;
  align-items: center;
  color: #1477f8 !important;
  text-decoration: underline;
  svg {
    margin: 0 5px 5px 5px;
  }
`;

export default CommunicationRolesLookup;
