import React from 'react';
import styled from 'styled-components';
import InfoIcon from '@mui/icons-material/Info';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';

export type NotificationType = 'SUCCESS' | 'WARNING' | 'INFO';

export interface Notification {
  id: string;
  key: string;
  account_id: number;
  type: NotificationType;
  message: string;
  hyperlink_message?: string | null;
  hyperlink_url?: string | null;
  created_at: string;
  dismissed_at?: string | null;
}

interface BannerProps {
  data: Notification;
  onDismiss?: (id: string) => void;
}

interface BannerContainerProps {
  type: NotificationType;
}

const BannerContainer = styled.div<BannerContainerProps>`
  width: 100%;
  height: 52px;
  background-color: #E4E9FF;
  font-family: Roboto;
`;

const BannerContent = styled.div`
  height: 100%;
  margin: 0 auto;
  padding: 0 24px 0 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
`;

const LeftSection = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const RightSection = styled.div`
  display: flex;
  align-items: center;
  font-weight: 500;
  margin-left: auto;
`;

const Message = styled.p`
  color: #3E4450;
  margin: 0;
`;

const Link = styled.a`
  color: #1477F8;
  text-decoration: none;
  
  &:hover {
    text-decoration: underline;
  }
`;

const DismissButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  gap: 4px;
  color: #1477F8;
  margin-left: 16px;
  
  &:hover {
    opacity: 0.8;
  }

  svg {
    color: #1477F8;
  }
`;

const AlertBanner: React.FC<BannerProps> = ({ data, onDismiss }) => {
  const { type, message, hyperlink_message, hyperlink_url, id } = data;

  const getIcon = () => {
    const iconProps = { 
      style: { 
        fontSize: 20,
        color: type === 'SUCCESS' ? '#16a34a' : 
               type === 'WARNING' ? '#dc2626' : 
               '#7562FC'
      } 
    };

    switch (type) {
      case 'SUCCESS':
        return <CheckCircleIcon {...iconProps} />;
      case 'WARNING':
        return <ErrorIcon {...iconProps} />;
      default:
        return <InfoIcon {...iconProps} />;
    }
  };

  const handleDismissClick = () => {
    if (onDismiss) {
      onDismiss(id);
    }
  };

  return (
    <BannerContainer type={type} id={id}>
      <BannerContent>
        <LeftSection>
          {getIcon()}
          <Message>{message}</Message>
          {hyperlink_message && hyperlink_url && (
            <Link href={hyperlink_url}>{hyperlink_message}</Link>
          )}
        </LeftSection>
        
        <RightSection>
          <DismissButton onClick={handleDismissClick}>
            Dismiss
            <CloseIcon style={{ fontSize: 20 }} />
          </DismissButton>
        </RightSection>
      </BannerContent>
    </BannerContainer>
  );
};

export default AlertBanner;
