import { observer } from "mobx-react";
import { FC, useEffect, useState } from "react";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";

import store from "../../store";
import styled from "styled-components";
import Highcharts from "highcharts";
import solidGauge from "highcharts/modules/solid-gauge";
import highchartsMore from "highcharts/highcharts-more";
import HighchartsReact from "highcharts-react-official";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { CardLoader } from "features/Dashboard/CardLoader";
import { ENABLE_IN_DEVELOPMENT_FEATURES } from "constants/config";

type OpenEndedQuestionsCardProps = {};

interface BubblePoint extends Highcharts.Point {
  dataLabel?: Highcharts.SVGElement;
}

highchartsMore(Highcharts);
solidGauge(Highcharts);

const CHARS_PER_LINE = 15;
const BUBBLE_BUFFER = 1.8;
const MIN_BUBBLE_SIZE = 50;
const MAX_BUBBLE_SIZE = 90;
const MIN_FONT_SIZE = 7;
const MAX_FONT_SIZE = 8;
const BASE_PLOT_HEIGHT = 800;
const BASE_CHART_HEIGHT = 900;
const LABEL_SPACE = 80;
const FONT_SIZE = 8;

const lerp = (start: number, end: number, t: number) => {
  return start * (1 - t) + end * t;
};

const interpolateColor = (color1: string, color2: string, factor: number) => {
  const hex1 = color1.replace('#', '');
  const hex2 = color2.replace('#', '');
  
  const r1 = parseInt(hex1.slice(0, 2), 16);
  const g1 = parseInt(hex1.slice(2, 4), 16);
  const b1 = parseInt(hex1.slice(4, 6), 16);
  
  const r2 = parseInt(hex2.slice(0, 2), 16);
  const g2 = parseInt(hex2.slice(2, 4), 16);
  const b2 = parseInt(hex2.slice(4, 6), 16);
  
  const r = Math.round(r1 + (r2 - r1) * factor);
  const g = Math.round(g1 + (g2 - g1) * factor);
  const b = Math.round(b1 + (b2 - b1) * factor);
  
  return `#${r.toString(16).padStart(2, '0')}${g.toString(16).padStart(2, '0')}${b.toString(16).padStart(2, '0')}`;
};

interface GridPosition {
  x: number;
  y: number;
  isOccupied: boolean;
}

const calculateBubblePosition = (
  sentiment: number,
  bubbleSize: number,
  existingPositions: Array<{ x: number; y: number; size: number }>,
  plotHeight: number
) => {
  // Define fixed vertical spacing
  const BASE_SPACING = plotHeight / 12; // Divide plot into 12 sections
  
  // If this is the first bubble, place it in the middle
  if (existingPositions.length === 0) {
    return {
      x: sentiment,
      y: plotHeight / 2
    };
  }

  // Find bubbles with similar sentiment (within a small range)
  const SENTIMENT_THRESHOLD = 0.5;
  const similarBubbles = existingPositions.filter(pos => 
    Math.abs(pos.x - sentiment) <= SENTIMENT_THRESHOLD
  );

  if (similarBubbles.length === 0) {
    // If no similar bubbles, place in middle with slight random offset
    return {
      x: sentiment,
      y: plotHeight / 2 + (Math.random() - 0.5) * BASE_SPACING
    };
  }

  // Find gaps in the existing positions
  const sortedPositions = similarBubbles
    .map(pos => pos.y)
    .sort((a, b) => a - b);

  // Add plot boundaries as positions
  sortedPositions.unshift(bubbleSize);
  sortedPositions.push(plotHeight - bubbleSize);

  // Find the largest gap
  let maxGap = 0;
  let gapCenter = plotHeight / 2;

  for (let i = 0; i < sortedPositions.length - 1; i++) {
    const gap = sortedPositions[i + 1] - sortedPositions[i];
    if (gap > maxGap) {
      maxGap = gap;
      gapCenter = sortedPositions[i] + gap / 2;
    }
  }

  // If the gap is too small, expand vertically
  if (maxGap < bubbleSize * 1.5) {
    const offset = (existingPositions.length % 2 === 0 ? 1 : -1) * 
                  (Math.floor(existingPositions.length / 2) + 1) * 
                  BASE_SPACING;
    gapCenter = plotHeight / 2 + offset;
  }

  return {
    x: sentiment,
    y: Math.max(bubbleSize, Math.min(plotHeight - bubbleSize, gapCenter))
  };
};

const OpenEndedQuestionsCard: FC<OpenEndedQuestionsCardProps> = observer(() => {
  const [graphData, setGraphData] = useState([]);
  const [graphOptions, setGraphOptions] = useState({
    maxX: 0,
    minX: 0,
    minY: 0,
    maxY: 0,
    plotHeight: BASE_PLOT_HEIGHT,
    chartHeight: BASE_CHART_HEIGHT
  });

  const calculateBubbleSizeAndFontSize = (text: string, size: number) => {
    const absSize = Math.abs(size);
    const MULTIPLIER_BASE = 1.2;
    const multiplier = Math.pow(MULTIPLIER_BASE, absSize);
    const baseBubbleSize = MIN_BUBBLE_SIZE * multiplier;
    const bubbleSize = Math.min(Math.max(baseBubbleSize, MIN_BUBBLE_SIZE), MAX_BUBBLE_SIZE);
    const fontSizeRatio = (bubbleSize - MIN_BUBBLE_SIZE) / (MAX_BUBBLE_SIZE - MIN_BUBBLE_SIZE);
    const fontSize = MIN_FONT_SIZE + (MAX_FONT_SIZE - MIN_FONT_SIZE) * fontSizeRatio;
    
    return {
      bubbleSize: Math.round(bubbleSize),
      fontSize: Math.round(fontSize * 10) / 10
    };
  };

  const formatTextToFit = (text: string) => {
    const words = text.split(' ');
    let lines = [];
    let currentLine = '';
    
    words.forEach(word => {
      if ((currentLine + ' ' + word).length <= CHARS_PER_LINE) {
        currentLine += (currentLine ? ' ' : '') + word;
      } else {
        if (currentLine) lines.push(currentLine);
        currentLine = word;
      }
    });
    if (currentLine) lines.push(currentLine);
    
    return lines.join('<br>');
  };

  useEffect(() => {
    if (
      store.projectInfo &&
      store.projectInfo?.openEndQuestion &&
      store.projectInfo?.openEndQuestion.length
    ) {
      // Calculate sentiment groups
      const sentimentGroups: Record<string, number> = {};
      store.projectInfo.openEndQuestion.forEach(item => {
        sentimentGroups[item.sentiment] = (sentimentGroups[item.sentiment] || 0) + 1;
      });

      // Find the largest group of same sentiment
      const maxGroupSize = Math.max(...Object.values(sentimentGroups));
      
      // Calculate dynamic heights
      const PLOT_HEIGHT = Math.max(BASE_PLOT_HEIGHT, maxGroupSize * 2);
      const CHART_HEIGHT = Math.max(BASE_CHART_HEIGHT, PLOT_HEIGHT + (1 * LABEL_SPACE));

      const minXvalue = store.projectInfo?.openEndQuestion.sort(
        (a, b) => a.sentiment - b.sentiment
      )[0]?.sentiment;
      const maxXvalue = store.projectInfo?.openEndQuestion.sort(
        (a, b) => b.sentiment - a.sentiment
      )[0]?.sentiment;

      const minX = minXvalue - 20;
      const maxX = maxXvalue + 20;

      //********* calculate y position on graph *********//

      const counterRepeatXValues = {};

      store.projectInfo?.openEndQuestion.forEach(function (item) {
        if (counterRepeatXValues[item.sentiment]) {
          counterRepeatXValues[item.sentiment].countRepeat += 1;
        } else {
          counterRepeatXValues[item.sentiment] = { ...item, value: item.sentiment, countRepeat: 1 };
        }
      });

      const arrayWithArrays = [];

      Object.values(counterRepeatXValues).map((el: { countRepeat: number; value: number }) => {
        let count = 0;
        const newArray = store.projectInfo?.openEndQuestion.map((item) => {
          if (item.sentiment === el.value) {
            count++;
            return {
              ...item,
              innerIndex: count,
            };
          } else return null;
        });
        arrayWithArrays.push(newArray.filter((el) => el));
      });

      const resultModifyArray = [].concat(...arrayWithArrays)
        .filter(el => el?.name && el.name.trim() !== ''); // Filter out empty names before processing

      const sentiments = resultModifyArray.map(item => item.sentiment);
      const maxSentiment = Math.max(...sentiments);
      const minSentiment = Math.min(...sentiments);

      const getColor = (el: { sentiment: number }) => {
        const sentiment = el.sentiment;
        
        // Define color breakpoints
        const HIGHEST_COLOR = "#007B8C";  // Most positive (blue)
        const NEUTRAL_COLOR = "#F1F1F1";  // Neutral (grey)
        const LOWEST_COLOR = "#FFBDBD";   // Most negative (red)
        
        // Get max and min sentiments from the dataset
        const maxSentiment = Math.max(...store.projectInfo?.openEndQuestion.map(q => q.sentiment) || [0]);
        const minSentiment = Math.min(...store.projectInfo?.openEndQuestion.map(q => q.sentiment) || [0]);
        
        // Return neutral color for zero sentiment
        if (sentiment === 0) return NEUTRAL_COLOR;
        
        // Calculate gradient for positive sentiments
        if (sentiment > 0) {
          const ratio = sentiment / maxSentiment;
          return interpolateColor(NEUTRAL_COLOR, HIGHEST_COLOR, ratio);
        }
        
        // Calculate gradient for negative sentiments
        if (sentiment < 0) {
          const ratio = sentiment / minSentiment;
          return interpolateColor(NEUTRAL_COLOR, LOWEST_COLOR, ratio);
        }
        
        return NEUTRAL_COLOR;
      };

      const existingPositions: Array<{ x: number; y: number; size: number }> = [];
      
      const newData = resultModifyArray
        .map((el, index) => {
          const { bubbleSize, fontSize } = calculateBubbleSizeAndFontSize(el.name, el.size);
          
          const maxSentiment = Math.max(...resultModifyArray.map(item => item.sentiment));
          const useWhiteText = el.sentiment === maxSentiment;
          
          const position = calculateBubblePosition(
            el.sentiment,
            bubbleSize,
            existingPositions,
            PLOT_HEIGHT
          );
          
          existingPositions.push({
            x: position.x,
            y: position.y,
            size: bubbleSize
          });
          
          const fillColor = getColor(el);
          const hex = fillColor.replace('#', '');
          const r = parseInt(hex.slice(0, 2), 16);
          const g = parseInt(hex.slice(2, 4), 16);
          const b = parseInt(hex.slice(4, 6), 16);
          
          const darkerR = Math.max(0, Math.floor(r * 0.7));
          const darkerG = Math.max(0, Math.floor(g * 0.7));
          const darkerB = Math.max(0, Math.floor(b * 0.7));
          const borderColor = `#${darkerR.toString(16).padStart(2, '0')}${darkerG.toString(16).padStart(2, '0')}${darkerB.toString(16).padStart(2, '0')}`;
          
          return {
            x: position.x,
            y: position.y,
            z: bubbleSize,
            name: el.name,
            formattedText: `<div 
              data-point-id="${index}"
              style="
                font-size: ${fontSize}px;
                width: 100%;
                text-align: center;
                position: relative;
                pointer-events: none;
                word-wrap: break-word;
                color: ${useWhiteText ? '#FFFFFF' : 'var(--colorNeutralForeground1)'};
              ">${formatTextToFit(el.name)}</div>`,
            marker: {
              fillColor: fillColor,
              lineWidth: 0.5,
              lineColor: borderColor,
              states: {
                hover: {
                  enabled: true
                }
              }
            },
          };
        });

      setGraphData(newData);
      setGraphOptions(prev => ({
        ...prev,
        plotHeight: PLOT_HEIGHT,
        chartHeight: CHART_HEIGHT,
        maxX,
        minX,
        minY: 0,
        maxY: PLOT_HEIGHT,
      }));
    }
  }, [store.projectInfo, store.questionSelectedOpenEndedCard]);

  const chartOptions: Highcharts.Options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "bubble",
      height: graphOptions.chartHeight,
      marginTop: LABEL_SPACE,
      marginBottom: LABEL_SPACE,
      spacing: [20, 40, 20, 40],
      style: {
        fontFamily: 'inherit'
      }
    },
    title: {
      text: "",
    },

    tooltip: {
      useHTML: true,
      backgroundColor: "var(--colorShadowInverted2)",
      borderWidth: 0,
      headerFormat: "",
      pointFormat: `
        <div style="
          padding: 8px 12px;
          border-radius: 4px;
        ">
          <div>{point.name}</div>
          <div>Sentiments: {point.x}</div>
        </div>
      `,
      shared: false,
      style: {
        color: "var(--colorNeutralBackground1)",
        zIndex: 9999999,
        pointerEvents: 'none'
      },
      className: 'bubble-tooltip',
    },

    plotOptions: {
      bubble: {
        animation: false,
        showInLegend: false,
        crisp: true,
        marker: {
          fillOpacity: 0.8,
          lineWidth: 0,
          states: {
            hover: {
              enabled: true,
              radiusPlus: 0
            },
            inactive: {
              opacity: 0.1,
            }
          }
        },
        minSize: `${MIN_BUBBLE_SIZE}px`,
        maxSize: `${MAX_BUBBLE_SIZE}px`,
        sizeBy: 'width',
        dataLabels: {
          enabled: true,
          defer: false,
          allowOverlap: true,
          inside: true,
          crop: false,
          overflow: 'allow',
          useHTML: true,
          format: '{point.formattedText}',
          className: 'bubble-label',
          style: {
            textAlign: 'center',
            textOverflow: 'ellipsis',
            pointerEvents: 'none'
          },
          formatter: function() {
            return `<div 
              data-point-id="${this.point.index}"
              class="bubble-text ${this.point.x > 5 ? 'positive-text' : 'neutral-text'}"
              style="
                font-size: ${FONT_SIZE}px;
                width: 100%;
                text-align: center;
                position: relative;
                pointer-events: none;
                word-wrap: break-word;
              ">${formatTextToFit(this.point.name)}</div>`;
          }
        },
        states: {
          inactive: {
            opacity: 0.1,
            enabled: true
          },
          hover: {
            enabled: true,
            halo: {
              size: 0,
            },
            brightness: 0,
          },
        },
        stickyTracking: false,
        enableMouseTracking: true,
        point: {
          events: {
            mouseOver: function(e: Highcharts.PointerEventObject) {
              const chart = this.series.chart;
              const point = this;
              
              // Only change opacity
              chart.series[0].points.forEach(p => {
                if (p !== point) {
                  if (p.graphic) {
                    p.graphic.css({
                      opacity: 0.5
                    });
                  }
                  const labelDiv = chart.container.querySelector(`[data-point-id="${p.index}"]`) as HTMLElement;
                  if (labelDiv) {
                    labelDiv.style.opacity = '0.5';
                  }
                } else {
                  if (p.graphic) {
                    p.graphic.css({
                      opacity: 1
                    });
                  }
                  const labelDiv = chart.container.querySelector(`[data-point-id="${p.index}"]`) as HTMLElement;
                  if (labelDiv) {
                    labelDiv.style.opacity = '1';
                  }
                }
              });
            },
            mouseOut: function() {
              const chart = this.series.chart;
              chart.series[0].points.forEach((p, index) => {
                if (p.graphic) {
                  p.graphic.css({
                    opacity: 1
                  });
                }
                const labelDiv = chart.container.querySelector(`[data-point-id="${index}"]`) as HTMLElement;
                if (labelDiv) {
                  labelDiv.style.opacity = '1';
                }
              });
            }
          }
        }
      }
    },

    boost: {
      enabled: false,
      useGPUTranslations: false
    },

    zAxis: {
      startOnTick: false,
      endOnTick: false
    },

    xAxis: {
      height: graphOptions.plotHeight,
      width: "100%",
      visible: true,
      ceiling: graphOptions.maxX,
      floor: graphOptions.minX,
      max: graphOptions.maxX,
      min: graphOptions.minX,
      lineColor: "transparent",
      tickWidth: 0,
      gridLineWidth: 1,
      showLastLabel: true,
      angle: 20,
      offset: 0,
      left: 50,
      opposite: true,
      labels: {
        enabled: true,
        y: 15,
        x: 0,
        align: 'center',
        formatter: (e) => {
          if (e.isFirst) {
            return `<div style="
              font-size: 14px; 
              color: var(--colorNeutralForeground1); 
              font-weight: 400; 
              white-space: nowrap; 
              position: relative; 
              z-index: 2;
              text-align: left;
              margin-right: 50px;
              padding-left: 20px;
            "><b>Negative</b><br/>${e.value}</div>`;
          } else if (e.isLast) {
            return `<div style="
              font-size: 14px; 
              color: var(--colorNeutralForeground1); 
              font-weight: 400; 
              white-space: nowrap; 
              position: relative; 
              z-index: 2;
              text-align: right;
              margin-left: -50px;
              padding-right: 20px;
            "><b>Positive</b><br/>${e.value}</div>`;
          } else if (e.value === 0) {
            return `<div style="
              font-size: 14px; 
              color: var(--colorNeutralForeground1); 
              font-weight: 400; 
              white-space: nowrap; 
              position: relative; 
              z-index: 2;
              min-width: max-content;
            "><b>Neutral</b><br/>${e.value}</div>`;
          } else return ``;
        },
        overflow: 'allow',
        style: {
          textOverflow: 'none'
        }
      },
      maxPadding: 0.2,
      minPadding: 0.2,
    },
    yAxis: {
      height: graphOptions.plotHeight,
      visible: true,
      ceiling: graphOptions.maxY,
      floor: 0,
      min: 0,
      max: graphOptions.maxY,
      startOnTick: false,
      endOnTick: false,
      maxPadding: 0.2,
      minPadding: 0.2,
      labels: {
        enabled: false,
        align: 'right',
        x: -10,
        style: {
          color: 'var(--colorNeutralForeground1)',
          fontSize: '12px'
        }
      },
      title: {
        text: '',
        style: {
          color: 'var(--colorNeutralForeground1)',
          fontSize: '14px'
        }
      },
      gridLineWidth: 0,
    },

    series: [{
      type: 'bubble',
      minSize: `${MIN_BUBBLE_SIZE}px`,
      maxSize: `${MAX_BUBBLE_SIZE}px`,
      data: graphData,
      enableMouseTracking: true,
      stickyTracking: false,
      marker: {
        lineWidth: 0
      }
    }],
  };

  const isPending = store.isOpenEndPending || !store.projectInfo || !graphData.length;

  const renderCardContent = () => {
    if (isPending) {
      return <CardLoader contentHeight={720} />;
    }

    return (
      <>
        <style>
          {`
            .bubble-point {
              pointer-events: all !important;
              cursor: pointer;
            }
            .bubble-point:hover {
              z-index: 999 !important;
            }
            .highcharts-data-label > span > div {
              transform-origin: center center !important;
              z-index: 1 !important;
            }
            .highcharts-tooltip {
              position: fixed !important;
              z-index: 999999 !important;
            }
            .highcharts-tooltip-container {
              position: fixed !important;
              z-index: 999999 !important;
            }
            .bubble-tooltip {
              position: fixed !important;
              z-index: 999999 !important;
            }
            .highcharts-data-label {
              position: relative !important;
              z-index: 1 !important;
            }
            .highcharts-data-labels > * {
              pointer-events: none !important;
              z-index: 1 !important;
            }
            .highcharts-series-group {
              z-index: 1 !important;
            }
            .highcharts-series {
              z-index: 1 !important;
            }
            .bubble-text {
              transition: opacity 0.2s ease-in-out !important;
              z-index: 1 !important;
            }
            .highcharts-data-label.inactive .bubble-text {
              opacity: 0.1 !important;
            }
            .bubble-tooltip {
              z-index: 9999999 !important;
              pointer-events: none !important;
              position: absolute !important;
            }
            .highcharts-tooltip-container {
              z-index: 9999999 !important;
              pointer-events: none !important;
              position: absolute !important;
            }
            .highcharts-data-label {
              z-index: 1 !important;
            }
            .positive-text {
              color: #FFFFFF !important;
              z-index: 1 !important;
            }
            .neutral-text {
              color: var(--colorNeutralForeground1) !important;
              z-index: 1 !important;
            }
            .highcharts-data-label {
              transition: visibility 0.2s ease-in-out !important;
            }
          `}
        </style>
        <HighchartsReact 
          highcharts={Highcharts} 
          options={chartOptions}
          containerProps={{ style: { height: '100%' } }}
        />
      </>
    );
  };

  return (
    <Card>
      <TitleRow>
        <Title>Topics from open-ended questions</Title>
        {!isPending && ENABLE_IN_DEVELOPMENT_FEATURES && (
          <ViewLink onClick={store.redirectOpenEnded}>View open-ended responses</ViewLink>
        )}
      </TitleRow>
      {!isPending && (
        <>
          <TitleSelect>
            <TitleText>Question selected:</TitleText>
            <StyledSelect
              id="question_selected"
              value={store.questionSelectedOpenEndedCard}
              onChange={(e: SelectChangeEvent<string>) =>
                store.setData(e.target.value, "questionSelectedOpenEndedCard")
              }
              displayEmpty
              IconComponent={KeyboardArrowDownIcon}
              MenuProps={{
                PaperProps: { sx: { maxHeight: 300, maxWidth: "10%", minWidth: 100 } },
              }}
            >
              {store.surveyQuestionsList.map((question) => (
                <StyledMenuItem key={question.value} value={question.value}>
                  <em>{question.label}</em>
                </StyledMenuItem>
              ))}
            </StyledSelect>
          </TitleSelect>
          <TitleInfo>
            <Group>
              <TitleText>Topics: {store.openEndedTopics || "-"}</TitleText>
              <TitleText>Topic-valid responses: {store.openEndedTopicsValid || "-"}</TitleText>
            </Group>
          </TitleInfo>
        </>
      )}
      <ChartWrapper>
        {isPending ? (
          <CardLoader contentHeight={720} />
        ) : (
          <>
            <style>
              {`
                .bubble-point {
                  pointer-events: all !important;
                  cursor: pointer;
                }
                .bubble-point:hover {
                  z-index: 999999 !important;
                }
                .highcharts-data-label > span > div {
                  transform-origin: center center !important;
                }
                .highcharts-tooltip {
                  pointer-events: none !important;
                  position: absolute !important;
                  z-index: 1000000 !important;
                }
                .highcharts-data-labels > * {
                  pointer-events: none !important;
                }
                .highcharts-series-group {
                  z-index: auto !important;
                }
                .highcharts-series {
                  z-index: auto !important;
                }
              `}
            </style>
            <HighchartsReact 
              highcharts={Highcharts} 
              options={chartOptions}
              containerProps={{ style: { height: '100%' } }}
            />
          </>
        )}
      </ChartWrapper>
    </Card>
  );
});

export default OpenEndedQuestionsCard;

const Card = styled.div`
  width: 100%;
  padding: 24px;
  border-radius: 8px;
  background-color: var(--colorNeutralBackground1);
`;

const TitleRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Title = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
  margin-right: 4px;
`;

const ViewLink = styled.a`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  margin-left: 8px;
  color: var(--colorBrandForeground1);

  &:hover {
    color: var(--colorBrandForeground2);
  }
`;

const ChartWrapper = styled.div`
  width: 100%;
  margin-top: 24px;
  min-height: 500px;
`;

const TitleSelect = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 48px;
`;

const TitleInfo = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 14px;
  padding: 16px 0px;
  border-bottom: 1px solid var(--colorPaletteBlueBackground1);
`;

const TitleText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
  margin-right: 20px;
  white-space: nowrap;
`;

const Group = styled.div`
  display: flex;
  align-items: center;
`;

const SwitchText = styled.p`
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0px;
  color: var(--colorNeutralForeground1);
  margin-right: 9px;
  white-space: nowrap;
`;

const StyledSelect = styled(Select)`
  height: 34px;
  width: 100% !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 2px !important;
  font-size: 14px !important;
  font-weight: 400 !important;

  em {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: var(--colorNeutralForeground1) !important;
    font-style: normal !important;
  }
`;

const StyledMenuItem = styled(MenuItem)`
  white-space: normal !important;
  em {
    font-style: normal !important;
  }
`;
