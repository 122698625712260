import { observer } from "mobx-react";
import { LinearProgress } from "@mui/material";
import { FC, Fragment, useEffect, useState } from "react";
import { DEFAULT_PAGINATION_DATA, OTHER_QUESTIN_TYPE } from "constants/dashboard-responses";
import { TDefaultPagination, TOthersDimensionQuestion } from "types/dashboard";

import Tabs from "@mui/material/Tabs";
import store from "./store";
import styled from "styled-components";
import topStore from "../../store";
import Accordion from "@mui/material/Accordion";
import PeopleIcon from "@mui/icons-material/People";
import RemoveIcon from "@mui/icons-material/Remove";
import MessageIcon from "@mui/icons-material/Message";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import PaginationList from "../PaginationList";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ThumbsUpDownIcon from "@mui/icons-material/ThumbsUpDown";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import FormatSizeRounded from "@mui/icons-material/FormatSizeRounded";

type QuestionCardProps = {
  question: TOthersDimensionQuestion;
  isLastQuestion: boolean;
  localFilters: { id: number; name: string; segments: string[] }[];
};

const QuestionCard: FC<QuestionCardProps> = observer((props) => {
  const [isCommentsExpanded, setIsCommentsExpanded] = useState<boolean>(false);
  const [isOthersQuestionExpanded, setIsOthersQuestionExpanded] = useState<boolean>(false);
  const [activeCommentsTab, setActiveCommentsTab] = useState(
    props.question?.responseList[0]?.id || null
  );
  const [commentsList, setCommentsList] = useState<string[]>([]);
  const [commentsPagination, setCommentsPagination] =
    useState<TDefaultPagination>(DEFAULT_PAGINATION_DATA);

  const [otherCommentsList, setOtherCommentsList] = useState<string[]>([]);
  const [otherCommentsPagination, setOtherCommentsPagination] =
    useState<TDefaultPagination>(DEFAULT_PAGINATION_DATA);

  const changeCommentsPagination = (field: string, value: number) =>
    setCommentsPagination((prev) => ({ ...prev, [field]: value }));

  const changeOtherCommentsPagination = (field: string, value: number) =>
    setOtherCommentsPagination((prev) => ({ ...prev, [field]: value }));

  const changeCommentsTab = (id: string) => {
    setActiveCommentsTab(id);
    setCommentsPagination(DEFAULT_PAGINATION_DATA);
  };

  useEffect(() => {
    const getData = async () => {
      if (isCommentsExpanded && activeCommentsTab && topStore.selectedSurvey && props.question.id) {
        const response = await store.getDashboardResponsesOtherComments(
          topStore.selectedSurvey.value,
          props.localFilters,
          props.question.id,
          activeCommentsTab,
          { offset: commentsPagination.active_page, limit: commentsPagination.count_per_page }
        );

        setCommentsList(response.data);
        setCommentsPagination({
          active_page: response.active_page,
          count_per_page: response.count_per_page,
          total_count: response.total_count,
          total_pages: response.total_pages
        });
      }
    };

    getData();
  }, [
    isCommentsExpanded,
    activeCommentsTab,
    topStore.selectedSurvey,
    commentsPagination.active_page,
    topStore.overviewBenchmark,
    props.localFilters
  ]);

  useEffect(() => {
    const getData = async () => {
      if (isOthersQuestionExpanded && topStore.selectedSurvey && props.question.id) {
        const response = await store.getDashboardResponsesOtherOptions(
          topStore.selectedSurvey.value,
          props.localFilters,
          props.question.id,
          {
            offset: otherCommentsPagination.active_page,
            limit: otherCommentsPagination.count_per_page
          }
        );

        setOtherCommentsList(response.data);
        setOtherCommentsPagination({
          active_page: response.active_page,
          count_per_page: response.count_per_page,
          total_count: response.total_count,
          total_pages: response.total_pages
        });
      }
    };

    getData();
  }, [
    isOthersQuestionExpanded,
    topStore.selectedSurvey,
    otherCommentsPagination.active_page,
    topStore.overviewBenchmark
  ]);

  const renderminMaxValues = () => {
    const isTypeMinNumber = typeof props.question.min === "number";
    const isTypeMaxNumber = typeof props.question.max === "number";

    return `MCQ: Multiple selection allowed 
      ${(isTypeMinNumber || isTypeMaxNumber) && `(`}
      ${isTypeMinNumber && `Minimum: ${props.question.min}`}
      ${isTypeMinNumber && isTypeMaxNumber && `, `}
      ${isTypeMaxNumber && `Maximum: ${props.question.max}`}
      ${(isTypeMinNumber || isTypeMaxNumber) && `)`}
`;
  };

  return (
    <Container $isLast={props.isLastQuestion}>
      <ColGroup>
        <Col>
          <Title>{props.question?.title}</Title>
          <Group>
            <StyledPeopleIcon />
            <SmallText>{props.question?.respondedCount} responded</SmallText>
          </Group>
          <Group>
            {props.question.type === OTHER_QUESTIN_TYPE.YES_NO ? (
              <StyledThumbsUpDownIcon />
            ) : (
              <StyledDoneAllIcon />
            )}
            {props.question.type === OTHER_QUESTIN_TYPE.MULTIPLE && (
              <SmallText>{renderminMaxValues()}</SmallText>
            )}
            {props.question.type === OTHER_QUESTIN_TYPE.SINGLE && (
              <SmallText>MCQ: Single selection only</SmallText>
            )}
            {props.question.type === OTHER_QUESTIN_TYPE.YES_NO && <SmallText>Yes/No</SmallText>}
          </Group>
        </Col>
        <Col>
          <Label>Response count</Label>
          <ResponseList>
            {props.question.responseList.map((response) => (
              <ResponseItem>
                <ResponseText>{response.title}</ResponseText>
                <ProgressCell>
                  <BorderLinearProgress variant="determinate" value={response.percent} />
                </ProgressCell>
                <StatContainer>
                  <PercentText>{response.percent}%</PercentText>
                  <ComparisonGroup>
                    <Text>{response.countMC}</Text>
                    {topStore.selectedSurvey?.isFirst ? (
                      <StyledRemoveIcon />
                    ) : (
                      <>
                        {
                          response.diffMC > 0 ? (
                            <StyledArrowDropUpIcon />
                          ) : response.diffMC < 0 ? (
                            <StyledArrowDropDownIcon />
                          ) : (
                            <StyledEmptyIcon />
                          )}
                        {response.diffMC !== 0 && response.diffMC ? (
                          <Text>{Math.abs(response.diffMC)}</Text>
                        ) : (<StyledEmptyText>{response.diffMC}</StyledEmptyText>)}
                      </>
                    )}
                  </ComparisonGroup>
                </StatContainer>
              </ResponseItem>
            ))}
          </ResponseList>
        </Col>
      </ColGroup>
      {props.question.commentsCount > 0 && (
        <StyledAccordion
          expanded={isCommentsExpanded}
          onChange={() => setIsCommentsExpanded(!isCommentsExpanded)}
        >
          <AccordionSummary aria-controls="comments-accordion" id="comments">
            <AccordionTitle>
              <AccordionTitleGroup>
                <StyledMessageIcon />
                <AccordionTitleText>Comments ({props.question.commentsCount})</AccordionTitleText>
              </AccordionTitleGroup>
              <AccordionToogleButton>
                {isCommentsExpanded ? "Hide" : "Show"}
                <AccordionToogleButtonLabel>
                  {isCommentsExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </AccordionToogleButtonLabel>
              </AccordionToogleButton>
            </AccordionTitle>
          </AccordionSummary>
          <AccordionDetails>
            <Tabs variant="scrollable" scrollButtons aria-label="scrollable comments tab">
              {props.question.responseList.map((response) => (
                <StyledTab
                  $active={Boolean(activeCommentsTab === response.id)}
                  onClick={() => changeCommentsTab(response.id)}
                >
                  <StyledTabText>{response.title}</StyledTabText>
                </StyledTab>
              ))}
            </Tabs>
            <AccordionContent>
              <PaginationList
                list={commentsList}
                pagination={commentsPagination}
                changePagination={changeCommentsPagination}
              />
            </AccordionContent>
          </AccordionDetails>
        </StyledAccordion>
      )}
      {props.question.otherOptionsReponsesCount > 0 && (
        <StyledAccordion
          expanded={isOthersQuestionExpanded}
          onChange={() => setIsOthersQuestionExpanded(!isOthersQuestionExpanded)}
        >
          <AccordionSummary aria-controls="others-question-accordion" id="others-question">
            <AccordionTitle>
              <AccordionTitleGroup>
                <StyledMessageIcon />
                <AccordionTitleText>
                  Responses for ‘Others’ option ({props.question.otherOptionsReponsesCount})
                </AccordionTitleText>
              </AccordionTitleGroup>
              <AccordionToogleButton>
                {isOthersQuestionExpanded ? "Hide" : "Show"}
                <AccordionToogleButtonLabel>
                  {isOthersQuestionExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </AccordionToogleButtonLabel>
              </AccordionToogleButton>
            </AccordionTitle>
          </AccordionSummary>
          <AccordionDetails>
            <AccordionContent>
              <PaginationList
                list={otherCommentsList}
                pagination={otherCommentsPagination}
                changePagination={changeOtherCommentsPagination}
              />
            </AccordionContent>
          </AccordionDetails>
        </StyledAccordion>
      )}
    </Container>
  );
});

export default QuestionCard;

const Container = styled.div<{ $isLast: boolean }>`
    width: 100%;
    padding-bottom: 48px;
    ${(props) => !props.$isLast && "border-bottom: 1px solid var(--colorPaletteBlueBackground1)"};
    margin-top: 24px;
`;

const ColGroup = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 3fr;
    column-gap: 49px;
    margin-bottom: 32px;
`;

const Col = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const Title = styled.p`
    font-family: Roboto;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    color: var(--colorNeutralForeground1);
    margin-bottom: 12px;
`;

const Group = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 4px;
`;

const SmallText = styled.p`
    font-family: Roboto;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: var(--colorNeutralForeground2);
    margin-left: 4px;
`;

const StyledPeopleIcon = styled(PeopleIcon)`
    font-size: 16px !important;
    color: var(--colorNeutralForeground5);
`;

const StyledDoneAllIcon = styled(DoneAllIcon)`
    font-size: 16px !important;
    color: var(--colorPaletteVioletBackground2);
`;

const StyledThumbsUpDownIcon = styled(ThumbsUpDownIcon)`
    font-size: 16px !important;
    color: var(--colorPaletteVioletBackground2);
`;

const Label = styled.p`
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--colorNeutralForeground1);
    margin-bottom: 4px;
`;

const ResponseList = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const ResponseItem = styled.div`
    width: 100%;
    display: table;
    border-radius: 10px;
    background-color: var(--colorNeutralForeground8);
    padding: 12px 16px;
    margin-top: 8px;
    table-layout: fixed;
`;

const ResponseText = styled.div`
    display: table-cell;
    width: 50%;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--colorNeutralForeground1);
    vertical-align: middle;
`;

const ProgressCell = styled.div`
    display: table-cell;
    width: 200px;
    vertical-align: middle;
    text-align: center;
`;

const StatContainer = styled.div`
    display: table-cell;
    width: 160px;
    vertical-align: middle;
`;

const PercentText = styled.span`
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--colorNeutralForeground1);
    display: inline-block;
    width: 48px;
    text-align: right;
    margin-right: 24px;
`;

const ComparisonGroup = styled.span`
    display: inline-grid;
    grid-template-columns: 24px auto 24px;
    gap: 4px;
    align-items: center;

    & > * {
        display: flex;
        align-items: center;
    }

    & > *:first-child {
        justify-self: end;
        justify-content: flex-end;
        text-align: right;
        width: 100%;
    }

    & > *:nth-child(2) {
        justify-self: center;
        display: flex;
        align-items: center;
        height: 24px;
    }

    & > *:last-child {
        justify-self: start;
        justify-content: flex-start;
        text-align: right;
        width: 100%;
    }
`;

const Text = styled.p`
    font-family: Roboto;
    font-size: 14px !important;
    font-weight: 500;
    line-height: 20px;
    color: var(--colorNeutralForeground1);
`;

const StyledArrowDropUpIcon = styled(ArrowDropUpIcon)`
    color: var(--colorPaletteGreenBackground2);
    font-size: 24px !important;
`;

const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)`
    color: var(--colorPaletteRedForeground1);
    font-size: 24px !important;
    margin-top: -4px !important;
`;

const StyledRemoveIcon = styled(RemoveIcon)`
    color: var(--colorNeutralForeground5);
    font-size: 18px !important;
`;

const StyledEmptyIcon = styled(FormatSizeRounded)`
    color: transparent;
    font-size: 18px !important;
`;

const BorderLinearProgress = styled(LinearProgress)`
    height: 12px !important;
    width: 100px;
    margin: 0 auto;
    background-color: var(--grayD9DDE7) !important;
    border-radius: 2px !important;

    .MuiLinearProgress-bar {
        background-color: var(--colorPalettePurpleForeground1) !important;
        border-radius: 2px !important;
    }
`;

const AccordionTitle = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 4px 0px;
`;

const StyledMessageIcon = styled(MessageIcon)`
    color: var(--colorNeutralForeground5);
    font-size: 16px !important;
`;

const AccordionTitleText = styled.p`
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--colorNeutralForeground1);
    margin-left: 6px;
`;

const AccordionToogleButton = styled.button`
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--colorBrandForeground1);
    border: none;
    cursor: pointer;
    background-color: transparent;
    display: flex;
    align-items: center;
`;

const StyledAccordion = styled(Accordion)`
    margin-top: 8px;
    border-radius: 10px !important;
    border: 1px solid var(--colorPaletteBlueBackground1) !important;
    box-shadow: 0px 2px 8px 0px var(--colorNeutralBackground3) !important;

    &::before {
        display: none !important;
    }

    .MuiTabs-root {
        min-height: 0 !important;
        height: 40px !important;
    }

    .Mui-disabled {
        opacity: 1 !important;
    }

    .MuiTabScrollButton-root {
        border: 1px solid var(--colorPaletteBlueBackground1) !important;
    }
`;

const AccordionTitleGroup = styled.div`
    display: flex;
    align-items: center;
`;

const AccordionToogleButtonLabel = styled.span`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    box-shadow: 0px 2px 5px 0px var(--colorNeutralBackground3);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 8px;
`;

const AccordionContent = styled.div`
    width: 100%;
    padding: 24px;
    background-color: var(--colorNeutralForeground8);
`;

const StyledTab = styled.span<{ $active: boolean }>`
    background-color: ${(props) =>
            props.$active ? "var(--colorNeutralBackground1)" : "var(--colorPaletteGrayBackground1)"};
    border: 1px solid var(--colorPaletteBlueBackground1);
    padding: 10px 24px;
    height: 40px;
    min-height: 0;
    width: fit-content;
    max-width: 180px;
    border-radius: 10px 10px 0px 0px;
    margin: 0px 4px;
    justify-content: flex-start;
    display: flex;
    align-items: center;
    cursor: pointer;

    p {
        color: ${(props) =>
                props.$active ? "var(--colorBrandForeground1)" : "var(--colorNeutralForeground2)"};
    }
`;

const StyledTabText = styled.p`
    font-family: Roboto;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    text-transform: none;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

const StyledEmptyText = styled.p`
    font-family: Roboto;
    font-size: 14px !important;
    font-weight: 500;
    line-height: 20px;
    color: var(--colorNeutralForeground2);
`;
