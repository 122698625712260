import { FC } from "react";
import styled from "styled-components";
import { observer } from "mobx-react";
import store from "./store";
import { IconXlsx } from "./Icons/IconXlsx";
import CustomButton from "components/button/Button";

type ReviewChangesProps = {};

const ReviewChanges: FC<ReviewChangesProps> = observer((props) => {
  return (
    <ReviewChangesWrapper id={`ImportHistoricalSurvey_ReviewChangesBlock`}>
      <Content>
        <Header id={`ImportHistoricalSurvey_ReviewChangesHeader`}>
          Review changes before importing
        </Header>
        <FileWrapper>
          <IconXlsx />
          <p id={`ImportHistoricalSurvey_ReviewChangesFileName`}>
            {store.fileName|| "File not found"}
          </p>
        </FileWrapper>
        <Row>
          <StatisticContainer>
            <WhatHappens id={`ImportHistoricalSurvey_ReviewChangesDescription`}>
              What happens next after importing your file?
            </WhatHappens>
            <Unit>
              <UnitRowText id={`ImportHistoricalSurvey_ReviewChangesInfo_1`}>
                "{store.surveyName || "Survey name"}" will be created as an historical survey within
                your survey project.
              </UnitRowText>
              <UnitRowText id={`ImportHistoricalSurvey_ReviewChangesInfo_2`}>
                Historical survey data will be displayed on the dashboard based on the questionnaire
                and raw responses provided.
              </UnitRowText>
            </Unit>
            <ReminderText>Should you need to make any edits to the imported data, please delete and upload again.</ReminderText>
          </StatisticContainer>
        </Row>
        <ConfirmButton
          id={`ImportHistoricalSurvey_ReviewChangesConfirmButton`}
          fullWidth
          variant={"contained"}
          onClick={() => store.confirmAndImport()}
        >
          Confirm and import
        </ConfirmButton>
      </Content>
    </ReviewChangesWrapper>
  );
});

export default ReviewChanges;

const ReviewChangesWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`;

const Content = styled.div`
  width: 100%;
  max-width: 660px;
  display: flex;
  flex-direction: column;
  padding-bottom: 40px;
`;

const Header = styled.h1`
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin: 0 0 20px 0;
`;

const WhatHappens = styled.p`
  font-weight: 500;
`;

const FileWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 28px;

  p {
    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    color: var(--colorNeutralForeground1);
  }

  svg {
    margin-right: 10px;
  }
`;

const StatisticContainer = styled.div<{ $notLast?: boolean }>`
  width: 100%;
  max-width: 584px;
  border-radius: 4px;
  padding: 20px 16px;
  background-color: var(--colorNeutralBackground1);
  margin-top: 20px;
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 30px;
`;

const Unit = styled.ul`
  width: 100%;
  margin-top: 20px;
  padding-inline-start: 15px;
`;

const UnitRowText = styled.li`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
`;

const ReminderText = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
`;

const ConfirmButton = styled(CustomButton)`
  max-width: 584px !important;
  margin-top: 10px !important;
`;
