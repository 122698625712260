import http from "api/https";


export interface HistoricalImportResponse {
    jobs: Array<HistoricalImportJob>;
}

export interface HistoricalImportJob {
    id: string;
    project_id: Number;
    survey_id?: Number;
    survey_name?: string;
    raw_data_filename: string;
    status?: string;
    error?: string;
}

export const useGetandAcknowledgeHistoricalImport = async (
    project_id: number, 
    status?: string
  ) => {
    const queryParams = `project_id=${project_id}` + (status ? `&status=${status}` : '');
    return http.getGO(`sm/sm_survey_historical_import?${queryParams}`);
  };
