import { FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import { Tabs, Tab, Box, Typography } from "@mui/material";

import FirstTab from "./FirstTab/FirstTab";
import SecondTab from "./SecondTab/SecondTab";
import ThirdTab from "./ThirdTab/ThirdTab";

import store from "../store";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      style={{ width: "100%" }}
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

type ScheduleCommunicationProps = {};

const ScheduleCommunication: FC<ScheduleCommunicationProps> = observer((props) => {
  return (
    <MainWrapper>
      <StyledTabs
        orientation="vertical"
        variant="scrollable"
        value={store.valueTab}
        onChange={store.handleTabSecondAccordionChange}
        aria-label="Vertical tabs example"
      >
        <StyledTab label="Email header & footer" {...a11yProps(0)} />
        <StyledTab label="Invite email" {...a11yProps(1)} />
        <StyledTab label="Reminder emails" {...a11yProps(2)} />
        <BoxMainContainer>
          <BoxMainText>{`All selected times are set according to your local timezone: UTC ${store.timezone_message}.`}</BoxMainText>
        </BoxMainContainer>
      </StyledTabs>
      <div style={{ width: 900 }}>
        <TabPanel value={store.valueTab} index={0}>
          <FirstTab />
        </TabPanel>
        <TabPanel value={store.valueTab} index={1}>
          <SecondTab />
        </TabPanel>
        <TabPanel value={store.valueTab} index={2}>
          <ThirdTab />
        </TabPanel>
      </div>
    </MainWrapper>
  );
});

export default ScheduleCommunication;

const MainWrapper = styled.div`
  flex-grow: 1;
  display: flex;
`;

const StyledTabs = styled(Tabs)`
  background-color: var(--colorNeutralForeground8);
  min-width: 245px;
  padding-top: 20px;
  margin: 0 0 -16px -16px;
  border-radius: 0 0 0 9px !important;
  .MuiTabs-indicator {
    left: 0;
    width: 6px;
    background-color: var(--colorBrandForeground1);
  }
`;

const StyledTab = styled(Tab)`
  text-transform: none !important;
  color: var(--colorNeutralForeground2);
  align-items: baseline !important;
  padding-left: 28px !important;
  &.MuiTab-root.Mui-selected {
    color: var(--colorBrandForeground1);
  }
`;

const BoxMainContainer = styled.div`
  width: 100%;
  margin-top: 40px;
  padding-left: 27px;
  padding-right: 38px;
`

const BoxMainText = styled.div`
  width: 180px;
  color: var(--ER-Theme-sys-light-on-tertiary-container, #707882);
  /* ER Theme/body/medium */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
  white-space: normal;
  border-top: 1px solid var(--colorNeutralForegroundLightGray);
  padding-top: 24px;
`;
