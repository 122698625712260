import { useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import styled from "styled-components";
import { observer } from "mobx-react";

type TimePickerValueProps = {
  value: Dayjs | null;
  changeValue: (value: Dayjs) => void;
  disabled?: boolean;
  minTime?: Dayjs | null;
  pairWithDate?: boolean;
  showCustomError?: boolean;
  onError?: (value: boolean, message: string) => void;
};

const TimePickerValue: React.FC<TimePickerValueProps> = observer((props) => {
  const { onError = () => {}, showCustomError = false } = props;

  const [open, setOpen] = useState(false);
  const [minTime, setMinTime] = useState<Dayjs | null>(props.minTime);
  const [disabled, setDisabled] = useState<boolean>(props.disabled);
  const [timeValue, setTimeValue] = useState<Dayjs | null>(props.value);
  const [isError, setIsError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState("");

  const isInPair = props.pairWithDate ?? false;

  useEffect(() => {
    if (props.value !== timeValue) {
      setTimeValue(props.value);
    }
  }, [props.value]);

  useEffect(() => {
    if (props.minTime !== minTime) {
      setMinTime(props.minTime);
    }
  }, [props.minTime]);

  useEffect(() => {
    setDisabled(props.disabled);
  }, [props.disabled]);

  useEffect(() => {
    onError(isError, errorMessage)
  }, [isError, errorMessage])

  useEffect(() => {
    if (disabled) {
      setTimeValue(null);
    } else {
      setTimeValue(props.value);
      defaultTimeWODate();
    }
  }, [disabled]);

  const defaultTimeWODate = () => {
    if (!isInPair) {
      const roundedTime = dayjs()
        .minute(Math.floor(dayjs().minute() / 5) * 5)
        .second(0)
        .add(15, "minute");

      setMinTime(roundedTime);
    }
  };

  const handleOnError = (error) => {
    if (error) {
      setIsError(true);
      setErrorMessage("You can only schedule the survey 15 minutes from now.");
      return;
    } 
    
    setIsError(false);
    setErrorMessage("");
  };

  return (
    <MainWrapper>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <WrappedTimeField
          $error={showCustomError}
          open={open}
          disabled={disabled}
          value={timeValue}
          label={!disabled ? "Select time" : ""}
          closeOnSelect={false}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          onChange={(newValue: Dayjs) => {
            if (newValue instanceof dayjs || newValue === null) {
              props.changeValue(newValue);
              setTimeValue(newValue);
            }
          }}
          minTime={minTime}
          slotProps={{
            textField: {
              InputLabelProps: { shrink: true },
              inputProps: { placeholder: "HH:MM AM" },
              onKeyDown: (event) => {
                event.preventDefault();
                setOpen(true);
              },
              error: showCustomError || isError
            },
            openPickerIcon: { sx: { fontSize: 15 } },
          }}
          onError={(error) => handleOnError(error)}
        />
      </LocalizationProvider>
    </MainWrapper>
  );
});

const WrappedTimeField = styled(TimePicker)<{ $error: boolean }>`
  & .MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled {
    background-color: var(--colorGrayForeground7);
    cursor: not-allowed;
  }

  input {
    padding: 6px 8px;
    font-size: 14px;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 16px;

    ${(props) =>
      props.$error
        ? "border-color: var(--colorPaletteRedForeground2)"
        : "border-color: var(--colorPaletteBlueBackground1)"};
  }

  label {
    position: absolute;
    top: -3px;
  }
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`

export default TimePickerValue;
