import { FC } from "react";
import styled from "styled-components";
import ErrorIcon from "@mui/icons-material/Error";
import { observer } from "mobx-react";
import store from "./store";
import { IconXlsx } from "./Icons/IconXlsx";
import { ReportProblem } from "@mui/icons-material";

type ErrorsProps = {
  file: File;
};

const Errors: FC<ErrorsProps> = observer((props) => {
  return (
    <ErrorsWrapper id={`ImportHistoricalSurvey_ErrorsBlock`}>
      <Content>
        <Header id={`ImportHistoricalSurvey_ErrorsFound`}>{ store.errorType === "VALIDATION" ? "File not uploaded" : "File not imported" }</Header>
        <FileWrapper>
          <IconXlsx />
          <p id={`ImportHistoricalSurvey_FileName`}>{store.fileName || "File not found"}</p>
        </FileWrapper>
        <WarningContainer>
          <ReportProblem style={{ fontSize: '20px', color: '#FA6B6B' }} />
          <p
            id="ImportHistoricalSurvey_ErrorsDescription"
            dangerouslySetInnerHTML={{
              __html:
                store.errorType === "VALIDATION"
                  ? `We couldn’t upload the file because there are some errors in the Excel sheet. Please review the file and try uploading it again. 
           <br/><br/>If you need further assistance, feel free to reach out to our Customer Success team at support@engagerocket.co and we’ll help you sort it out!`
                  : `Oops! We couldn’t import your file. Please review the file and try uploading it again. <br/><br/>
           If you need further assistance, feel free to reach out to our Customer Success team at support@engagerocket.co and we’ll help you sort it out!`,
            }}
          ></p>
        </WarningContainer>
      </Content>
    </ErrorsWrapper>
  );
});

export default Errors;

const ErrorsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const Content = styled.div`
  width: 100%;
  max-width: 520px;
  display: flex;
  flex-direction: column;
`;

const Header = styled.h1`
  font-family: Roboto, sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  color: var(--colorNeutralForeground1);
  margin: 0 0 20px 0;
`;

const FileWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 28px;

  p {
    font-family: Roboto, sans-serif;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    color: var(--colorNeutralForeground1);
  }

  svg {
    margin-right: 10px;
  }
`;

const WarningContainer = styled.div`
  width: 100%;
  padding: 20px 30px;
  border-radius: 4px;
  display: flex;
  background-color: var(--colorNeutralBackground1);

  p {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--colorNeutralForeground2);
  }

  svg {
    color: var(--colorPaletteRedForeground2);
    margin-right: 10px;
  }
`;

const Unit = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--colorPaletteBlueBackground1);
  margin-top: 20px;
  padding-bottom: 15px;
`;

const UnitRowText = styled.li`
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
`;
