import { FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import RichTextWithTabsAll from "../../RichTextWithTabsAll";
import CustomButton from "components/button/Button";
import PreviewIcon from "../../PreviewIcon";
import store from "./../../store";
import { formatDateToLocalTime, formatToLocalDateTime } from "utils/timeFormatter";
import { SURVEY_STATUS } from "constants/survey-status";
import { SEND_OUT_SURVEY_TYPES } from "constants/send-out-survey";

type SecondTabProps = {};

const SecondTab: FC<SecondTabProps> = observer(() => {
  const getInviteEmailInfo = () => {
    const { AllInfo } = store;

    const renderRunningEmailInfo = (emailTime: string, surveyName?: string) => (
      <ResendEmailInfoWrapper>
        <p>
          Your invite email was sent on: <b>{surveyName ? `${surveyName} at ${emailTime}` : emailTime}</b>
        </p>
      </ResendEmailInfoWrapper>
    );

    const renderScheduledEmailInfo = (emailTime: string, surveyName?: string) => (
      <SendEmailInfoWrapper>
        <SendEmailInfoContainer>
          {emailTime ? (
            <>
              <SendEmailInfoParagraph>Your invite email will be sent on:</SendEmailInfoParagraph>
              <SendEmailInfoParagraph>
                <b>{surveyName ? `${surveyName} at ${emailTime}` : emailTime}</b>
              </SendEmailInfoParagraph>
            </>
          ) : (
            <SendEmailInfoParagraph>
              Your invite email <b>will not be sent</b>{" "}
              {surveyName ? (
                <>
                  the time for <b>{surveyName}</b>.
                </>
              ) : (
                <>your survey’s launch. It will be sent at the time your survey is launched on.</>
              )}
            </SendEmailInfoParagraph>
          )}
        </SendEmailInfoContainer>
      </SendEmailInfoWrapper>
    );

    const isMilestone = AllInfo?.project_type === SEND_OUT_SURVEY_TYPES.Milestones;
    const isRunning = AllInfo?.survey_status === SURVEY_STATUS.running;

    if (isMilestone) {
      const emailTime = AllInfo?.timestart_milestone
        ? formatDateToLocalTime(AllInfo.timestart_milestone)
        : null;

      return isRunning
        ? renderRunningEmailInfo(emailTime, AllInfo.name)
        : renderScheduledEmailInfo(emailTime, AllInfo.name);
    } else {
      const emailTime = AllInfo?.survey_date_start
        ? formatToLocalDateTime(AllInfo.survey_date_start)
        : null;

      return isRunning ? renderRunningEmailInfo(emailTime!) : renderScheduledEmailInfo(emailTime);
    }
  };

  return (
    <>
      <Wrapped>
        <Header id="Survey_EditCreate_Communication_ScheduleCommunications_Invite_Title">
          Invite email
        </Header>

        <TopContentWrapper>
          <SendEmailQuestion>WHEN SHOULD WE SEND THE EMAIL?</SendEmailQuestion>
          {getInviteEmailInfo()}
        </TopContentWrapper>

        <SubHeader id="Survey_EditCreate_Communication_ScheduleCommunications_Invite_SubTitle">
          EMAIL SUBJECT
        </SubHeader>

        <RichTextWithTabsAll
          onlyText
          data={store.InviteData}
          width={100}
          changeValue={store.changeInviteData}
          field="subject_email"
          langNameId="Survey_EditCreate_Communication_ScheduleCommunications_Invite_RichTextWith_Language"
          editorId="Survey_EditCreate_Communication_ScheduleCommunications_Invite_Subject_RichTextWith_Editor"
        />

        <SubHeader id="Survey_EditCreate_Communication_ScheduleCommunications_Invite_SubTitle2">
          EMAIL CONTENT
        </SubHeader>

        <RichTextWithTabsAll
          data={store.InviteData}
          width={200}
          changeValue={store.changeInviteData}
          field="body_email"
          langNameId="Survey_EditCreate_Communication_ScheduleCommunications_Invite_RichTextWith_Language"
          editorId="Survey_EditCreate_Communication_ScheduleCommunications_Invite_Content_RichTextWith_Editor"
        />

        <BottomWrapper>
          <ButtonWrapper>
            <StyledNextButton
              id="Survey_EditCreate_Communication_ScheduleCommunications_Invite_Save_Button"
              disabled={!store.saveInviteEmailBtn}
              variant="contained"
              onClick={() => store.saveInviteData()}
            >
              Save changes
            </StyledNextButton>
            <StyledCancelButton
              id="Survey_EditCreate_Communication_ScheduleCommunications_Invite_Cancel_Button"
              variant="outlined"
              disabled={!store.changedSecondTab}
              onClick={() => store.onCancelSecondTab()}
            >
              Cancel
            </StyledCancelButton>
          </ButtonWrapper>
          <ButtonWrapper>
            <LinkPreview
              id="Survey_EditCreate_Communication_ScheduleCommunications_Invite_Preview_Button"
              onClick={() => store.loadPreviewMessage(null, false)}
            >
              <PreviewIcon />
              <LinkPreviewText id="Survey_EditCreate_Communication_ScheduleCommunications_Invite_Preview_Button_Text">
                Preview email
              </LinkPreviewText>
            </LinkPreview>
          </ButtonWrapper>
        </BottomWrapper>
      </Wrapped>
    </>
  );
});

export default SecondTab;

const Wrapped = styled.div`
  margin: 10px 30px;
`;

const Header = styled.div`
  font-weight: 500;
  font-size: 22px;
  margin-bottom: 10px;
  margin-top: 10px;
  color: var(--colorNeutralForeground1);
`;

const BottomWrapper = styled.div`
  width: 100%;
  margin: 40px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LinkPreviewText = styled.span`
  margin-left: 3px;
`;

const LinkPreview = styled.a`
  margin-left: 5px;
  color: var(--colorBrandForeground1);
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;

  &:hover {
    text-decoration: underline;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const StyledCancelButton = styled(CustomButton)`
  width: 100%;
  max-width: 75px;
`;

const StyledNextButton = styled(CustomButton)`
  width: 100%;
  max-width: 150px;
`;

const SubHeader = styled.div`
  margin-bottom: 10px;
  margin-top: 30px;
  color: var(--colorNeutralForeground2);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
`;

const TopContentWrapper = styled.div`
  padding-bottom: 18px;
`;

const SendEmailQuestion = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--colorNeutralForeground2);
`;

const ResendEmailInfoWrapper = styled.div`
  padding: 17px 16px 17px 16px;
  background-color: var(--colorPaletteGreenBackground7);
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  margin-top: 16px;

  p {
    font-size: 14px;
    color: var(--colorNeutralForeground1);
    font-weight: 400;
  }

  b {
    font-size: 14px;
    color: var(--colorNeutralForeground1);
    font-weight: 700;
  }
`;

const SendEmailInfoWrapper = styled.div`
  padding: 16px;
  background-color: var(--colorPalleteLightBlue);
  display: flex;
  column-gap: 6px;
  border-radius: 4px;
  margin-top: 20px;

  p {
    color: var(--colorNeutralForeground1);
  }
`;

const SendEmailInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
`;

const SendEmailInfoParagraph = styled.p`
  font-size: 14px;
  line-height: 16px;
  font-weight: 400;
`;
