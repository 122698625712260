import { FC } from "react";
import { observer } from "mobx-react";
import { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { PARTICIPATION, QUESTION, SEGMENT } from "constants/dashboard-hotspots";

import store from "../store";
import styled, { css } from "styled-components";
import Tooltip from "@mui/material/Tooltip";
import PeopleIcon from "@mui/icons-material/People";

type GraphHeaderProps = {};

const CustomGraphHeader: FC<GraphHeaderProps> = observer(() => {
  const changeCollapseDimension = (
    dimension: string,
    dimensionIndex: number,
    dimensionCollapse: boolean
  ) => {
    const collapseDimension = store.graphData.data[0]?.list.find(
      (el) => el.dimension === dimension && el.type === SEGMENT
    );

    const newCategoriesList = store.graphData.categories.map((el) => {
      if (el.name === dimension && el.type === SEGMENT && el.countQuestions)
        return { ...el, collapse: !el.collapse };
      else return el;
    });

    if (collapseDimension && collapseDimension.countQuestions) {
      if (!dimensionCollapse) {
        const newQuestions = store.graphData.data[0]?.list.filter(
          (el) => el.dimension === collapseDimension.dimension && el.type === QUESTION
        );

        const editNewQuestions = newQuestions.map((el) => ({
          name: el.title,
          collapse: false,
          type: el.type,
          countQuestions: 0,
        }));

        newCategoriesList.splice(dimensionIndex + 1, 0, ...editNewQuestions);
      } else {
        newCategoriesList.splice(dimensionIndex + 1, collapseDimension.countQuestions);
      }

      store.setData(
        {
          data: store.graphData.data,
          categories: newCategoriesList,
        },
        "graphData"
      );
    }
  };

  const returnCountQuestions = (countQuestions: number, collapse: boolean) =>
    `(${collapse ? "Hide" : "View"} scores for ${countQuestions} ${countQuestions > 1 ? "qns" : "qn"
    })`;

  return (
    <Container>
      {store.graphData &&
        store.graphData?.categories?.map((el, index) => {
          const isMoreOneWord = el.name.trim().split(" ").length > 1;

          if (el.type !== QUESTION)
            return (
              <CustomWidthTooltip
                key={index + el.name}
                title={
                  <p>
                    {el.name} <br />
                    {el.countQuestions && returnCountQuestions(el.countQuestions, el.collapse)}
                  </p>
                }
                placement="top"
                arrow
              >
                <LabelWrapper
                  onClick={() => changeCollapseDimension(el.name, index, el.collapse)}
                  $isDimension
                >
                  <Label
                    $isDimension
                    $isParticipation={el.name === PARTICIPATION}
                    $isMoreOneWord={isMoreOneWord}
                  >
                    {el.name === PARTICIPATION && <PeopleIcon />}
                    {el.name}
                  </Label>
                </LabelWrapper>
              </CustomWidthTooltip>
            );
          else
            return (
              <LabelWrapper onClick={() => changeCollapseDimension(el.name, index, el.collapse)}>
                <CustomWidthTooltip
                  key={index + el.name}
                  title={
                    <p>
                      {el.name} <br />
                    </p>
                  }
                  placement="top"
                  arrow
                >
                  <Label $isParticipation={el.name === PARTICIPATION} $isMoreOneWord={isMoreOneWord}>
                    {el.name}
                  </Label>
                </CustomWidthTooltip>
              </LabelWrapper>
            );
        })}
    </Container>
  );
});

export default CustomGraphHeader;

type StyledLabelProps = {
  $isDimension?: boolean;
  $isParticipation?: boolean;
  $isMoreOneWord: boolean;
};

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-start;
  height: 120px;
`;

const LabelWrapper = styled.div<{ $isDimension?: boolean }>`
  height: 110px;
  width: 80px;
  margin: 0px 1px;
  border-right: 1px solid var(--colorPaletteBlueBackground1);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  font-style: ${(props) => (props.$isDimension ? "normal" : "italic")};

  &:hover {
    p,
    svg {
      color: ${(props) =>
    props.$isDimension ? "var(--colorBrandForeground1)" : "var(--colorNeutralForeground2)"};
    }
  }
`;

const Label = styled.div<StyledLabelProps>`
  font-family: Roboto;
  font-size: 13px;
  font-weight: ${(props) => (props.$isDimension ? 500 : 400)};
  line-height: 15px;
  color: ${(props) =>
    props.$isDimension && !props.$isParticipation
      ? "var(--colorNeutralForeground1)"
      : "var(--colorNeutralForeground2)"};

  transform: rotate(270deg);
  width: 80px;
  overflow: hidden;
  align-content: center;
  height: 30px;
  line-height: 15px;

  ${(props) =>
    props.$isMoreOneWord &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-word;
    `}

  ${(props) =>
    !props.$isMoreOneWord &&
    !props.$isParticipation &&
    css`
      white-space: nowrap;
      text-overflow: ellipsis;
    `}


  svg {
    font-size: 14px;
    color: var(--colorNeutralForeground2);
    margin-left: 30px;
  }
`;

const MyTooltip = styled(Tooltip) <{ $isDimension: boolean }>``;

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MyTooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    fontWeight: 500,
    fontSize: "12px",
    textAlign: "center",
    background: "var(--colorShadowInverted2)",
  },
});