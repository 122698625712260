import { WEEKDAY } from "constants/weekDay";
import { SEND_OUT_SURVEY_TYPES } from "constants/send-out-survey";
import { checkUniqueProjectName } from "api/useCheckUniqueProjectName";
import { makeAutoObservable, reaction } from "mobx";
import { getAttributesByEntityWithSegments } from "api/go/useGetAttributesByEntityWithSegments";
import { MilestoneDaysSurveyProject, TEntityAttribute } from "types/entityAttribute";
import {
  AddFrequencyProjectCustom,
  AddMilestonesProjectCustom,
  AddOneOffProjectCustom,
} from "api/useAddProject";

import dayjs, { Dayjs } from "dayjs";

import MainStore from "MainStore";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";

class Store {
  name: string = "";
  minResponses: number = 3;
  isTestProject: boolean = false;
  automaticTriggers: boolean = false;
  sendOutSurveysType: string = "";

  frequencyDay: "Sun" | "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat" | "" = "Mon";
  isAbleToSchedule: boolean = false;
  endDate: Dayjs | null = null;

  millstoneDayAttribure: string = "";
  millstoneDays: MilestoneDaysSurveyProject[] = [{ days: 30 }, { days: 60 }, { days: 90 }];
  frequency_repeat_every_count: number = 1;
  frequency_repeat_every_period: string = "day";

  entity_attributes: TEntityAttribute[] = [];
  selected_attribute: TEntityAttribute = null;
  selected_date_attribute: TEntityAttribute = null;
  selected_attribute_segments: { id: number; value: string }[] = [];

  isErrorFrequensyDate: boolean = false;

  errorname: string = "";

  newSurveyId: number = 0;

  constructor() {
    makeAutoObservable(this);

    reaction(
      () => this.name,
      (newName) => {
        this.delayedCheckNameUniqueness(newName);
      },
      { delay: 500 },
    );
  }

  clearStore = () => {
    this.name = "";
    this.errorname = "";
    this.minResponses = 3;
    this.isTestProject = false;
    this.automaticTriggers = false;
    this.sendOutSurveysType = "";

    this.frequencyDay = "Mon";
    this.isAbleToSchedule = false;
    this.endDate = dayjs(new Date());

    this.millstoneDayAttribure = "";
    this.millstoneDays = [{ days: 30 }, { days: 60 }, { days: 90 }];
    this.frequency_repeat_every_count = 1;
    this.frequency_repeat_every_period = "day";

    this.entity_attributes = [];
    this.selected_date_attribute = null;
    this.selected_attribute = null;
    this.selected_attribute_segments = [];

    this.newSurveyId = 0;
  };

  setfrequencyDay = (value: "Sun" | "Mon" | "Tue" | "Wed" | "Thu" | "Fri" | "Sat" | "") => {
    this.frequencyDay = value;
  };

  setData = (value: any, field: string) => {
    this[field] = value;
    if (field === "name" && !value) {
      this.setErrorName("Name is a required field");
      return;
    } else if (!this.name.length) {
      this.setErrorName("Project name has to be filled");
    }
    if (field === "frequency_repeat_every_period") {
      this.frequencyDay = "Mon";
      this.frequency_repeat_every_count = 1;
    }
    if (field === "selected_attribute") {
      this.setData([], "selected_attribute_segments");
    }
    if (field === "automaticTriggers") {
      this.setData([], "selected_attribute_segments");
      this.setData(null, "selected_attribute");
    }
    if (field === "isKeepItUnscheduled" && value) {
      this.setData(null, "endDate");
    }
  };

  addMilstoneDay = () => {
    if (this.millstoneDays.length === 0) this.millstoneDays = [{ id: 0, is_new: true, days: 1 }];
    else if (this.millstoneDays.length === 12) return;
    else this.millstoneDays = [...this.millstoneDays, { id: 0, is_new: true, days: 1 }];
  };

  removeMillstoneDay = (removeIndex: number) => {
    this.millstoneDays = this.millstoneDays.filter((_, index) => index !== removeIndex);
  };

  updateMillstoneDay = (changeIndex: number, value: number) => {
    this.millstoneDays = this.millstoneDays.map((day, index) => {
      if (index === changeIndex) {
        day.days = value;
        return day;
      } else return day;
    });
  };

  loadAttributes = async () => {
    try {
      MainStore.changeLoader(true);
      const response = await getAttributesByEntityWithSegments(StoreLayout.currentEntityId);
      if (response.status === 201 || response.status === 200) {
        this.entity_attributes = response.data;
      } else {
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Failed to load attributes", "error");
    } finally {
      MainStore.changeLoader(false);
    }
  };
  private async delayedCheckNameUniqueness(newName: string) {
    if (!newName && !this.errorname) return;
    if (!newName) {
      this.setErrorName("Name is a required field");
      return;
    } else {
      try {
        const response = await checkUniqueProjectName(StoreLayout.currentEntityId, newName, 0);
        if (response.status === 201 || response.status === 200) {
          if (response.data) {
            this.setErrorName("");
          } else {
            this.setErrorName(
              "Project names have to be unique, another project with the same name already exists",
            );
          }
        } else {
          throw new Error();
        }
      } catch (err) {
        MainStore.setSnackbar("Failed to check name for uniqueness", "error");
      }
    }
  }

  setErrorName = (error: string) => {
    this.errorname = error;
  };

  saveProject = async (callback: () => void) => {
    if (this.name === null || this.name === "") {
      this.setErrorName("Name is a required field");
      return; //Add form validations
    }

    if (this.sendOutSurveysType === SEND_OUT_SURVEY_TYPES.OneOff) {
      try {
        MainStore.changeLoader(true);
        const response = await AddOneOffProjectCustom(
          StoreLayout.currentEntityId,
          this.sendOutSurveysType,
          this.name,
          this.minResponses,
          this.isTestProject,
          this.automaticTriggers,
          this.selected_attribute?.id,
          this.selected_attribute_segments.map((el) => el.value),
        );
        if (response.status === 201 || response.status === 200) {
          this.newSurveyId = response.data;
          MainStore.setSnackbar(this.name + " successfully created", "success");
          callback();
        } else {
          throw new Error();
        }
      } catch (err) {
        MainStore.setSnackbar("Something went wrong", "error");
      } finally {
        MainStore.changeLoader(false);
      }
    }

    if (this.sendOutSurveysType === SEND_OUT_SURVEY_TYPES.Milestones) {
      try {
        MainStore.changeLoader(true);
        const response = await AddMilestonesProjectCustom(
          StoreLayout.currentEntityId,
          this.sendOutSurveysType,
          this.name,
          this.minResponses,
          this.isTestProject,
          this.millstoneDays,
          this.selected_date_attribute?.id,
          this.automaticTriggers,
          this.selected_attribute?.id,
          this.selected_attribute_segments.map((el) => el.value),
        );
        if (response.status === 201 || response.status === 200) {
          this.newSurveyId = response.data;
          MainStore.setSnackbar(this.name + " successfully created", "success");
          callback();
        } else {
          throw new Error();
        }
      } catch (err) {
        MainStore.setSnackbar("Something went wrong", "error");
      } finally {
        MainStore.changeLoader(false);
      }
    }

    if (this.sendOutSurveysType === SEND_OUT_SURVEY_TYPES.Frequency) {
      let dateEndWithTimezone = this.endDate;

      try {
        MainStore.changeLoader(true);
        const response = await AddFrequencyProjectCustom(
          StoreLayout.currentEntityId,
          this.sendOutSurveysType,
          this.name,
          this.minResponses,
          this.isTestProject,
          this.frequency_repeat_every_period,
          this.frequency_repeat_every_count,
          WEEKDAY.indexOf(this.frequencyDay),
          this.isAbleToSchedule ? dateEndWithTimezone : null,
          this.automaticTriggers,
          this.selected_attribute?.id,
          this.selected_attribute_segments.map((el) => el.value),
        );
        if (response.status === 201 || response.status === 200) {
          this.newSurveyId = response.data;
          MainStore.setSnackbar(this.name + " successfully created", "success");
          callback();
        } else {
          throw new Error();
        }
      } catch (err) {
        MainStore.setSnackbar("Something went wrong", "error");
      } finally {
        MainStore.changeLoader(false);
      }
    }
  };
}

const store = new Store();

export default store;
