import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AlertBanner, { Notification } from './banner';
import { useRealtimeNotification } from 'api/useRealtimeNotification';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { dismissNotification } from 'api/useDismissNotification';

interface SSEEvent {
  type: string;
  metadata: Notification;
}

const BannerWrapper: React.FC = () => {
  const [notification, setNotification] = useState<Notification | null>(null);
  const createEventSource = useRealtimeNotification();
  const location = useLocation();

  useEffect(() => {
    let eventSource = createEventSource();
    
    eventSource.onmessage = (event) => {
      try {
        const data: SSEEvent = JSON.parse(event.data);

        if (data.type === 'NEW_NOTIFICATION') {
          setNotification(data.metadata);
        }

        if (data.type === 'DISMISS_NOTIFICATION') {
          setNotification(data.metadata);
        }
      } catch (error) {
        console.error('Error parsing SSE event:', error);
      }
    };

    eventSource.onerror = (error) => {
      console.error('SSE connection error:', error);
      eventSource?.close();

      const timeoutId = setTimeout(() => {
        eventSource = createEventSource();
      }, 5000);

      return () => clearTimeout(timeoutId);
    };

    return () => {
      eventSource?.close();
    };
  }, [createEventSource]);

  const handleDismiss = async (id: string) => {
    try {
      dismissNotification(id);
      setNotification(null);
    } catch (error) {
      console.error('Error dismissing notification:', error);
    }
  };

  const shouldShowBanner = !location.pathname.includes('import-historical-survey');

  return (
    <div className="flex flex-col min-h-screen">
      {notification && !notification.dismissed_at && shouldShowBanner && (
        <AlertBanner 
          data={notification}
          onDismiss={handleDismiss}
        />
      )}
    </div>
  );
};

export default BannerWrapper;
