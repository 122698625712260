import dayjs, { Dayjs } from "dayjs";

export function getTimeZone() {
  let offset = new Date().getTimezoneOffset(),
    o = Math.abs(offset);
  return (
    (offset < 0 ? "+" : "-") +
    ("00" + Math.floor(o / 60)).slice(-2) +
    ":" +
    ("00" + (o % 60)).slice(-2)
  );
}

export function getLocalTime(time: string): string {
  if (time === null || time === "" || time === undefined) return null;
  const currentDate = new Date();
  const [hours, minutes] = time.split(":").map(Number);
  currentDate.setUTCHours(hours, minutes, 0, 0);
  let day_js = dayjs(currentDate);
  return day_js.format("HH:mm");
}

export function toUtcTime(time: string): string {
  if (time === null || time === "" || time === undefined || time === "Invalid Date") return null;
  if (time.split(":").length !== 2) return null;
  const [hours, minutes] = time.split(":").map(Number);
  const currentDate = new Date();
  currentDate.setHours(hours, minutes, 0, 0);
  const utcTimeString = currentDate.toISOString().substr(11, 5);
  return utcTimeString;
}

export function formatToLocalDate(dateString): string {
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.toLocaleString("en-US", { month: "short" });
  const year = date.getFullYear();
  const weekday = date.toLocaleString("en-US", { weekday: "short" });

  return `${day} ${month} ${year}, ${weekday}`;
}

export function formatToLocalDateTime(dateString): string {
  const date = new Date(dateString);
  const time = date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  return `${formatToLocalDate(dateString)} at ${time}`;
}

export function formatDateToLocalTime(dateString): string {
  const date = new Date(dateString);

  const time = date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  });

  return time;
}

export function convertToAmPm(time24: string): string {
  const time = dayjs(time24, "HH:mm");

  return time.format("hh:mm A");
};

export function extractMinimumTime(dateTarget: Dayjs | null): Dayjs | null {
  if (dateTarget && dateTarget.isSame(dayjs(), "day")) {
    const roundedTime = dayjs()
      .minute(Math.floor(dayjs().minute() / 5) * 5)
      .second(0)
      .add(15, "minute");

    return roundedTime;
  } 

  return null;
}

export function extractAndMergeDateTime(datePart: Dayjs, timePart: Dayjs): Dayjs {
  if (!datePart || !timePart) return;

  return datePart
    .startOf("day")
    .hour(timePart.hour())
    .minute(timePart.minute())
    .second(0);
}
