import { USE_DASHBOARD_API } from "constants/config";
import http from "../https";

import { TGetSurveyResponseCountAsyncResponse } from "types/dashboard";
import { TDashboardParameters } from "./useGetDashboardData";

type TRequestBody = {
    dashboardParameters: TDashboardParameters;
    statsLevel: string;
    benchmarkType: number;
    attributes: {
        id: number;
        name: string;
        segments: string[];
    }[];
};

export const getSurveyResponseCountAsync = async (
    data: TRequestBody
): Promise<TGetSurveyResponseCountAsyncResponse> => {
    try {
        let response;
        if (USE_DASHBOARD_API) {
            response = await http.dashboardAPIClient.post(`/dashboard/dashboard/responses-stats`, data);
          } else {
            const { dashboardParameters, statsLevel, ...rest } = data;
            if (statsLevel === 'survey') {
                dashboardParameters.project_id = undefined;
            } else {
                dashboardParameters.survey_id = undefined;
            }
            response = await http.post(`/Dashboard/global-count-responce`, {...rest, ...dashboardParameters});
          }

        if ([200, 201].includes(response.status)) {
            return response.data;
        }

        throw new Error(`Failed to fetch count responce (HTTP status code: ${response.status})`);
    } catch (error) {
        console.error(error);
    }
};

