import http from "api/https";
import { TRecipientsFilterSearch } from "types/recipients";

export const addEmployeeRecipients = (
  survey_id: number,
  employees: number[],
  is_all: boolean,
  attributes: TRecipientsFilterSearch[],
  search_value: string,
) => {
  let data = {
    survey_id: survey_id,
    employees: employees,
    is_all: is_all,
    attributes: attributes,
    search_value: search_value,
  }
  if (http.isAPIGO) {
    return http.postGO("/sm/sm_survey_recepients/AddEmployeeRecipients", data, );
  } else {
    return http.post("/sm_survey_recepients/AddEmployeeRecipients", data, );
  }
};
