import { TProject } from "types/dashboard";
import { observer } from "mobx-react";
import { useSearchParams } from "react-router-dom";
import { FC, useEffect, useState } from "react";
import { EMPTY_STRING_FILTERS, PROJECT_STATUSES, PROJECT_TYPES } from "constants/dashboard-menu";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  InputAdornment,
  ListSubheader,
  Select,
  TextField,
} from "@mui/material";

import store from "./store";
import styled from "styled-components";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import ProjectSelectorItem from "./ProjectSelectorItem";

type ProjectSelectorProps = {};

const ProjectSelector: FC<ProjectSelectorProps> = observer(() => {
  const [searchValue, setSearchValue] = useState<string>("");
  const [localProjectsList, setLocalPorjectsList] = useState<TProject[]>([]);
  const [isOpenSelector, setIsOpenSelector] = useState<boolean>(false);

  const [_, setQueryParams] = useSearchParams();

  useEffect(() => {
    if (store.surveyProjectsList) setLocalPorjectsList(store.surveyProjectsList);
  }, [store.surveyProjectsList]);

  useEffect(() => {
    if (searchValue.length > 0) {
      const newArrayProjects = store.surveyProjectsList.filter((project) =>
        project.name.toLowerCase().includes(searchValue.toLowerCase())
      );
      setLocalPorjectsList(newArrayProjects);
    } else setLocalPorjectsList(store.surveyProjectsList);
  }, [searchValue.length]);

  const handleChange = (el: TProject) => {
    store.setData(el, "selectedProject");
    const stringFilters = JSON.stringify(EMPTY_STRING_FILTERS);
    localStorage.setItem("dashboard_filters", stringFilters);
    store.setData(EMPTY_STRING_FILTERS, "filters");
    store.setData(false, "isSurveyHaveResponseCount");
    setIsOpenSelector(false);
    setQueryParams({ project_id: String(el.id) });
    store.getSurveyProjects(String(el.id), setQueryParams);
  };

  return (
    <StyledSelectProject
      id="select_survey_project"
      value={(store.selectedProject && store.selectedProject.id) || null}
      displayEmpty
      MenuProps={{ PaperProps: { sx: { maxHeight: 280 } } }}
      IconComponent={KeyboardArrowDownIcon}
      open={isOpenSelector}
      onOpen={() => setIsOpenSelector(true)}
      onClose={() => setIsOpenSelector(false)}
      renderValue={() => {
        if (store.surveyProjectsList.length === 0) {
          return <p>Select project</p>;
        } else return store.selectedProject.name;
      }}
    >
      <SearchSubheader>
        <SearchWrapper>
          <StyledSearchInput
            onClick={(e) => e.preventDefault()}
            size="small"
            placeholder="Search for survey project"
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        </SearchWrapper>
      </SearchSubheader>

      {localProjectsList.some((el) => el.status === PROJECT_STATUSES.ACTIVE) && (
        <GroupSubHeader>
          <StyledAccordion>
            <AccordionSummary
              expandIcon={<KeyboardArrowDownIcon />}
              aria-controls="panel1-select-projects-active-content"
              id="panel1-select-projects-active"
            >
              <AccordionTitle>
                Active <FiberManualRecordIcon />
              </AccordionTitle>
            </AccordionSummary>
            <AccordionDetails>
              <MenuList>
                {localProjectsList
                  .filter((item) => item.status === PROJECT_STATUSES.ACTIVE)
                  .map((el) => (
                    <ProjectSelectorItem
                      project={el}
                      handleChange={handleChange}
                      idDisableProjectItem={() => false}
                      key={el.id}
                    />
                  ))}
              </MenuList>
            </AccordionDetails>
          </StyledAccordion>
        </GroupSubHeader>
      )}
      {localProjectsList.some((el) => el.status === PROJECT_STATUSES.TEST) && (
        <GroupSubHeader>
          <StyledAccordion>
            <AccordionSummary
              expandIcon={<KeyboardArrowDownIcon />}
              aria-controls="panel1-select-projects-test-content"
              id="panel1-select-projects-test"
            >
              <AccordionTitle>test projects</AccordionTitle>
            </AccordionSummary>
            <AccordionDetails>
              <MenuList>
                {localProjectsList
                  .filter((item) => item.status === PROJECT_STATUSES.TEST)
                  .map((el) => (
                    <ProjectSelectorItem
                      project={el}
                      handleChange={handleChange}
                      idDisableProjectItem={() => false}
                      key={el.id}
                    />
                  ))}
              </MenuList>
            </AccordionDetails>
          </StyledAccordion>
        </GroupSubHeader>
      )}
      {localProjectsList.some((el) => el.status === PROJECT_STATUSES.COMPLETED) && (
        <GroupSubHeader>
          <StyledAccordion>
            <AccordionSummary
              expandIcon={<KeyboardArrowDownIcon />}
              aria-controls="panel1-select-projects-completed-content"
              id="panel1-select-projects-completed"
            >
              <AccordionTitle>all surveys Completed</AccordionTitle>
            </AccordionSummary>
            <AccordionDetails>
              <MenuList>
                {localProjectsList
                  .filter((item) => item.status === PROJECT_STATUSES.COMPLETED)
                  .map((el) => (
                    <ProjectSelectorItem
                      project={el}
                      handleChange={handleChange}
                      idDisableProjectItem={() => false}
                      key={el.id}
                    />
                  ))}
              </MenuList>
            </AccordionDetails>
          </StyledAccordion>
        </GroupSubHeader>
      )}
    </StyledSelectProject>
  );
});

export default ProjectSelector;

const StyledSelectProject = styled(Select)`
  height: 36px !important;
  width: fit-content !important;
  max-width: 280px !important;
  background-color: transparent !important;
  border-radius: 0px !important;
  border-bottom: 1px solid var(--colorPaletteBlueBackground1) !important;

  .MuiSelect-select {
    padding: 0px 32px 0px 0px !important;
  }

  font-family: Roboto !important;
  font-size: 24px !important;
  font-weight: 700 !important;
  line-height: 32px !important;
  color: var(--colorNeutralForeground1) !important;

  fieldset {
    border: none !important;
  }
`;

const AccordionTitle = styled.span`
  font-family: Roboto;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--colorNeutralForeground5);
  text-transform: uppercase;
  display: flex;
  align-items: center;

  svg {
    color: var(--colorPaletteGreenBackground2);
    font-size: 12px;
    margin-left: 4px;
  }
`;

const StyledSearchInput = styled(TextField)`
  background-color: transparent;
  width: 100%;
  border-radius: 2px !important;
  border-color: var(--colorPaletteBlueBackground1) !important;

  input {
    padding: 8px 12px !important;
    font-family: Roboto;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0px;
  }

  &::placeholder {
    color: var(--colorNeutralForeground5) !important;
  }

  svg {
    font-size: 18px;
    color: var(--colorBrandForeground1) !important;
  }
`;

const SearchWrapper = styled.div`
  width: 100%;
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 16px 0px 16px;
`;

const StyledAccordion = styled(Accordion)`
  box-shadow: none !important;
  margin: 0px !important;

  &::before {
    display: none !important;
  }
  .MuiTabs-root {
    min-height: 0 !important;
    height: 40px !important;
  }
  .Mui-disabled {
    opacity: 1 !important;
  }

  .MuiAccordionSummary-content {
    margin: 0px !important;
  }

  .MuiButtonBase-root {
    height: 38px !important;
    min-height: 0px !important;
  }

  .MuiTabScrollButton-root {
    border: none !important;
  }

  .MuiAccordionDetails-root {
    padding: 0px !important;
  }
`;

const SearchSubheader = styled(ListSubheader)`
  padding: 0px !important;
`;

const GroupSubHeader = styled(ListSubheader)`
  padding: 0px !important;
`;

const MenuList = styled.ul`
  padding: 0px;
`;
