import { FC } from "react";
import { observer } from "mobx-react";
import styled from "styled-components";
import {
  Outlet,
  useLocation,
  useBlocker,
  useNavigate,
} from "react-router-dom";

import { HeaderMenu } from "./header/HeaderMenu";
import { DiscardPopup } from "./discardPopup/DiscardPopup";
import { BackToMainLayoutHeader } from "./header/BackToMainLayoutHeader";
import storeSurveyCreation from "features/Survey/SurveyCreation/store";
import storeSurveyAccess from "features/Survey/Communication/store";
import BannerWrapper from "components/bannerWrapper";

type SurveyLayoutProps = {};

const SurveyLayout: FC<SurveyLayoutProps> = observer(() => {
  const query = useQuery();
  const navigate = useNavigate();
  const idSurvey = query.get("id") !== null ? query.get("id") : "0";

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    if (storeSurveyCreation.surveyChanged || storeSurveyAccess.checkChanged()) {
      return currentLocation.pathname !== nextLocation.pathname;
    }
  });

  return (
    <>
      <AppMainWapper>
        <BannerWrapper />
        <BackToMainLayoutHeader idSurvey={idSurvey} />
        <HeaderMenu />
        <AppContent>
          <Outlet />
        </AppContent>
      </AppMainWapper>
      <DiscardPopup
        open={blocker.state === "blocked"}
        closeHandler={() => blocker.reset()}
        cancelHandler={() => blocker.reset()}
        confirmHandler={() => blocker.proceed()}
        title="Leave page and discard changes?"
        description={
          storeSurveyAccess.checkChanged()
            ? "All changes made will not be saved."
            : "All changes made will not be saved if you choose not to apply changes."
        }
        cancel="Cancel"
        confirm="Leave and discard"
      />
      <DiscardPopup
        open={storeSurveyCreation.openUnsavedTranslation}
        closeHandler={() => storeSurveyCreation.openUnsavedTranslationModal(false)}
        cancelHandler={() => {
          navigate(storeSurveyCreation.proceedTranslation);
          storeSurveyCreation.openUnsavedTranslationModal(false);
        }}
        confirmHandler={async () => {
          await storeSurveyCreation.saveSurvey(() => { });
          navigate(storeSurveyCreation.proceedTranslation);
          storeSurveyCreation.openUnsavedTranslationModal(false);
        }}
      />
    </>
  );
});

export default SurveyLayout;

const AppMainWapper = styled.div``;

const AppContent = styled.div`
  width: 100%;
  height: calc(100vh - 126px);
  margin-top: 55px;
  overflow-y: auto;
  overflow-x: hidden;
  position: relative;
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
