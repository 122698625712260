import { observer } from "mobx-react";
import styled from "styled-components";
import Highcharts, { Options } from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { PROJECT_TYPES } from "constants/dashboard-menu";
import { FC, useEffect, useState } from "react";
import { DIMENSION, ENPS, OVERAL_SCORE } from "constants/dashboard-overview";
import { MenuItem, Select, SelectChangeEvent } from "@mui/material";

import { CardLoader } from "features/Dashboard/CardLoader";

import store from "../../store";
import EmptyState from "./EmptyState";
import { EmptyLabel } from "./EmptyLabel";

type TrendCardProps = {
  isStretched?: boolean;
  width?: string;
};

const TrendCard: FC<TrendCardProps> = observer(({ isStretched = false, width = "43%" }) => {
  const [graphValues, setGraphValues] = useState([]);
  const [graphCategories, setGraphCategories] = useState([]);

  useEffect(() => {
    if (store.projectInfo && store.projectInfo?.trends?.trends) {
      const categories = store.projectInfo.trends.trends.map((el) => el.name);
      const values = store.projectInfo.trends.trends.map((el) => ({
        y: el.score,
        name: el.respondents,
      }));
      setGraphCategories(categories);
      setGraphValues(values);
    } else {
      setGraphCategories([])
      setGraphValues([]);
    }
  }, [store.projectInfo]);

  const chartOptions: Options = {
    credits: {
      enabled: false,
    },
    chart: {
      type: "column",
      height: "230px",
      spacingLeft: 0,
      spacingRight: 0,
      spacing: [10, 0, 10, 0],
      events: {
        load: function () {
          this.reflow();
        },
      },
    },

    tooltip: {
      useHTML: false,
      shared: false,
      backgroundColor: "var(--colorShadowInverted2)",
      style: {
        fontSize: '12px',
        color: "var(--colorNeutralBackground1)",
      }, 
      formatter: function () {
          return `Score: ${this.point.y}<br>Respondent: ${this.point.name}`;
      },
      positioner: function () {
        return { x: this.chart.hoverPoint.plotX, y: this.chart.hoverPoint.plotY };
      },
    },
    title: {
      text: "",
    },
    series: [
      {
        maxPointWidth: 24,
        borderRadius: 0,
        type: "column",
        data: graphValues,
      },
    ],

    plotOptions: {
      column: {
        allowPointSelect: false,
        cursor: "pointer",
        dataLabels: {
          enabled: false,
        },
        showInLegend: false,
        colorByPoint: true,
        colors: store.overviewTrendCardFilter !== ENPS ? ["#DB9EFF"] : ["#1493A4", "#FF9E9E"],
      },
    },
    xAxis: {
      categories: graphCategories,
      gridLineWidth: 0,
      lineWidth: 1,
      lineColor: `var(--colorNeutralForeground6)`,
    },
    yAxis: {
      visible: true,
      gridLineWidth: 0,
      lineWidth: 1,
      lineColor: `var(--colorNeutralForeground6)`,
      title: {
        text: store.overviewTrendCardFilter !== ENPS ? "Favourable score" : "eNPS",
      },
      min: (() => {
        const minValue = Math.min(...graphValues.map(v => v.y));
        const roundedDown = Math.floor(minValue / 10) * 10;
        return minValue % 10 === 0 ? roundedDown - 10 : roundedDown;
      })(),
      max: (() => {
        const maxValue = Math.max(...graphValues.map(v => v.y));
        const roundedUp = Math.ceil(maxValue / 10) * 10;
        return maxValue % 10 === 0 ? roundedUp + 10 : roundedUp;
      })(),
      tickInterval: 10,
      labels: {
        align: "right",
        distance: 5,
        formatter: (e) => {
          if (e.value !== 0 && store.overviewTrendCardFilter !== ENPS) {
            return `<p style="
              font-family: Roboto;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              color:var(--colorNeutralForeground2);">
              ${e.value}%
              </p>`;
          } else
            return `<p style="
                      font-family: Roboto;
                      font-size: 14px;
                      font-weight: 400;
                      line-height: 20px;
                      color:var(--colorNeutralForeground2);">
                      ${e.value}
                      </p>`;
        },
      },
    },
  };

  const isPending =
    store.isDimensionQuestionsPending || store.isTrendsPending || !store.projectInfo;

  const renderCardContent = () => {
    if (isPending) return <CardLoader contentHeight={240} />;
    if (!store.isDisplayTrends) return <EmptyState />;
    if (!graphValues?.length) return <EmptyLabel />;

    return (
      <HighchartsReact highcharts={Highcharts} options={chartOptions} style={{ width: "100%" }} />
    );
  };

  const redirectOnTrends = () =>
    store.selectedProject.Type !== PROJECT_TYPES.oneOff &&
    store.isDisplayTrends &&
    store.setData("trend", "activeTab");

  const handleSelectProject = (e: SelectChangeEvent<string>) => {
    if (e.target.value === String(DIMENSION)) {
      store.setData(
        store.overviewTrendCardDimenssionTypeList[1].label,
        "selectedOverviewTrendCardDimenssionType"
      );
    }
    store.setData(e.target.value, "overviewTrendCardFilter");
  };

  return (
    <Card $width={width} $isStretched={isStretched}>
      <TitleRow $isColumn={Boolean(store.overviewTrendCardFilter === DIMENSION)}>
        <Row>
          <TitleGroup>
            <Title>Trend</Title>
            {!isPending && (
              <StyledSelect
                id="segments_filter_select"
                value={store.overviewTrendCardFilter}
                onChange={handleSelectProject}
                displayEmpty
              >
                <MenuItem value={OVERAL_SCORE}>Overall score</MenuItem>
                <MenuItem value={ENPS}>eNPS</MenuItem>
                <MenuItem value={DIMENSION}>Dimension</MenuItem>
              </StyledSelect>
            )}
            {store.overviewTrendCardFilter === DIMENSION && !isPending && (
              <StyledSelect
                id="dimension_segments_type"
                value={store.selectedOverviewTrendCardDimenssionType}
                onChange={(e: SelectChangeEvent<string>) =>
                  store.setData(e.target.value, "selectedOverviewTrendCardDimenssionType")
                }
                displayEmpty
                MenuProps={{ PaperProps: { sx: { maxHeight: 300 } } }}
              >
                {store.overviewTrendCardDimenssionTypeList.map((el) => (
                  <MenuItem value={el.label}>{el.label}</MenuItem>
                ))}
              </StyledSelect>
            )}
          </TitleGroup>
        </Row>
        {/*store.isDisplayTrends && !isPending && (
          <LinkRow $isColumn={Boolean(store.overviewTrendCardFilter === DIMENSION)}>
            <ViewLink
              onClick={redirectOnTrends}
              $isDisabled={
                store.selectedProject.Type === PROJECT_TYPES.oneOff || !store.isDisplayTrends
              }
            >
              Create more trends
            </ViewLink>
          </LinkRow>
        )*/}
      </TitleRow>
      <ChartWrapper>{renderCardContent()}</ChartWrapper>
    </Card>
  );
});

export default TrendCard;

const Card = styled.div<{ $width: string; $isStretched: boolean }>`
  width: ${props => props.$isStretched ? '100%' : props.$width};
  padding: 24px;
  border-radius: 8px;
  background-color: var(--colorNeutralBackground1);
`;

const TitleRow = styled.div<{ $isColumn?: boolean }>`
  width: 100%;
  display: flex;
  align-items: center;
  ${(props) => props.$isColumn && "flex-direction:column"};
  justify-content: space-between;
`;

const Title = styled.p`
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0em;
  color: var(--colorNeutralForeground1);
  margin-right: 4px;
`;

const ViewLink = styled.a<{ $isDisabled: boolean }>`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  margin-left: 8px;
  color: ${(props) =>
    props.$isDisabled ? "var(--colorNeutralForeground5)" : "var(--colorBrandForeground1)"};
  transition: all 0.3s;
  align-self: self-end;
  white-space: nowrap;
  cursor: ${(props) => (props.$isDisabled ? "not-allowed" : "pointer")};

  &:hover {
    color: ${(props) =>
      props.$isDisabled ? "var(--colorNeutralForeground5)" : "var(--colorBrandForeground2)"};
  }
`;

const StyledSelect = styled(Select)`
  height: 34px;
  width: 140px !important;
  background-color: var(--colorNeutralBackground1) !important;
  border-radius: 2px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  margin-left: 8px !important;

  em {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: var(--colorNeutralForeground5) !important;
    font-style: normal !important;
  }
`;

const TitleGroup = styled.div`
  display: flex;
  align-items: center;
`;

const ChartWrapper = styled.div`
  width: 100%;
  margin-top: 40px;
  display: flex;
  min-height: 200px;
  justify-content: center;
  align-items: center;

  .highcharts-container {
    width: 100% !important;
  }
  div {
    width: 100% !important;
  }
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const LinkRow = styled.div<{ $isColumn?: boolean }>`
  ${(props) => props.$isColumn && "width: 100%"};
  ${(props) => props.$isColumn && "justify-content: flex-end"};
  ${(props) => props.$isColumn && "margin-top: 10px"};

  display: flex;
`;
