import { FC } from "react";
import styled from "styled-components";
import store from "./store";

type ProgressLoaderProps = {};

const ProgressLoader: FC<ProgressLoaderProps> = () => {
  const importDescription = "Thank you for confirming the import. <br /><br />We're making sure the data is properly stored in the database, so this step may take a while. Feel free to navigate the platform while the process continues seamlessly in the background. We'll notify you as soon as it's done";
  
  const uploadDescription = "Feel free to navigate the platform; the process will continue seamlessly even if you move around.";

  return (
    <LoaderWrapper id={`ImportHistoricalSurvey_ProgressLoaderBlock`}>
      <LoaderContent>
        <img src={"/assets/er_logo_loading.gif"} width={64} height={64} alt="EngageRocket Logo" />
        <LargeText id={`ImportHistoricalSurvey_LoaderText`}>
          {store.surveyName ? "Importing in progress..." : "Uploading in progress..."}
        </LargeText>
        <SmallText 
          id={`ImportHistoricalSurvey_LoaderDescription`}
          dangerouslySetInnerHTML={{ 
            __html: store.surveyName ? importDescription : uploadDescription 
          }}
        />
      </LoaderContent>
    </LoaderWrapper>
  );
};

export default ProgressLoader;

const LoaderWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 150px;
`;

const LoaderContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 522px;
`;

const LargeText = styled.span`
  font-family: Roboto, sans-serif;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: var(--colorNeutralForeground1);
  margin-top: 10px;
  text-align: center;
`;

const SmallText = styled.p`
  font-family: Roboto, sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--colorNeutralForeground1);
  margin-top: 20px;
  text-align: center;
`;
