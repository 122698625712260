import { USE_DASHBOARD_API } from "constants/config";
import http from "../https";
import { TDashboardParameters } from "./useGetDashboardData";

type TRequestBody = {
  dashboardParameters: TDashboardParameters;
  attributes: {
    id: number;
    name: string;
    segments: string[];
  }[];
  benchmarkType?: number;
  rowSettings: {
    overalScore: boolean;
    eNPS: boolean;
    questions: number[];
  };
};

export const useGetDashboardHotspots = (requestData: TRequestBody) => {
  if (USE_DASHBOARD_API) {
    return http.dashboardAPIClient.post(`/dashboard/dashboard/hotspots`, requestData);
  } else {
    const { dashboardParameters, ...rest } = requestData;
    return http.post(`/Dashboard/hotspot`, {...rest, ...dashboardParameters});
  }
}
