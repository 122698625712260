import { FC, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Grid, Box, FormControlLabel, Checkbox } from "@mui/material";
import styled from "styled-components";
import CustomButton from "components/button/Button";
import store from "./../store";
import storeRecipients from "../../Recipients/store";
import CustomDateField from "components/customDateField";
import CustomTimeField from "components/customTimeField";
import CalendarIcon from "components/calendarIcon/CalendarIcon";
import { SEND_OUT_SURVEY_TYPES } from "constants/send-out-survey";
import { SURVEY_STATUS } from "constants/survey-status";
import dayjs, { Dayjs } from "dayjs";
import {
  formatToLocalDateTime,
  formatDateToLocalTime,
  extractAndMergeDateTime,
  formatToLocalDate,
} from "utils/timeFormatter";
import CustomConfirmationDialog from "components/customConfirmationDialog";

const SurveyDates: FC = observer(() => {
  const [emplyeeAuto, setEmplyeeAuto] = useState(0);
  const [employeeManual, setEmployeeManual] = useState(0);
  const [surveyOnCloseNow, setSurveyOnCloseNow] = useState(false);

  const [startDatePart, setStartDatePart] = useState<Dayjs | null>(null);
  const [startTimePart, setStartTimePart] = useState<Dayjs | null>(null);
  const [endDatePart, setEndDatePart] = useState<Dayjs | null>(null);
  const [endTimePart, setEndTimePart] = useState<Dayjs | null>(null);

  const [startDateError, setStartDateError] = useState<boolean>(false);
  const [startTimeError, setStartTimeError] = useState<boolean>(false);
  const [endDateError, setEndDateError] = useState<boolean>(false);
  const [endTimeError, setEndTimeError] = useState<boolean>(false);
  const [isCustomEndTimeError, setIsCustomEndTimeError] = useState<boolean>(false);
  const [isCustomEndDateError, setIsCustomEndDateError] = useState<boolean>(false);

  const [errorStartDateMessage, setErrorStartDateMessage] = useState("");
  const [errorStartTimeMessage, setErrorStartTimeMessage] = useState("");
  const [errorEndDateMessage, setErrorEndDateMessage] = useState("");
  const [errorEndTimeMessage, setErrorEndTimeMessage] = useState("");

  const [isSurveyDialogOpen, setIsSurveyDialogOpen] = useState<boolean>(false);
  const [isStartDateSelected, setIsStartDateSelected] = useState(false);
  const [isStartTimeSelected, setIsStartTimeSelected] = useState(false);
  const [isEndDateSelected, setIsEndDateSelected] = useState(false);
  const [isEndTimeSelected, setIsEndTimeSelected] = useState(false);

  const daysCount = store.AllInfo ? store.AllInfo.name.split(" ")[1] : "";
  const dialogButtonsCaption = {
    yesButton: "Yes, end the survey immediately",
    cancelButton: "Cancel",
  };

  const initiateStartDateSelection = () => {
    if (store.AllInfo?.project_type !== SEND_OUT_SURVEY_TYPES.Milestones) {
      if (store.AllInfo?.survey_status === SURVEY_STATUS.complete) return;

      if (
        ![SURVEY_STATUS.running, SURVEY_STATUS.complete].includes(store.AllInfo?.survey_status) &&
        store.leaveDateEmpty
      ) {
        setStartDatePart(null);
        setStartTimePart(null);

        store.changeValue("surveyDateStart", null);
        store.changeValue("surveyDateEnd", null);

        store.changeValue("leaveDateEmpty", store.leaveDateEmpty);
        store.changeValue("leaveEndDateSurveyEmpty", store.leaveDateEmpty);

        return;
      }

      const startDate = store.surveyDateStart?.startOf("day") || dayjs().startOf("day");

      if (!store.surveyDateStart && store.AllInfo?.survey_status !== SURVEY_STATUS.running) {
        store.setupMinTime(startDate, "surveyDateStart");
      }

      const startTime = store.surveyDateStart
        ? dayjs(store.surveyDateStart.format("HH:mm"), "HH:mm")
        : store.selectedTime["surveyDateStart"];

      setStartDatePart(startDate);
      setStartTimePart(startTime);
    }
  };

  const initiateEndDateSelection = () => {
    if (
      store.AllInfo?.project_type !== SEND_OUT_SURVEY_TYPES.Milestones &&
      store.AllInfo?.survey_status === SURVEY_STATUS.complete
    )
      return;

    if (store.AllInfo?.survey_status !== SURVEY_STATUS.complete && store.leaveEndDateSurveyEmpty) {
      setEndDatePart(null);
      setEndTimePart(null);

      store.changeValue("surveyDateEnd", null);
      store.changeValue("leaveEndDateSurveyEmpty", store.leaveEndDateSurveyEmpty);

      return;
    }

    const endDate = store.surveyDateEnd?.startOf("day") || dayjs().startOf("day");

    if (!store.surveyDateEnd) {
      store.setupMinTime(endDate, "surveyDateEnd");
    }

    const endTime = store.surveyDateEnd
      ? dayjs(store.surveyDateEnd.format("HH:mm"), "HH:mm")
      : store.selectedTime["surveyDateEnd"];

    setEndDatePart(endDate);
    setEndTimePart(endTime);
  };

  useEffect(() => {
    if (store.isSetMainInformationDone) {
      initiateStartDateSelection();
      initiateEndDateSelection();
    }
  }, [store.isSetMainInformationDone]);

  useEffect(() => {
    if (
      store.selectedTime["surveyDateStart"] &&
      startTimePart &&
      store.selectedTime["surveyDateStart"] !== startTimePart
    ) {
      setStartTimePart(store.selectedTime["surveyDateStart"]);
    }
  }, [store.selectedTime["surveyDateStart"]]);

  useEffect(() => {
    if (
      store.selectedTime["surveyDateEnd"] &&
      endTimePart &&
      store.selectedTime["surveyDateEnd"] !== endTimePart
    ) {
      setEndTimePart(store.selectedTime["surveyDateEnd"]);
    }
  }, [store.selectedTime["surveyDateEnd"]]);

  useEffect(() => {
    if (store.selectedDate["surveyDateStart"] !== startDatePart) {
      setStartDatePart(store.selectedDate["surveyDateStart"]);
    }
  }, [store.selectedDate["surveyDateStart"]]);

  useEffect(() => {
    if (store.selectedDate["surveyDateEnd"] !== endDatePart) {
      setEndDatePart(store.selectedDate["surveyDateEnd"]);
    }
  }, [store.selectedDate["surveyDateEnd"]]);

  useEffect(() => {
    if (
      store.AllInfo &&
      store.AllInfo.project_type !== SEND_OUT_SURVEY_TYPES.Milestones &&
      startDatePart &&
      startTimePart &&
      endDatePart &&
      endTimePart
    ) {
      if (!isEndDateValid(endDatePart, startDatePart)) {
        setIsCustomEndDateError(true);
        return;
      } else {
        setIsCustomEndDateError(false);
      }

      checkSurveyStartAndSurveyEndRange();
    }
  }, [store.AllInfo?.project_type, startDatePart, startTimePart, endDatePart, endTimePart]);

  useEffect(() => {
    if (
      store.AllInfo?.project_type === SEND_OUT_SURVEY_TYPES.Milestones &&
      store.AllInfo?.survey_status === SURVEY_STATUS.running
    ) {
      storeRecipients.getMilestoneData(store.idSurvey);
    }
  }, [store.idSurvey, store.AllInfo]);

  useEffect(() => {
    if (storeRecipients.Employees.length > 0) {
      const { auto, customDate } = storeRecipients.Employees.reduce(
        (counts, employee) => {
          employee.recepients.forEach((recipient) => {
            if (recipient.survey_id === store.idSurvey) {
              recipient.status === "auto" && counts.auto++;
              recipient.status === "customDate" && counts.customDate++;
            }
          });
          return counts;
        },
        { auto: 0, customDate: 0 }
      );

      setEmplyeeAuto(auto);
      setEmployeeManual(customDate);
    }
  }, [storeRecipients.Employees]);

  useEffect(() => {
    setEndTimeError(false);
    setErrorEndTimeMessage("");

    if (isCustomEndTimeError) {
      setEndTimeError(true);
      setErrorEndTimeMessage(
        "Please ensure that the survey does not end less than 30 minutes before the survey starts."
      );
    }
  }, [isCustomEndTimeError]);

  useEffect(() => {
    setEndDateError(false);
    setErrorEndDateMessage("");

    if (isCustomEndDateError) {
      setEndDateError(true);
      setErrorEndDateMessage(
        "Please ensure that the survey does not end less than 30 minutes before the survey starts."
      );
    }
  }, [isCustomEndDateError]);

  useEffect(() => {
    if (
      store.isSetMainInformationDone &&
      startDatePart &&
      !startDatePart.isSame(store.surveyDateStart, "day") &&
      !store.leaveDateEmpty
    ) {
      setIsStartDateSelected(true);
    }
  }, [startDatePart, store.leaveDateEmpty, store.isSetMainInformationDone]);

  useEffect(() => {
    if (store.isSetMainInformationDone && startTimePart && !store.leaveDateEmpty) {
      const endTimeState = dayjs(startTimePart).format("HH:mm");
      const endTimeStore = dayjs(store.surveyDateStart).format("HH:mm");

      if (endTimeState !== endTimeStore) {
        setIsStartTimeSelected(true);
      }
    }
  }, [startTimePart, store.leaveDateEmpty, store.isSetMainInformationDone]);

  useEffect(() => {
    if (
      store.isSetMainInformationDone &&
      endDatePart &&
      !endDatePart.isSame(store.surveyDateEnd, "day") &&
      !store.leaveEndDateSurveyEmpty
    ) {
      setIsEndDateSelected(true);
    }
  }, [endDatePart, store.leaveEndDateSurveyEmpty, store.isSetMainInformationDone]);

  useEffect(() => {
    if (store.isSetMainInformationDone && endDatePart && !store.leaveDateEmpty) {
      const endTimeState = dayjs(endTimePart).format("HH:mm");
      const endTimeStore = dayjs(store.surveyDateEnd).format("HH:mm");

      if (endTimeState !== endTimeStore) {
        setIsEndTimeSelected(true);
      }
    }
  }, [endDatePart]);

  const handleStartDateError = (isError: boolean, message: string) => {
    setStartDateError(isError);
    setErrorStartDateMessage(message);
  };

  const handleEndDateError = (isError: boolean, message: string) => {
    setEndDateError(isError);
    setErrorEndDateMessage(message);
  };

  const handleStartTimeError = (isError: boolean, message: string) => {
    setStartTimeError(isError);
    setErrorStartTimeMessage(message);
  };

  const handleEndTimeError = (isError: boolean, message: string) => {
    if (!isCustomEndTimeError) {
      setEndTimeError(isError);
      setErrorEndTimeMessage(message);
    }
  };

  const handleLeaveStartDateEmpty = (event: React.ChangeEvent<HTMLInputElement>) => {
    store.changeValue("leaveDateEmpty", event.target.checked);

    if (event.target.checked) {
      setStartDatePart(null);
      setStartTimePart(null);

      setIsStartDateSelected(false);
      setIsStartTimeSelected(false);

      setStartDateError(false);
      setStartTimeError(false);

      store.changeValue("surveyDateStart", null);
    } else {
      initiateStartDateSelection();
    }

    handleLeaveEndDateEmpty(event);
  };

  const handleLeaveEndDateEmpty = (event: React.ChangeEvent<HTMLInputElement>) => {
    store.changeValue("leaveEndDateSurveyEmpty", event.target.checked);
    store.changeDatesTab(true);

    if (event.target.checked) {
      setEndDatePart(null);
      setEndTimePart(null);

      setIsEndDateSelected(false);
      setIsEndTimeSelected(false);

      setEndTimeError(false);
      setEndDateError(false);

      store.changeValue("surveyDateEnd", null);
    } else {
      initiateEndDateSelection();
    }
  };

  //for milestone survey
  const handleLeaveDateEmpty = (event: React.ChangeEvent<HTMLInputElement>) => {
    store.changeValue("leaveDateEmpty", event.target.checked);
    store.changeDatesTab(true);

    if (event.target.checked) {
      setEndDatePart(null);
      setEndTimePart(null);

      setIsEndDateSelected(false);
      setIsEndTimeSelected(false);
    } else {
      initiateEndDateSelection();
      checkSurveyStartAndSurveyEndRange();
    }
  };

  const handleCloseNow = (event: React.ChangeEvent<HTMLInputElement>) => {
    store.changeValue("closeNow", event.target.checked);
    store.changeDatesTab(true);
  };

  const handleSurveyCloseNow = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSurveyOnCloseNow(event.target.checked);
    store.changeDatesTab(true);
  };

  const handleOnYesDialog = () => {
    store.changeValue("closeNow", surveyOnCloseNow);
    store.changeValue("surveyDateEnd", dayjs());
    store.SaveSurveyDates();

    setIsSurveyDialogOpen(false);
  };

  const handleOnCloseDialog = () => {
    setSurveyOnCloseNow(store.closeNow);
    setIsSurveyDialogOpen(false);

    store.changeDatesTab(false);
  };

  const isEndDateValid = (endDate: Dayjs, startDate: Dayjs) => {
    const differenceInDays = endDate.diff(startDate);
    return differenceInDays >= 0;
  };

  const isEndDateTimeValid = (endDateTime: Dayjs, startDateTime: Dayjs) => {
    const differenceInMinutes = endDateTime.diff(startDateTime, "minute");
    return differenceInMinutes >= 30;
  };

  const checkSurveyStartAndSurveyEndRange = () => {
    const mergedStartDateTime = extractAndMergeDateTime(startDatePart, startTimePart);
    const mergedEndDateTime = extractAndMergeDateTime(endDatePart, endTimePart);

    if (!isEndDateTimeValid(mergedEndDateTime, mergedStartDateTime)) {
      setIsCustomEndTimeError(true);
    } else {
      setIsCustomEndTimeError(false);
    }
  };

  const handleSurveyTimeEndChanged = (value: Dayjs) => {
    store.mergeDateAndTime(endDatePart, value);
    store.changeDatesTab(true);
  };

  const handleSurveySaveChanges = () => {
    if (surveyOnCloseNow && store.closeNow !== surveyOnCloseNow) {
      setIsSurveyDialogOpen(true);
      return;
    }

    store.changeValue("surveyDateStart", extractAndMergeDateTime(startDatePart, startTimePart));
    if (endDatePart && endTimePart) {
      store.changeValue("surveyDateEnd", extractAndMergeDateTime(endDatePart, endTimePart));
    }

    store.SaveSurveyDates();
  };

  const calculateLineDotsSurveyHeight = (isRunning: boolean, isComplete: boolean): number => {
    const { project_type } = store.AllInfo || {};

    if (!project_type) return 0;

    const isOneOff = project_type === SEND_OUT_SURVEY_TYPES.OneOff;
    const isFrequency = project_type === SEND_OUT_SURVEY_TYPES.Frequency;

    if (isOneOff) {
      if (isRunning) return 116;

      if (
        (startDateError || startTimeError || isStartDateSelected || isStartTimeSelected) &&
        !isComplete
      )
        return 265;

      return isComplete ? 119 : 220;
    }

    if (isFrequency) {
      if (isRunning) return 182;

      if ((startDateError || startTimeError) && !isComplete) return 312;
      if (startDatePart && !isComplete) return 285;

      return isComplete ? 185 : 272;
    }
  };

  const renderMilestoneSurveyDateSettings = () => {
    const isSurveyRunning = store.AllInfo?.survey_status === SURVEY_STATUS.running;
    const isSurveyComplete = store.AllInfo?.survey_status === SURVEY_STATUS.complete;

    return (
      <MainDateTimeContainer>
        <GridSurveyRunningWrapped>
          <IndicatorWrapper>
            <img
              src={"/assets/icons/ellipse-green.svg"}
              width={11}
              height={11}
              alt="ellipse green"
            />
            <img
              src={"/assets/icons/line-dots.svg"}
              width={1}
              height={
                !isSurveyComplete && !isSurveyRunning
                  ? 185
                  : isSurveyRunning || isSurveyComplete
                  ? 290
                  : 215
              }
              alt="line dots"
            />
            <img src={"/assets/icons/ellipse-red.svg"} width={11} height={11} alt="ellipse red" />
          </IndicatorWrapper>

          <DateSettingWrapper>
            {(isSurveyRunning || isSurveyComplete) && (
              <>
                <QuestionFirstTitle>When should the survey be launched?</QuestionFirstTitle>
                <SurveyLaunchInfoWrapper>
                  <SurveyLaunchCaption>Your survey was launched on:</SurveyLaunchCaption>
                  <SurveyLaunchDate>
                    {formatToLocalDateTime(store.AllInfo?.timestart_milestone)}
                  </SurveyLaunchDate>
                  <SurveyLaunchScheduleContainer>
                    <li>
                      {emplyeeAuto} recipients with auto-scheduled invites: Survey to be received{" "}
                      {daysCount} days from their start date at{" "}
                      {formatDateToLocalTime(store.AllInfo?.timestart_milestone)}
                    </li>
                    <li>
                      {employeeManual} recipients with manually scheduled invites: Survey to be
                      received based on their individual schedules.
                    </li>
                  </SurveyLaunchScheduleContainer>
                </SurveyLaunchInfoWrapper>
              </>
            )}

            {!isSurveyRunning && !isSurveyComplete && (
              <>
                <QuestionFirstTitle>When will recipients receive the survey?</QuestionFirstTitle>
                <SurveyLaunchInfoWrapper>
                  <SurveyLaunchScheduleContainer>
                    <li>
                      Recipients with auto-scheduled invites: Survey to be received{" "}
                      <b>{daysCount} days</b> from their start date at{" "}
                      <b>{formatDateToLocalTime(store.AllInfo?.timestart_milestone)}</b>
                    </li>
                    <li>
                      Recipients with manually scheduled invites: Survey to be received based on
                      their individual schedules.
                    </li>
                  </SurveyLaunchScheduleContainer>
                </SurveyLaunchInfoWrapper>
              </>
            )}

            <DateSettingBottomWrapper>
              <QuestionFirstTitle>When should the survey end?</QuestionFirstTitle>
              <SurveyDateTimeWrapper>
                <div>
                  <SurveyDateTimeColumn>
                    <CustomDateField
                      id="Survey_EditCreate_Communication_SetSurveyDates_EndDaySet_InputContainer"
                      value={endDatePart}
                      changeValue={(value: Dayjs) => {
                        store.mergeDateAndTime(value, null);
                        store.changeDatesTab(true);
                      }}
                      disabled={store.leaveDateEmpty || isSurveyComplete}
                      onError={handleEndDateError}
                    />
                    <CustomTimeField
                      value={endTimePart}
                      disabled={store.leaveDateEmpty || isSurveyComplete}
                      changeValue={(value: Dayjs) => {
                        store.mergeDateAndTime(endDatePart, value);
                        store.changeDatesTab(true);
                      }}
                      minTime={store.minTime["surveyDateEnd"]}
                      pairWithDate={true}
                      onError={handleEndTimeError}
                    />
                  </SurveyDateTimeColumn>

                  {!store.leaveDateEmpty && errorEndDateMessage && (
                    <LabelErrorMessage>{errorEndDateMessage}</LabelErrorMessage>
                  )}
                  {!store.leaveDateEmpty && errorEndTimeMessage && (
                    <LabelErrorMessage>{errorEndTimeMessage}</LabelErrorMessage>
                  )}
                </div>

                <FormControlLabel
                  label={
                    <LabelCheckbox id="Survey_EditCreate_Communication_SetSurveyDates_Checkbox_Label">
                      Leave end time unscheduled
                    </LabelCheckbox>
                  }
                  control={
                    <Checkbox
                      id="Survey_EditCreate_Communication_SetSurveyDates_Checkbox"
                      onChange={(e) => handleLeaveDateEmpty(e)}
                      size="small"
                      checked={store.leaveDateEmpty}
                      disabled={isSurveyRunning || isSurveyComplete}
                    />
                  }
                />
              </SurveyDateTimeWrapper>

              {isSurveyRunning && (
                <>
                  <BottomSectionWrapper>
                    <FormControlLabel
                      label={
                        <LabelCheckbox id="Survey_EditCreate_Communication_SetSurveyDates_Checkbox_Label">
                          End survey now
                        </LabelCheckbox>
                      }
                      control={
                        <Checkbox
                          id="Survey_EditCreate_Communication_SetSurveyDates_Checkbox"
                          onChange={(event) => handleCloseNow(event)}
                          size="small"
                          checked={store.closeNow}
                          disabled={isSurveyComplete}
                        />
                      }
                    />
                  </BottomSectionWrapper>
                  <WarningSectionWrapper>
                    <img src={"/assets/icons/notice.svg"} width={20} height={20} alt="notice" />
                    <WarningLabel>
                      Your survey will end as soon as you have saved your changes. Survey recipients
                      will not be able to submit responses and you will not be able to reopen the
                      survey.
                    </WarningLabel>
                  </WarningSectionWrapper>
                </>
              )}

              <ButtonWrapper>
                <StyledSavedButton
                  id="Survey_EditCreate_Communication_SetSurveyDates_Save_Button"
                  disabled={!store.dates_changed || endDateError || endTimeError}
                  variant="contained"
                  onClick={() => {
                    store.SaveSurveyDates();
                  }}
                >
                  Save changes
                </StyledSavedButton>
              </ButtonWrapper>
            </DateSettingBottomWrapper>
          </DateSettingWrapper>
        </GridSurveyRunningWrapped>
      </MainDateTimeContainer>
    );
  };

  const renderSurveyDateSettings = () => {
    const isSurveyRunning = store.AllInfo?.survey_status === SURVEY_STATUS.running;
    const isSurveyComplete = store.AllInfo?.survey_status === SURVEY_STATUS.complete;

    return (
      <MainDateTimeContainer>
        <GridSurveyRunningWrapped>
          <IndicatorWrapper>
            <img
              src={"/assets/icons/ellipse-green.svg"}
              width={11}
              height={11}
              alt="ellipse green"
            />
            <img
              src={"/assets/icons/line-dots.svg"}
              width={1}
              height={calculateLineDotsSurveyHeight(isSurveyRunning, isSurveyComplete)}
              alt="line dots"
            />
            <img src={"/assets/icons/ellipse-red.svg"} width={11} height={11} alt="ellipse red" />
          </IndicatorWrapper>

          <DateSettingWrapper>
            <SurveyLaunchInfoWrapper>
              <QuestionFirstTitle>When should the survey be launched?</QuestionFirstTitle>
              {isSurveyRunning || isSurveyComplete ? (
                <SurveyStartScheduleWrapper>
                  <p>Your survey was launched on:</p>
                  <SurveyLaunchDate>
                    <b>{formatToLocalDateTime(store.surveyDateStart)}</b>
                  </SurveyLaunchDate>
                </SurveyStartScheduleWrapper>
              ) : (
                <>
                  <SurveyDateTimeWrapper>
                    <div>
                      <SurveyDateTimeColumn>
                        <CustomDateField
                          id="Survey_EditCreate_Communication_SetSurveyDates_StartDaySet_InputContainer"
                          value={startDatePart}
                          changeValue={(value: Dayjs) => {
                            store.mergeDateAndTime(value, null, "surveyDateStart");
                            store.changeDatesTab(true);
                          }}
                          disabled={store.leaveDateEmpty || isSurveyComplete}
                          onError={handleStartDateError}
                        />
                        <CustomTimeField
                          value={startTimePart}
                          disabled={store.leaveDateEmpty || isSurveyComplete}
                          changeValue={(value: Dayjs) => {
                            store.mergeDateAndTime(startDatePart, value, "surveyDateStart");
                            store.changeDatesTab(true);
                          }}
                          minTime={store.minTime["surveyDateStart"]}
                          pairWithDate={true}
                          onError={handleStartTimeError}
                        />
                      </SurveyDateTimeColumn>

                      {store.AllInfo?.project_type === SEND_OUT_SURVEY_TYPES.OneOff &&
                        !startDateError &&
                        !startTimeError &&
                        (isStartDateSelected || isStartTimeSelected) && (
                          <RuleInfoWrapper>
                            <LabelScheduleRuleInfo>
                              You can only schedule the survey 15 minutes from now.
                            </LabelScheduleRuleInfo>
                          </RuleInfoWrapper>
                        )}

                      {!store.leaveDateEmpty &&
                        (errorStartDateMessage || errorStartTimeMessage) && (
                          <RuleInfoWrapper>
                            {errorStartDateMessage && (
                              <LabelErrorMessage>{errorStartDateMessage}</LabelErrorMessage>
                            )}
                            {errorStartTimeMessage && (
                              <LabelErrorMessage>{errorStartTimeMessage}</LabelErrorMessage>
                            )}
                          </RuleInfoWrapper>
                        )}
                    </div>

                    <FormControlLabel
                      label={
                        <LabelCheckbox id="Survey_EditCreate_Communication_SetSurveyDates_Checkbox_Label">
                          Leave start time unscheduled
                        </LabelCheckbox>
                      }
                      control={
                        <Checkbox
                          id="Survey_EditCreate_Communication_SetSurveyDates_Checkbox"
                          onChange={(e) => handleLeaveStartDateEmpty(e)}
                          size="small"
                          checked={store.leaveDateEmpty}
                          disabled={isSurveyRunning || isSurveyComplete}
                        />
                      }
                    />
                  </SurveyDateTimeWrapper>
                </>
              )}
            </SurveyLaunchInfoWrapper>

            {store.AllInfo?.project_type === SEND_OUT_SURVEY_TYPES.Frequency && (
              <LabelScheduleFrequencyRuleInfo>
                {store.projectDateEnd
                  ? `Your future surveys are sent in intervals of 2 weeks from your launch date until ${formatToLocalDate(
                      store.projectDateEnd
                    )} based on your project settings.`
                  : "Your future surveys will be sent in intervals of 2 weeks from your launch date based on your project settings."}
              </LabelScheduleFrequencyRuleInfo>
            )}

            <DateSettingBottomWrapper>
              <QuestionFirstTitle>When should the survey end?</QuestionFirstTitle>
              {isSurveyComplete ? (
                <SurveyStartScheduleWrapper>
                  <p>Your survey was ended on:</p>
                  <SurveyLaunchDate>
                    <b>{formatToLocalDateTime(store.surveyDateEnd)}</b>
                  </SurveyLaunchDate>
                </SurveyStartScheduleWrapper>
              ) : (
                <>
                  <SurveyDateTimeWrapper>
                    <div>
                      <SurveyDateTimeColumn>
                        <CustomDateField
                          id="Survey_EditCreate_Communication_SetSurveyDates_EndDaySet_InputContainer"
                          value={endDatePart}
                          changeValue={(value: Dayjs) => {
                            store.mergeDateAndTime(value, null);
                            store.changeDatesTab(true);
                          }}
                          disabled={store.leaveEndDateSurveyEmpty || isSurveyComplete}
                          onError={handleEndDateError}
                          showCustomError={isCustomEndDateError}
                        />
                        <CustomTimeField
                          value={endTimePart}
                          disabled={store.leaveEndDateSurveyEmpty || isSurveyComplete}
                          changeValue={handleSurveyTimeEndChanged}
                          minTime={store.minTime["surveyDateEnd"]}
                          pairWithDate={true}
                          onError={handleEndTimeError}
                          showCustomError={isCustomEndTimeError}
                        />
                      </SurveyDateTimeColumn>

                      {store.AllInfo?.project_type === SEND_OUT_SURVEY_TYPES.OneOff &&
                        !endDateError &&
                        !endTimeError &&
                        (isEndDateSelected || isEndTimeSelected) && (
                          <LabelScheduleRuleInfo>
                            You can only end the survey 30 minutes from start time.
                          </LabelScheduleRuleInfo>
                        )}

                      {!store.leaveEndDateSurveyEmpty && errorEndDateMessage && (
                        <LabelErrorMessage>{errorEndDateMessage}</LabelErrorMessage>
                      )}
                      {!store.leaveEndDateSurveyEmpty && errorEndTimeMessage && (
                        <LabelErrorMessage>{errorEndTimeMessage}</LabelErrorMessage>
                      )}
                    </div>

                    <FormControlLabel
                      label={
                        <LabelCheckbox id="Survey_EditCreate_Communication_SetSurveyDates_Checkbox_Label">
                          Leave end time unscheduled
                        </LabelCheckbox>
                      }
                      control={
                        <Checkbox
                          id="Survey_EditCreate_Communication_SetSurveyDates_Checkbox"
                          onChange={(e) => handleLeaveEndDateEmpty(e)}
                          size="small"
                          checked={store.leaveEndDateSurveyEmpty}
                          disabled={isSurveyRunning || isSurveyComplete}
                        />
                      }
                    />
                  </SurveyDateTimeWrapper>

                  {isSurveyRunning && (
                    <>
                      <BottomSectionWrapper>
                        <FormControlLabel
                          label={
                            <LabelCheckbox id="Survey_EditCreate_Communication_SetSurveyDates_Checkbox_Label">
                              End survey now
                            </LabelCheckbox>
                          }
                          control={
                            <Checkbox
                              id="Survey_EditCreate_Communication_SetSurveyDates_Checkbox"
                              onChange={(event) => handleSurveyCloseNow(event)}
                              size="small"
                              checked={surveyOnCloseNow}
                              disabled={isSurveyComplete}
                            />
                          }
                        />
                      </BottomSectionWrapper>
                      <WarningSectionWrapper>
                        <img src={"/assets/icons/notice.svg"} width={20} height={20} alt="notice" />
                        <WarningLabel>
                          Your survey will end as soon as you have saved your changes. Survey
                          recipients will not be able to submit responses and you will not be able
                          to reopen the survey.
                        </WarningLabel>
                      </WarningSectionWrapper>
                    </>
                  )}

                  <ButtonWrapper>
                    <StyledSavedButton
                      id="Survey_EditCreate_Communication_SetSurveyDates_Save_Button"
                      disabled={
                        !store.dates_changed ||
                        startDateError ||
                        startTimeError ||
                        endDateError ||
                        endTimeError
                      }
                      variant="contained"
                      onClick={handleSurveySaveChanges}
                    >
                      Save changes
                    </StyledSavedButton>
                  </ButtonWrapper>
                </>
              )}
            </DateSettingBottomWrapper>
          </DateSettingWrapper>
        </GridSurveyRunningWrapped>
      </MainDateTimeContainer>
    );
  };

  return (
    <>
      <Container>
        <Grid item>
          <BoxText>
            <IconWrapped>
              <CalendarIcon />
            </IconWrapped>
            <BoxMainText id="Survey_EditCreate_Communication_SetSurveyDates_Description">
              {`All selected times are set according to your local timezone: UTC ${store.timezone_message}.`}
            </BoxMainText>
          </BoxText>
        </Grid>
        {store.AllInfo?.project_type === SEND_OUT_SURVEY_TYPES.Milestones ? (
          <>{renderMilestoneSurveyDateSettings()}</>
        ) : (
          <>{renderSurveyDateSettings()}</>
        )}
      </Container>
      <CustomConfirmationDialog
        title="Are you sure you want to end the survey immediately?"
        bodyMessage="Survey recipients will not able to submit responses and you will not be able to reopen the survey."
        isDialogOpen={isSurveyDialogOpen}
        onClose={handleOnCloseDialog}
        onYes={handleOnYesDialog}
        buttonsCaption={dialogButtonsCaption}
      />
    </>
  );
});

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 50px;
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 12px;
`;

const StyledSavedButton = styled(CustomButton)`
  width: 100%;
  margin-right: 0px !important;
`;

const IconWrapped = styled.div`
  margin: 0 5px;
`;

const BoxText = styled(Box)`
  width: 230px;
  height: 78px;
  color: var(--colorNeutralForeground2);
  margin-left: 20px;
  display: flex;
  flex-direction: row;
`;

const LabelCheckbox = styled.span`
  color: var(--ER-Theme-sys-light-on-tertiary-container, #707882);
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

const MainDateTimeContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 390px;
`;

const GridSurveyRunningWrapped = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 12px 1fr;
  column-gap: 19px;
  align-items: baseline;
`;

const DateSettingWrapper = styled.div`
  grid-column: span 1 / span 1;
  display: flex;
  flex-direction: column;
`;

const DateSettingBottomWrapper = styled.div`
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

const BottomSectionWrapper = styled.div`
  padding: 16px;
  background-color: var(--colorPalleteLightBlue);
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  font-weight: 500;
`;

const WarningSectionWrapper = styled.div`
  display: flex;
  column-gap: 6px;
`;

const WarningLabel = styled.div`
  font-size: 12px;
  color: var(--colorNeutralForeground1);
`;

const IndicatorWrapper = styled.div`
  grid-column: span 1 / span 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const QuestionFirstTitle = styled.h6`
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  color: var(--colorNeutralForeground1);
  margin: 0px !important;
`;

const SurveyLaunchInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  padding-top: 24px;
`;

const SurveyStartScheduleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  p {
    font-weight: 400;
  }
`;

const SurveyLaunchCaption = styled.p`
  font-size: 14px;
`;

const SurveyLaunchDate = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--colorNeutralForeground1);
`;

const SurveyLaunchScheduleContainer = styled.ul`
  list-style: disc;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding-inline-start: 25px !important;
  margin: 0px !important;
  color: var(--colorNeutralForeground1);
`;

const SurveyDateTimeWrapper = styled.div`
  border: 1px solid;
  color: var(--colorPaletteBlueBackground1);
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 15px;
`;

const SurveyDateTimeColumn = styled.div`
  display: flex;
  column-gap: 8px;
  margin-top: 16px;
`;

const BoxMainText = styled.div`
  width: 200px;
  color: var(--ER-Theme-sys-light-on-tertiary-container, #707882);
  /* ER Theme/body/medium */
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
`;

const RuleInfoWrapper = styled.div`
  min-height: 42px;
`;

const LabelErrorMessage = styled.p`
  color: var(--colorPaletteRedForeground2);
  padding-top: 8px;
  font-size: 14px;
`;

const LabelScheduleRuleInfo = styled.p`
  color: var(--colorNeutralForeground1);
  padding-top: 8px;
  font-size: 14px;
`;

const LabelScheduleFrequencyRuleInfo = styled.p`
  color: var(--colorNeutralForeground2);
  padding-top: 16px;
  font-size: 14px;
  min-height: 65px;
`;

export default SurveyDates;
