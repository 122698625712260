import { FC } from "react";
import { WEEKDAY } from "constants/weekDay";
import { observer } from "mobx-react-lite";
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  SelectChangeEvent,
  Select,
} from "@mui/material";
import store from "./store";
import styled from "styled-components";
import dayjs, { Dayjs } from "dayjs";
import { useGetFullDayHook } from "utils/useGetFullDayHook";
import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";
import CustomDateField from "components/customDateField";
import { formatToLocalDate } from "utils/timeFormatter";

type SurveyFrequencyProps = {};

const SurveyFrequency: FC<SurveyFrequencyProps> = observer(() => {
  const frequency_repeat_every_count_array = [...Array(31)].map((_, i) => i);
  const frequency_repeat_every_week_array = [...Array(4)].map((_, i) => i);
  const frequency_repeat_every_month_array = [...Array(12)].map((_, i) => i);
  const frequency_repeat_every_year_array = [...Array(5)].map((_, i) => i);

  const handleCheckboxChanged = () => {
    store.setData(!store.isAbleToSchedule, "isAbleToSchedule");

    if (!store.isAbleToSchedule) {
      store.setData(null, "endDate");
      return;
    }

    store.setData(dayjs(), "endDate");
  };

  return (
    <Container>
      <Title id="Survey_Project_CreatePopup_Frequency_Title">Survey frequency</Title>
      <Description id="Survey_Project_CreatePopup_Frequency_Description">
        Send surveys automatically as often as you need.{" "}
      </Description>
      <GridSurveyRunningWrapped>
        <IndicatorWrapper>
          <img src={"/assets/icons/ellipse-green.svg"} width={11} height={11} alt="ellipse green" />
          <img
            src={"/assets/icons/line-dots.svg"}
            width={1}
            height={store.frequency_repeat_every_period === "day" ? 190 : 208}
            alt="line dots"
          />
          <img src={"/assets/icons/ellipse-red.svg"} width={11} height={11} alt="ellipse red" />
        </IndicatorWrapper>

        <DateSettingWrapper>
          <QuestionFirstTitle>REPEAT THE SURVEY</QuestionFirstTitle>
          <SurveyLaunchInfoWrapper>
            <FrequencyWrapper>
              <Text id="Survey_Project_CreatePopup_Frequency_Text_Repeat_Every">Every</Text>
              <div>
                <StyledFormControl>
                  <StyledSelect
                    id="Survey_Project_CreatePopup_Frequency_Repeat_Every_Count"
                    value={store.frequency_repeat_every_count}
                    onChange={(e: SelectChangeEvent<number>) =>
                      store.setData(e.target.value, "frequency_repeat_every_count")
                    }
                    displayEmpty
                  >
                    {store.frequency_repeat_every_period === "day" &&
                      frequency_repeat_every_count_array.map((el) => (
                        <MenuItem value={el + 1} key={el}>
                          {el + 1}
                        </MenuItem>
                      ))}
                    {store.frequency_repeat_every_period === "week" &&
                      frequency_repeat_every_week_array.map((el) => (
                        <MenuItem value={el + 1} key={el}>
                          {el + 1}
                        </MenuItem>
                      ))}
                    {store.frequency_repeat_every_period === "month" &&
                      frequency_repeat_every_month_array.map((el) => (
                        <MenuItem value={el + 1} key={el}>
                          {el + 1}
                        </MenuItem>
                      ))}
                    {store.frequency_repeat_every_period === "year" &&
                      frequency_repeat_every_year_array.map((el) => (
                        <MenuItem value={el + 1} key={el}>
                          {el + 1}
                        </MenuItem>
                      ))}
                  </StyledSelect>
                </StyledFormControl>
                <StyledFormControl>
                  <StyledSelect
                    id="Survey_Project_CreatePopup_Frequency_Repeat_Every_Period"
                    value={store.frequency_repeat_every_period}
                    onChange={(e: SelectChangeEvent<string>) =>
                      store.setData(e.target.value, "frequency_repeat_every_period")
                    }
                    displayEmpty
                  >
                    <MenuItem
                      id="Survey_Project_CreatePopup_Frequency_Repeat_Every_Period_day"
                      value={"day"}
                    >
                      day
                    </MenuItem>
                    <MenuItem
                      id="Survey_Project_CreatePopup_Frequency_Repeat_Every_Period_week"
                      value={"week"}
                    >
                      week
                    </MenuItem>
                    <MenuItem
                      id="Survey_Project_CreatePopup_Frequency_Repeat_Every_Period_month"
                      value={"month"}
                    >
                      month
                    </MenuItem>
                    <MenuItem
                      id="Survey_Project_CreatePopup_Frequency_Repeat_Every_Period_year"
                      value={"year"}
                    >
                      year
                    </MenuItem>
                  </StyledSelect>
                </StyledFormControl>
              </div>
            </FrequencyWrapper>
            {store.frequency_repeat_every_period !== "day" && (
              <DayFrequencyWrapper>
                <DayFrequencyLabel id="Survey_Project_CreatePopup_Frequency_OccursEvery_Title">
                  On
                </DayFrequencyLabel>
                <div>
                  {WEEKDAY.map((day) => (
                    <DayItem
                      id={"Survey_Project_CreatePopup_Frequency_OccursEvery_Day-" + day}
                      onClick={() => store.setData(day, "frequencyDay")}
                      $active={store.frequencyDay === day}
                      key={day}
                    >
                      {day}
                    </DayItem>
                  ))}
                </div>
              </DayFrequencyWrapper>
            )}
            {store.frequency_repeat_every_period === "day" ? (
              <Text id="Survey_Project_CreatePopup_Frequency_OccursEvery_Description">
                Survey questions will be sent every{" "}
                <b>
                  {store.frequency_repeat_every_count}{" "}
                  {store.frequency_repeat_every_count === 1 ? "day" : "days"}
                </b>{" "}
                to employees. Full reports with recommendations will be available when the survey
                ends. You may also check out your dashboard for real-time data.
              </Text>
            ) : (
              <Text id="Survey_Project_CreatePopup_Frequency_OccursEvery_Description">
                Survey will be sent{" "}
                <b>
                  every {useGetFullDayHook(store.frequencyDay)},{" "}
                  {store.frequency_repeat_every_count}{" "}
                  {store.frequency_repeat_every_count === 1
                    ? store.frequency_repeat_every_period
                    : `${store.frequency_repeat_every_period}s`}
                </b>{" "}
                from your first survey to selected employees. You will be able to schedule your
                first survey later on.{" "}
                <StatusInformationContainer>
                  <CustomWidthTooltip
                    title={
                      "Your surveys will follow the same schedule as your first survey. For example, if your first survey is sent out on the second Monday of every two months, then all future surveys will also be sent out on the second Monday of every two months."
                    }
                    placement="top"
                    arrow
                  >
                    <img
                      src={"/assets/icons/status-information.svg"}
                      width={16}
                      height={16}
                      alt="status information"
                    />
                  </CustomWidthTooltip>
                </StatusInformationContainer>
              </Text>
            )}
          </SurveyLaunchInfoWrapper>

          <DateSettingBottomWrapper>
            <QuestionFirstTitle>STOP REPEATING A SURVEY</QuestionFirstTitle>
            {store.isAbleToSchedule ? (
              <BottomInformation>
                Future surveys will be created and sent until{" "}
                <b>{store.isErrorFrequensyDate ? "-" : formatToLocalDate(store.endDate)}</b>.
              </BottomInformation>
            ) : (
              <BottomInformation>
                Future surveys will be created and sent until you have scheduled an end time to stop
                them from repeating.
              </BottomInformation>
            )}

            <SurveyDateTimeWrapper>
              <CheckBoxContainer>
                <StyledFormControlLabel
                  control={
                    <StyledCheckBox
                      id="Survey_Project_CreatePopup_Frequency_unscheduled_CheckBox"
                      checked={store.isAbleToSchedule}
                      onChange={handleCheckboxChanged}
                    />
                  }
                  label={
                    <span id="Survey_Project_CreatePopup_Frequency_unscheduled_CheckBox_Label">
                      Schedule when survey will stop repeating
                    </span>
                  }
                />
              </CheckBoxContainer>
              <CustomDateField
                value={store.endDate}
                changeValue={(value: Dayjs) => store.setData(value, "endDate")}
                disabled={!store.isAbleToSchedule}
                required={store.isAbleToSchedule ? true : false}
                onError={(error) => store.setData(error, "isErrorFrequensyDate")}
              />
            </SurveyDateTimeWrapper>
          </DateSettingBottomWrapper>
        </DateSettingWrapper>
      </GridSurveyRunningWrapped>
    </Container>
  );
});

export default SurveyFrequency;

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
`;

const Title = styled.span`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: var(--colorNeutralForeground1);
  padding-bottom: 4px;
`;

const Description = styled.span`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
  margin-bottom: 20px;

  b {
    color: var(--colorPaletteRedForeground2);
  }
`;

const Text = styled.p`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
`;

const CheckBoxContainer = styled.div`
  width: 100%;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  color: var(--colorNeutralForeground2);

  .MuiCheckbox-root {
    &:hover {
      background-color: transparent !important;
    }
  }
`;

const StyledCheckBox = styled(Checkbox)`
  padding: 0px 10px !important;
  color: var(--colorBrandForeground1) !important;
`;

const DayItem = styled.span<{ $active: boolean }>`
  padding: 7px 12px;
  border-radius: 4px;
  border: 1px solid var(--colorPaletteBlueBackground1);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  color: ${(props) =>
    props.$active ? "var(--colorNeutralForeground1)" : "var(--colorNeutralForeground5)"};
  margin-right: 10px;
  cursor: pointer;
  background-color: ${(props) =>
    props.$active ? "var(--colorPaletteBlueBackground3)" : "transparent"};
`;

const StyledSelect = styled(Select)`
  height: 34px;
  width: 100%;
  max-width: 225px;

  em {
    font-family: Roboto !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    color: var(--colorNeutralForeground5) !important;
    font-style: normal !important;
  }
`;

const StyledFormControl = styled(FormControl)`
  margin: 0px 4px !important;
`;

const GridSurveyRunningWrapped = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 12px 1fr;
  column-gap: 19px;
  align-items: baseline;
`;

const IndicatorWrapper = styled.div`
  grid-column: span 1 / span 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const DateSettingWrapper = styled.div`
  grid-column: span 1 / span 1;
  display: flex;
  flex-direction: column;
`;

const DateSettingBottomWrapper = styled.div`
  padding-top: 48px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const QuestionFirstTitle = styled.h6`
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  color: var(--colorNeutralForeground2);
  margin: 0px !important;
`;

const SurveyLaunchInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding-top: 24px;
`;

const FrequencyWrapper = styled.div`
  display: flex;
  column-gap: 16px;
  align-items: center;
`;

const DayFrequencyWrapper = styled.div`
  display: flex;
  column-gap: 35px;
  align-items: center;
`;

const DayFrequencyLabel = styled.div`
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--colorNeutralForeground2);
`;

const BottomInformation = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  min-height: 40px;
  color: var(--colorNeutralForeground1);
`;

const SurveyDateTimeWrapper = styled.div`
  border: 1px solid;
  border-radius: 4px;
  color: var(--colorPaletteBlueBackground1);
  padding: 16px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const StatusInformationContainer = styled.span`
  vertical-align: sub;
`;

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    fontWeight: 500,
    fontSize: "12px",
    textAlign: "center",
    background: "var(--colorShadowInverted2)",
    marginLeft: "6px",
  },
});
