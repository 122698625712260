import { API_TEMPLATE_URL_GO } from 'constants/config';
import { EventSourcePolyfill } from 'event-source-polyfill';
import { useCallback } from 'react';

export const useRealtimeNotification = () => {
    const createEventSource = useCallback(() => {
        const token = localStorage.getItem('ERAccessToken');
        
        const newEventSource = new EventSourcePolyfill(
            `${API_TEMPLATE_URL_GO}/communication/events`,
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            }
        );

        return newEventSource;
    }, []);

    return createEventSource;
};
