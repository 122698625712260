import http from "../https";
import { TDashboardParameters } from "./useGetDashboardData";

type TRequestBody = {
  survey_id: number;
  attributes: {
    id: number;
    name: string;
    segments: string[];
  }[];
  segmentFilter: {
    type: number;
    conditional: string | null;
  };
  benchmarkType?: number;
  viewType: string;
};


type TWidgetRequestBody = {
  dashboardParameters: TDashboardParameters,
  attributes: {
    id: number;
    name: string;
    segments: string[];
  }[];
  benchmarkType?: number;
};

export const useGetDashboardTrends = (requestData: TRequestBody) =>
  http.post(`/Dashboard/trend-score`, requestData);

export const useGetDashboardTrendsWidget = (requestData: TWidgetRequestBody) =>
  http.dashboardAPIClient.post(`/dashboard/dashboard/trends-widget`, requestData);
