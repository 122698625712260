import { FC, useEffect, useState } from "react";
import dayjs, { Dayjs } from "dayjs";
import { observer } from "mobx-react";
import styled from "styled-components";
import EmailIcon from "@mui/icons-material/Email";
import { DeleteIcon } from "features/Employees/AttributeList/components/DeleteIcon";
import ChevronCircleIcon from "features/Survey/Recipients/ChevronCircleIcon";
import RichTextWithTabsAll from "../../RichTextWithTabsAll";
import CustomButton from "components/button/Button";
import { ReminderSurvey } from "types/surveys";
import PreviewIcon from "../../PreviewIcon";
import CustomTimeField from "components/customTimeField";
import CustomDayInterval from "components/customDayInterval";
import store from "./../../store";
import { formatDateToLocalTime, formatToLocalDateTime } from "utils/timeFormatter";
import { SURVEY_STATUS } from "constants/survey-status";
import { Tooltip, TooltipProps, tooltipClasses } from "@mui/material";
import { SEND_OUT_SURVEY_TYPES } from "constants/send-out-survey";

type ReminderEditProps = {
  reminder: ReminderSurvey;
  index: number;
  short_scheduled_date: string;
};

const ReminderEdit: FC<ReminderEditProps> = observer((props) => {
  const { reminder_days_id, strtime_send_reminder } = props.reminder;
  const [reminderSchedule, setReminderSchedule] = useState<Dayjs | null>(null);
  const [timeValue, setTimeValue] = useState<Dayjs | null>(null);
  const [errorDaysInterval, setErrorDaysInterval] = useState(false);
  const [errorTimeField, setErrorTimeField] = useState(false);
  const [disabledSaveChanges, setDisabledSaveChanges] = useState(false);
  const [disabledResendEmail, setDisabledResendEmail] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(() => {
    store.setupMinTime(null, "reminderScheduleTime");

    const selectedOption = store.ReminderDays.find((option) => option.id === reminder_days_id);
    let dayValue: Dayjs | null = null;

    if (selectedOption && strtime_send_reminder) {
      if (store.AllInfo?.project_type === SEND_OUT_SURVEY_TYPES.Milestones) {
        dayValue = dayjs(store.timestart_milestone)
          .add(selectedOption.reminders_after_days, "day")
          .set("hour", +strtime_send_reminder.split(":")[0])
          .set("minute", +strtime_send_reminder.split(":")[1]);
      } else {
        dayValue = dayjs(store.surveyDateStart)
          .add(selectedOption.reminders_after_days, "day")
          .set("hour", +strtime_send_reminder.split(":")[0])
          .set("minute", +strtime_send_reminder.split(":")[1]);
      }

      const timeData = dayjs(`${strtime_send_reminder}`, "HH:mm");

      setReminderSchedule(dayValue);
      setTimeValue(timeData);

      store.setupMinTime(dayValue, "reminderScheduleTime");
      store.setupReminderDataSaved();
    } else {
      if (store.AllInfo?.project_type === SEND_OUT_SURVEY_TYPES.Milestones) {
        dayValue = dayjs(store.timestart_milestone);
      } else {
        dayValue = dayjs(store.surveyDateStart);
      }

      store.changeReminderDays(store.ReminderDays[0].id, props.index);
      store.changeReminderTime(dayValue, props.index);

      store.resetChangedThirdTab()
    }
  }, []);

  useEffect(() => {
    setDisabledSaveChanges(false);
    
    if (
      missingIds.includes(props.reminder.reminder_days_id) ||
      (store.AllInfo?.survey_status !== SURVEY_STATUS.running && !timeValue) ||
      errorDaysInterval ||
      errorTimeField ||
      !store.changedThirdTab
    ) {
      setDisabledSaveChanges(true);
    }
  }, [
    props.reminder.reminder_days_id,
    store.AllInfo?.survey_status,
    timeValue,
    errorDaysInterval,
    errorTimeField,
    store.changedThirdTab,
  ]);

  useEffect(() => {
    setDisabledResendEmail(false);

    if (dayjs(props.short_scheduled_date).isAfter(dayjs()) || !store.reminderDataSaved) {
      setDisabledResendEmail(true);
    }
  }, [props.short_scheduled_date, store.reminderDataSaved]);

  const optionDays =
    store.surveyDateStart && store.surveyDateEnd
      ? store.ReminderDays.filter(
          (x) =>
            dayjs(store.surveyDateStart).add(x.reminders_after_days, "day") <
            dayjs(store.surveyDateEnd)
        )
      : store.ReminderDays;

  const missingIds = store.ReminderDays.map((x) => x.id).filter(
    (id) => !optionDays.map((x) => x.id).includes(id)
  );

  const handleDateIntervalChanged = (e: any) => {
    const idDays = e.target.value;
    const selectedOption = store.ReminderDays.find((option) => option.id === Number(idDays));

    store.changeReminderDays(+selectedOption.id, props.index);

    if (store.surveyDateStart) {
      const daysNumber = selectedOption.reminders_after_days;
      let nextSchedule: Dayjs = dayjs(store.surveyDateStart);

      if (daysNumber > 0) {
        nextSchedule = dayjs(store.surveyDateStart).add(daysNumber, "day");
      }

      if (nextSchedule.isBefore(dayjs())) {
        setErrorDaysInterval(true);
        return;
      } else {
        setErrorDaysInterval(false);
      }

      store.setupMinTime(nextSchedule, "reminderScheduleTime");

      setReminderSchedule(nextSchedule);
    }
  };

  const handleTimerChanged = (value: any) => {
    store.changeReminderTime(value, props.index);
    setTimeValue(value);

    if (reminderSchedule) {
      const updatedSchedule: Dayjs = reminderSchedule
        .set("hour", value.hour())
        .set("minute", value.minute())
        .set("second", 0);

      setReminderSchedule(updatedSchedule);
    } else {
      console.error("Reminder schedule is not set!");
    }
  };

  const handleErrorTimeField = (isError: boolean, message: string) => {
    setErrorTimeField(isError);
    setErrorMessage(message);
  }

  return (
    <Wrapped id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd">
      <TopOfEdit>
        <TopLeft>
          <p id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd_Title">
            Reminder email {props.index + 1}
          </p>
          <LinkPreview
            id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd_Remove_Button"
            onClick={() => store.DeleteReminderSubmit(props.index)}
          >
            <DeleteIcon />
            Delete
          </LinkPreview>
        </TopLeft>
        <TopRight>
          <LinkPreview
            id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd_Close_Button"
            onClick={() => store.changeOpenReminderIndex(props.index, false)}
          >
            Hide
            <ChevronCircleIcon style={{ marginLeft: 8 }} />
          </LinkPreview>
        </TopRight>
      </TopOfEdit>

      {store.AllInfo?.survey_status === SURVEY_STATUS.running &&
        ((reminderSchedule && dayjs().isAfter(reminderSchedule)) || !reminderSchedule) && (
          <ResendEmailInfoWrapper>
            {store.AllInfo?.project_type === SEND_OUT_SURVEY_TYPES.Milestones ? (
              <p>
                Your reminder email was sent on the same day as invite email{" "}
                <b>
                  at{" "} {formatDateToLocalTime(store.timestart_milestone)}
                </b>
              </p>
            ) : (
              <p>
                Your reminder email was sent on:{" "}
                <b>
                  {reminderSchedule
                    ? formatToLocalDateTime(reminderSchedule.toString())
                    : formatToLocalDateTime(store.surveyDateStart)}
                </b>
              </p>
            )}

            <CustomWidthTooltip
              title={
                disabledResendEmail
                  ? "Changes to your email need to be saved before it can be sent again."
                  : ""
              }
              placement="top"
              arrow
            >
              <div>
                <ResendEmailButton
                  id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd_Resend_Button"
                  variant="outlined"
                  onClick={() => {
                    const code = store.ReminderGroups.find(
                      (reminder) =>
                        reminder.id === store.ReminderData[props.index].reminder_recipients_group_id
                    )?.code;

                    store.setReminderGroupCode(code);
                    store.getAllResendEmail(store.idSurvey, code);
                    store.setData("isOpenResendEmailPoup", true);
                  }}
                  disabled={disabledResendEmail}
                >
                  <EmailIcon />
                  Resend email
                </ResendEmailButton>
              </div>
            </CustomWidthTooltip>
          </ResendEmailInfoWrapper>
        )}

      <SubHeader id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd_RecipientsSelect_Label">
        Recipients For reminder
      </SubHeader>

      <StyledLookup
        data={store.ReminderGroups}
        value={props.reminder.reminder_recipients_group_id}
        errorText=""
        error={false}
        name="recipients"
        onChange={(e) => store.changeReminderGroups(+e.target.value, props.index)}
      />

      {store.AllInfo?.survey_status !== SURVEY_STATUS.running && (
        <>
          <SubHeader id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd_RecipientsSelect_Label">
            Send after invite email
          </SubHeader>

          <ScheduleWrapper>
            <SendAfter>
              <LookUpWrapper>
                <StyledLookup
                  data={store.ReminderDays}
                  value={props.reminder.reminder_days_id}
                  errorText={
                    errorDaysInterval
                      ? "Minimum selected date is today"
                      : "Select a date before the end of the survey"
                  }
                  error={missingIds.includes(props.reminder.reminder_days_id) || errorDaysInterval}
                  onChange={(e) => handleDateIntervalChanged(e)}
                  disabledOption={missingIds}
                  label="Select date"
                />
              </LookUpWrapper>
              <TimeFieldWrapper>
                <CustomTimeField
                  changeValue={(e) => handleTimerChanged(e)}
                  value={timeValue}
                  pairWithDate={true}
                  minTime={store.minTime["reminderScheduleTime"]}
                  onError={handleErrorTimeField}
                />
              </TimeFieldWrapper>
            </SendAfter>
            {errorTimeField && <LabelErrorMessage>{errorMessage}</LabelErrorMessage>}
          </ScheduleWrapper>

          {store.AllInfo?.project_type !== SEND_OUT_SURVEY_TYPES.Milestones && (
            <EmailScheduleWrapper>
              <img src={"/assets/icons/calendar-icon.svg"} width={16} height={16} alt="calendar" />
              {store.surveyDateStart && reminderSchedule ? (
                <EmailScheduleInfo>
                  Email will be sent on{" "}
                  <span>{formatToLocalDateTime(reminderSchedule.toString())}</span>
                </EmailScheduleInfo>
              ) : (
                <EmailScheduleInfo>
                  Email will be sent on - <br />
                  (This email can only be sent after your survey launch has been scheduled.)
                </EmailScheduleInfo>
              )}
            </EmailScheduleWrapper>
          )}
        </>
      )}

      <SubHeader id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd_EmailSubject_Label">
        EMAIL SUBJECT
      </SubHeader>

      <EditorWrapper>
        <RichTextWithTabsAll
          onlyText
          data={props.reminder.reminder_message_languages}
          width={100}
          changeValue={(value: string, field: string, index: number) =>
            store.changeReminderData(value, field, props.index, index)
          }
          field="subject_email"
          editorId="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_Subject_RichTextWith_Editor"
        />
      </EditorWrapper>

      <SubHeader id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd_EmailContent_Label">
        EMAIL CONTENT
      </SubHeader>

      <EditorWrapper>
        <RichTextWithTabsAll
          data={props.reminder.reminder_message_languages}
          width={200}
          changeValue={(value: string, field: string, index: number) =>
            store.changeReminderData(value, field, props.index, index)
          }
          field="body_email"
          editorId="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_Content_RichTextWith_Editor"
        />
      </EditorWrapper>

      <BottomWrapper>
        <ButtonWrapper>
          <CustomButton
            id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd_Save_Button"
            variant="contained"
            disabled={disabledSaveChanges}
            onClick={() => store.saveReminderData()}
          >
            Save changes
          </CustomButton>

          <StyledCancelButton
            id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd_Cancel_Button"
            variant="text"
            onClick={() => {
              store.changeOpenReminderIndex(props.index, false)
              store.resetChangedThirdTab()
            }}
          >
            Cancel
          </StyledCancelButton>
        </ButtonWrapper>

        <ButtonWrapper>
          <LinkPreview
            id="Survey_EditCreate_Communication_ScheduleCommunications_Reminder_EditAdd_Preview_Button"
            onClick={() => store.loadPreviewMessage(props.reminder.id_comms_reminder, false)}
          >
            <PreviewIcon />
            Preview email
          </LinkPreview>
        </ButtonWrapper>
      </BottomWrapper>
    </Wrapped>
  );
});

export default ReminderEdit;

const Wrapped = styled.div`
  min-height: 100px;
  padding: 10px 15px;
  border-radius: 5px;
  border: 1px solid var(--colorPaletteBlueBackground1);
`;

const BottomWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
`;

const LinkPreview = styled.a`
  color: var(--colorBrandForeground1);
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledLookup = styled(CustomDayInterval)`
  margin: 0 !important;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
`;

const StyledCancelButton = styled(CustomButton)`
  color: var(--colorNeutralForeground2) !important;
`;

const TopOfEdit = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const TopLeft = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
`;

const TopRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const EditorWrapper = styled.div`
  .MuiBox-root {
    padding: 0 !important;
    border-bottom: 0;
  }
  .MuiTab-root {
    border-radius: 4px 4px 0px 0px !important;
  }
  .Mui-selected {
    border: 1px solid var(--colorBrandForeground1) !important;
    border-bottom: none !important;
    border-radius: 4px 4px 0px 0px !important;
  }
  .rsw-editor {
    border-radius: 0px 0px 4px 4px !important;
  }
`;

const SubHeader = styled.h2`
  color: var(--colorNeutralForeground2);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  text-transform: uppercase;
  margin-top: 25px;
  margin-bottom: 8px;
`;

const ScheduleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  padding-bottom: 16px;
`

const SendAfter = styled.div`
  display: flex;
  align-items: start;
  gap: 20px;
  
  .MuiStack-root {
    padding-top: 10px;
  }
`;

const LookUpWrapper = styled.div`
  padding-top: 10px;
`;

const LabelErrorMessage = styled.p`
  color: var(--colorPaletteRedForeground2);
  padding-top: 8px;
  font-size: 14px;
`;

const ResendEmailButton = styled(CustomButton)`
  border: 1px solid var(--colorBrandForeground1) !important;
  background-color: white !important;
  padding: 5px 37px !important;

  svg {
    margin-right: 8px;
    font-size: 16px;
    margin-bottom: 2px;
  }

  &:disabled {
    border-color: var(--colorNeutralForegroundLightGray) !important;
  }
`;

const TimeFieldWrapper = styled.div`
  padding-top: 10px;
`;

const EmailScheduleWrapper = styled.div`
  display: flex;
  column-gap: 8px;
  align-items: baseline;
`;

const EmailScheduleInfo = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;

  span {
    font-weight: 500;
  }
`;

const ResendEmailInfoWrapper = styled.div`
  padding: 10px 16px 10px 16px;
  background-color: var(--colorPaletteGreenBackground7);
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  margin-top: 16px;
  align-items: center;

  p {
    font-size: 14px;
    color: var(--colorNeutralForeground1);
    font-weight: 400;
  }

  b {
    font-size: 14px;
    color: var(--colorNeutralForeground1);
    font-weight: 700;
  }
`;

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 200,
    fontWeight: 500,
    fontSize: "12px",
    textAlign: "center",
    background: "var(--colorShadowInverted2)",
    marginLeft: "6px",
  },
});
