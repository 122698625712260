import http from "api/https";
import { createUrl } from "../../helpers/url";


export const getManagersRecipients = (entityID: number, page: number, pageSize: number) => {
  const url = createUrl({
    path: "/employee/GetManagersEntity",
  });
  return http.postGO(url, {
    entity: entityID,
    page: page,
    limit: pageSize
  });
}
