import MainStore from "MainStore";
import { makeAutoObservable, runInAction } from "mobx";
import { TImportHistoricSurveyResult } from "types/surveys";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { Step } from "constants/layout-types";
import { downloadFileFromAssets } from "utils/downloadFile";
import { useGeneratePresignURL } from "api/useGeneratePresignURL";
import axios from "axios";
import { HistoricalImportJob, useGetandAcknowledgeHistoricalImport } from "api/useGetAndAcknowledgeHistoricalImport";
import { useConfirmHistoricalImport } from "api/useConfirmHistoricalImport";


class Store {
  projectId: number = 0;

  file: File = null;
  fileName: string | null = null;
  importCurrentStep: Step | null = null;
  showImportProgress: boolean = false;
  success: boolean = false;
  surveyId: Number = 0;
  surveyName: string = "";
  jobId: string = "";
  errorType: string = "";

  constructor() {
    makeAutoObservable(this);
  }

  doLoad = (projectId: number) => {
    this.projectId = projectId;
  };

  setFile = (file: File) => {
    this.file = file;
  };

  clearStore = () => {
    runInAction(() => {
      this.projectId = 0;
      this.file = null;
      this.fileName = null;
      this.importCurrentStep = null;
      this.showImportProgress = false;
      this.success = false;
      this.surveyName = "";
      StoreLayout.showImportDone = false;
    });
  };

  parseExcel = async () => {
    this.importCurrentStep = Step.ProgressLoader;
    try {
      const presignUrlResponse = await useGeneratePresignURL("historical-import", {
        "project_id": this.projectId.toString(),
        "filename": this.file.name,
      });
      const uploadURL = presignUrlResponse.data.url;

      await axios.put(uploadURL, this.file, {
        headers: {
          "Content-Type": this.file.type,
        },
      });


    } catch (err) {
      StoreLayout.changeShowImportDone(false);
      MainStore.setSnackbar("Something went wrong", "error");
    }
  };

  confirmAndImport = async () => {
    this.importCurrentStep = Step.ProgressLoader;
    try {
      await useConfirmHistoricalImport(this.jobId);
    } catch {
      StoreLayout.changeShowImportDone(false);
      MainStore.setSnackbar("Something went wrong", "error");
    }
  };

  saveExcelStructure = async () => {
    downloadFileFromAssets("/assets/survey_template.xlsx", "survey_template.xlsx");
  };

  getLatestJobs = async () => {
    const jobResponse = await useGetandAcknowledgeHistoricalImport(this.projectId)
    const jobs = jobResponse.data.jobs as HistoricalImportJob[];
    if (jobs) {
      const selectedJob = jobs[0];
      this.fileName = selectedJob.raw_data_filename;

      if (selectedJob.error) {
        this.importCurrentStep = Step.Error;
        StoreLayout.changeShowImportProgress(false);
        this.errorType = selectedJob.error;
        this.file = null;
      }

      if (selectedJob.status === "PENDING") {
        this.importCurrentStep = Step.ReviewChanges;
        this.surveyName = selectedJob.survey_name;
        this.jobId = selectedJob.id;
      }

      if (selectedJob.status === "COMPLETED") {
        this.importCurrentStep = Step.Success;
        this.surveyId = selectedJob.survey_id;
      }

      if (selectedJob.status === "PROCESSING") {
        this.importCurrentStep = Step.ProgressLoader
        this.surveyName = selectedJob.survey_name;
        this.file = new File(
          [new Blob()],
          selectedJob.raw_data_filename,
          {
            type: "text/plain",
            lastModified: new Date().getTime()
          }
        )
      }
    }
  }
}

const store = new Store();
export default store;
