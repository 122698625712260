import { FC, useMemo } from "react";
import dayjs from "dayjs";
import { observer } from "mobx-react";
import styled from "styled-components";
import EditIcon from "@mui/icons-material/Edit";

import store from "./../../store";
import ReminderEdit from "./ReminderEdit";
import { ReminderSurvey } from "types/surveys";
import PreviewIcon from "../../PreviewIcon";
import { convertToAmPm, formatDateToLocalTime, formatToLocalDateTime } from "utils/timeFormatter";
import { SEND_OUT_SURVEY_TYPES } from "constants/send-out-survey";

type ReminderBlockProps = {
  reminder: ReminderSurvey;
  index: number;
};

const ReminderBlock: FC<ReminderBlockProps> = observer((props) => {
  const scheduledDates = useMemo(() => {
    let scheduled_date = "";
    let short_scheduled_date = "";
    if (store.surveyDateStart && props.reminder.strtime_send_reminder) {
      let start = store.surveyDateStart;
      let days = store.ReminderDays.filter((x) => x.id === props.reminder.reminder_days_id);
      let added = dayjs();

      if (days.length !== 0) {
        added = start.add(days[0].reminders_after_days, "day");
      } else {
        added = start;
      }

      if (
        props.reminder.strtime_send_reminder &&
        props.reminder.strtime_send_reminder.split(":").length === 2
      ) {
        added = added.set("hour", +props.reminder.strtime_send_reminder.split(":")[0]);
        added = added.set("minute", +props.reminder.strtime_send_reminder.split(":")[1]);
      }

      short_scheduled_date = added.format("ddd, D MMM, YYYY");
    }
    return { scheduled_date, short_scheduled_date };
  }, [store.surveyDateStart, props.reminder.strtime_send_reminder, store.ReminderDays]);

  const { short_scheduled_date } = scheduledDates;

  const renderEmailSchedule = () => {
    const { reminder } = props;
    const { ReminderDays, surveyDateStart, timestart_milestone, AllInfo } = store;
  
    const daysNumber = ReminderDays.find((x) => x.id === reminder.reminder_days_id);
    const reminderTime = reminder.strtime_send_reminder;
  
    const formattedTime = reminderTime ? convertToAmPm(reminderTime) : null;
  
    const renderScheduleText = (text: string) => (
      <LaunchSurveySchedule>{text}</LaunchSurveySchedule>
    );
  
    // Milestones project type
    if (AllInfo?.project_type === SEND_OUT_SURVEY_TYPES.Milestones) {
      if (!timestart_milestone) {
        return renderScheduleText("Not Scheduled");
      }
  
      if (daysNumber && formattedTime) {
        const daysAfterText =
          daysNumber.reminders_after_days > 0
            ? `${daysNumber.reminders_after_days} ${daysNumber.reminders_after_days === 1 ? 'day' : 'days'} after invite email at ${formattedTime}`
            : `Same day as invite email at ${formattedTime}`;
        return renderScheduleText(daysAfterText);
      }
  
      const sameDayText = `Same day as invite email at ${formatDateToLocalTime(
        timestart_milestone
      )}`;
      
      return renderScheduleText(sameDayText);
    }
  
    // Non-Milestones project type
    if (AllInfo?.survey_date_start) {
      if (daysNumber && formattedTime) {
        const nextScheduleDate = dayjs(AllInfo.survey_date_start)
          .add(Number(daysNumber.reminders_after_days), "day")
          .set("hour", +reminderTime.split(":")[0])
          .set("minute", +reminderTime.split(":")[1]);
  
        return renderScheduleText(formatToLocalDateTime(nextScheduleDate));
      }
  
      return renderScheduleText(formatToLocalDateTime(surveyDateStart));
    }
  
    if (daysNumber && formattedTime) {
      const daysAfterText =
        daysNumber.reminders_after_days > 0
          ? `${daysNumber.reminders_after_days} ${daysNumber.reminders_after_days === 1 ? 'day' : 'days'} after invite at ${formattedTime}`
          : `Same day as invite at ${formattedTime}`;
      return renderScheduleText(daysAfterText);
    }
  
    return renderScheduleText("Not Scheduled");
  };

  return (
    <>
      {store.openReminderIndex === props.index ? (
        <>
          <ReminderEdit {...props} short_scheduled_date={short_scheduled_date} />
          <LaunchSurveyAfter />
        </>
      ) : (
        <>
          <LaunchSurvey>
            <div>
              <span
                id={`Survey_EditCreate_Communication_ScheduleCommunications_Reminder_Email_${props.index} `}
              >
                Reminder email {props.index + 1} to
              </span>
              <LaunchSecondText
                id={`Survey_EditCreate_Communication_ScheduleCommunications_Reminder_Recipients${props.index}`}
              >
                {props.reminder.reminder_recipients_group_name}
              </LaunchSecondText>
              {renderEmailSchedule()}
            </div>
            <LaunchButtons>
              <LaunchButtonWrapper>
                <LinkPreview
                  id={`Survey_EditCreate_Communication_ScheduleCommunications_Reminder_Preview_Button_${props.index}`}
                  onClick={() => store.loadPreviewMessage(props.reminder.id_comms_reminder, false)}
                >
                  <PreviewIcon />
                  Preview email
                </LinkPreview>
                <LinkPreview
                  id={`Survey_EditCreate_Communication_ScheduleCommunications_Reminder_Edit_Button_${props.index}`}
                  onClick={() => {
                    store.changeOpenReminderIndex(props.index, true)
                    store.resetChangedThirdTab()
                  }}
                >
                  <EditIcon fontSize="inherit" />
                  Edit
                </LinkPreview>
              </LaunchButtonWrapper>
            </LaunchButtons>
          </LaunchSurvey>
          <LaunchSurveyAfter />
        </>
      )}
    </>
  );
});

export default ReminderBlock;

const LaunchSurvey = styled.div`
  padding: 12px;
  background-color: var(--colorNeutralForeground4);
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 14px;
    font-weight: 400;
    color: var(--colorNeutralForeground1);
  }
`;

const LinkPreview = styled.a`
  margin-left: 5px;
  color: var(--colorBrandForeground1);
  display: flex;
  align-items: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  display: flex;
  align-items: center;
  gap: 5px;

  &:hover {
    text-decoration: underline;
  }
`;

const LaunchButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LaunchButtonWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 20px;
`;

const LaunchSurveyAfter = styled.div`
  height: 15px;
  border-left: 3px dashed var(--colorPaletteBlueBackground2);
  margin-left: 20px;
`;

const LaunchSecondText = styled.span`
  color: var(--colorPaletteVioletBackground3) !important;
  font-weight: 500 !important;
  margin-left: 6px;
`;

const LaunchSurveySchedule = styled.p`
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: var(--colorNeutralForeground1);
  padding-top: 6px;
`;
